import { getLoggedInUser, setAuthData, setAuthLoading, setAuthUser, setLogOut, setLogin, setTwoFASuccess, setUnAuthData, setUserData } from "redux/authSlice";
import store from "redux/store";
import { API, Auth } from "aws-amplify";
import type { LoginPayload } from "interfaces/auth";
import { ErrorHandler } from "helper/Handlers";
import { userByEmail } from "graphql/queries";
import { type GraphQLResult } from "@aws-amplify/api";
import type { UserByEmailQuery } from "API";
import resetPasswordEmailTemplate from "mailTemplates/auth/resetPassword";
import { sendMail } from "./users";
import { handleCreateFlunaMember, handleUpdateFlunaMember } from "./permissions";

export const updateAuthLoading = (loading: boolean) => {
  store.dispatch(setAuthLoading({ data: loading }));
};

export const saveAuthUser = async (authUser: any) => {
  store.dispatch(setAuthUser({ data: authUser }));
  store.dispatch(getLoggedInUser());
};

export const getLoggedInUserFunction = async () => {
  try {
    updateAuthLoading(true);
    const currentUser = await Auth.currentAuthenticatedUser();
    store.dispatch(setAuthData({ data: currentUser }));
    store.dispatch(setUserData({ data: currentUser.attributes }));
    return currentUser;
  } catch (err: any) {
    console.log({ err });
    // ErrorHandler({ message: err });
    store.dispatch(setUnAuthData({ data: err }));
  }
};

export const getUserByEmail = async (email: string) => {
  const userData = (await API.graphql({
    query: userByEmail,
    variables: { email },
  })) as GraphQLResult<UserByEmailQuery>;
  return userData.data?.userByEmail?.items[0];
};

export const login = async (payload: LoginPayload, cb) => {
  try {
    const { email, password } = payload;
    const user = await getUserByEmail(email);
    if (user) {
      const data = await Auth.signIn(email, password);
      store.dispatch(setLogin({ loginData: payload, loginReturn: data, isAuthenticated: true, status: "success" }));
    } else {
      throw new Error("User does not exist");
    }
  } catch (err: any) {
    if (err.message) {
      ErrorHandler(err);
    } else {
      ErrorHandler();
    }
    throw err;
  } finally {
    cb();
  }
};

export const register = async (payload) => {
  try {
    const { email, password, name, familyName, primaryTeam } = payload;
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email.replace(/\s+/g, ""),
        given_name: name.replace(/\s+/g, ""),
        family_name: familyName.replace(/\s+/g, ""),
        picture: "",
      },
    });
    const existingUser = await getUserByEmail(email);
    if (existingUser) {
      const memberName = { firstName: name, lastName: familyName };
      const flunaMember = primaryTeam ? { primaryTeam, ...memberName } : memberName;
      const user = await handleUpdateFlunaMember({
        id: existingUser.id,
        status: "inactive",
        ...flunaMember,
      });
      return { error: false, data: user };
    } else {
      const user = await handleCreateFlunaMember({
        email,
        primaryTeam: primaryTeam || null,
        firstName: name,
        lastName: familyName,
        picture: "",
        status: "inactive",
      });
      return { error: false, data: user };
    }
  } catch (err: any) {
    if (err.message) {
      ErrorHandler(err);
    } else {
      ErrorHandler();
    }
    return { error: true, data: null };
  }
};

export const logout = async () => {
  await Auth.signOut({ global: true });
};

export const setLogout = () => {
  store.dispatch(setLogOut());
};

export const changedPasswordEmailHandler = async (recipientEmail: string, userName: string) => {
  const recipientMails = [recipientEmail];

  const mailSubject = "Password Change";

  const mailBody = resetPasswordEmailTemplate(userName);

  await sendMail({
    showNotif: true,
    recipientMails,
    mailSubject,
    mailBody,
    ccMails: [],
    bccMails: [],
    replyAddresses: [],
  });
};

export const confirmAuthentication = async (payload: Record<string, any>, code: string) => {
  try {
    await Auth.sendCustomChallengeAnswer(payload, code);

    const user = await getLoggedInUserFunction();

    if (user.payload && !user.payload.isAuthenticated) {
      ErrorHandler({
        message: "The verification code has expired, please resend a new one.",
      });
    } else {
      store.dispatch(setTwoFASuccess({ status: "success" }));
    }
  } catch (err: unknown) {
    store.dispatch(setTwoFASuccess({ status: "" }));
    console.log("errtest2", err);
    if (err instanceof Error) {
      ErrorHandler(err || { message: "Failed to submit form" });
      throw err;
    }
  }
};
