import { logout } from "services/auth";

const environ = process.env.REACT_APP_NODE_ENV;
const idleDuration = environ === "dev" ? 7200000 : 900000; // 15mins;

const ActivityHelper = () => {
  let time: any;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer; // catches touchpad clicks as well
  window.onkeydown = resetTimer;
  window.addEventListener("scroll", resetTimer, true);

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, idleDuration);
    // 1000 milliseconds = 1 second
  }
};

export default ActivityHelper;
