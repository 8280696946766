import React from "react";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import { MTableCell } from "davetech-material-table";
import { type Column } from "interfaces/Table";
import { IPerson } from "interfaces/atlas";
import { ContentCopyOutlined } from "@mui/icons-material";
import countryDailCode from "data/countryDailCode";
import flunaIcon from "assets/stats-1.svg";
import pastInterIcon from "assets/stats-2.svg";

export const tableStyles = {
  fontWeight: 400,
  fontSize: "0.875rem",
  border: "1px solid #E9EAEF",
  padding: "18px 0 0 0px",
  borderRadius: "8px",
  color: "#8083A3",
};

export const columns: Array<Column<IPerson>> = [
  {
    title: "NAME",
    field: "name",
    width: "23%",
  },
  {
    title: "EMAIL",
    field: "email",
    width: "25%",
  },
  {
    title: "PHONE NUMBER",
    field: "phoneNumber",
    width: "25%",
  },
  {
    title: "COMPANY",
    field: "company",
    width: "25%",
  },
  {
    title: "COUNTRY",
    field: "country",
    width: "25%",
  },
  {
    title: "LABELS",
    field: "labels",
    width: "25%",
  },
];

export const CountryPill = ({ id, country }: { id: string; country: any }) => {
  return (
    <PillBox
      key={id}
      text={
        <>
          {country?.isoAlpha2 ? (
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.7rem",
              }}
            >
              <Box
                component="img"
                sx={{
                  width: ".9rem",
                  height: ".9rem",
                }}
                src={`/static/countrysvgs/${country?.isoAlpha2 as string}.svg`}
              />
              <Typography>{country?.isoAlpha2}</Typography>
            </Box>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export const filterColumns = [
  {
    title: "Name",
    field: "name",
    others: ["firstName", "lastName"],
    type: "string",
  },
  {
    title: "Email",
    field: "email",
    customConditions: ["Contains"],
    type: "string",
  },
  {
    title: "Country",
    field: "country",
    type: "string",
  },
];

export const PillBox = ({ text, type, sx }: { sx?: SxProps; text: string | React.ReactNode; type?: "uppercase" | "lowercase" | "capitalize" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        borderRadius: "2.5625rem",
        padding: ".5rem 1rem",
        color: "rgba(230, 122, 14, 1)",
        backgroundColor: "rgba(230, 122, 14, 0.15)",
        fontFamily: "SF Pro Display Medium",
        textTransform: type === "uppercase" ? "uppercase" : type === "lowercase" ? "lowercase" : type === "capitalize" ? "capitalize" : "none",
        ...sx,
      }}
    >
      {text}
    </Box>
  );
};

export const cases = (copyToClipboard: (id: string) => void) => ({
  name: (props: any, field: any, rowData: IPerson) => {
    const { firstName, lastName, id } = rowData;

    const name = `${firstName} ${lastName}`;

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <ContentCopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(id);
              }}
              sx={{
                marginLeft: "6px",
                cursor: "pointer",
                fontSize: "medium",
                color: "rgba(128, 131, 163, 0.7)",
              }}
            />
            <Tooltip title={name}>
              <Typography sx={{ fontSize: "0.875rem" }}>{name ?? ""}</Typography>
            </Tooltip>
          </Box>
        }
      />
    );
  },
  email: (props: any, field: any, rowData: IPerson) => {
    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
            }}
          >
            {rowData.email || "---"}
          </Box>
        }
      />
    );
  },
  phoneNumber: (props: any, field: any, rowData: IPerson) => {
    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.7rem",
            }}
          >
            {rowData.phoneNumber || "---"}
          </Box>
        }
      />
    );
  },
  company: (props: any, field: any, rowData: IPerson) => {
    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <ContentCopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(rowData?.entity?.name);
              }}
              sx={{
                marginLeft: "6px",
                cursor: "pointer",
                fontSize: "medium",
                color: "rgba(128, 131, 163, 0.7)",
              }}
            />
            <Tooltip title={rowData?.entity?.name}>
              <Typography sx={{ fontSize: "0.875rem" }}>{rowData?.entity?.name || "---"}</Typography>
            </Tooltip>
          </Box>
        }
      />
    );
  },
  labels: (props: any, field: any, rowData: IPerson) => {
    const isKeyContact = rowData.id === rowData?.entity?.keyContactId;

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
            }}
          >
            {rowData?.isFlunaUser && (
              <PillBox
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={flunaIcon} alt="flunaIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Fluna User
                    </Box>
                  </Box>
                }
              />
            )}
            {isKeyContact && (
              <PillBox
                text={
                  <Box
                    sx={{
                      gap: ".5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box component="img" src={pastInterIcon} alt="pastInterIcon" />
                    <Box
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                        fontFamily: "SF Pro Display Medium",
                      }}
                    >
                      Key Contact
                    </Box>
                  </Box>
                }
              />
            )}
          </Box>
        }
      />
    );
  },
  country: (props: any, field: any, rowData: IPerson) => {
    const countryInfo = countryDailCode[rowData?.country];

    return (
      <MTableCell
        {...props}
        value={
          <>
            {countryInfo?.isoAlpha2 ? (
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.7rem",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "1rem",
                    height: "1rem",
                  }}
                  src={`/static/countrysvgs/${countryInfo?.isoAlpha2 as string}.svg`}
                />
                <Typography>{countryInfo?.isoAlpha2}</Typography>
              </Box>
            ) : (
              <></>
            )}
          </>
        }
      />
    );
  },
  createdOn: (props: any, field: any, rowData: IPerson) => {
    const { createdOn } = rowData;
    const formattedDate = createdOn ? format(new Date(createdOn), "MMM. d, yyyy") : "";

    return (
      <MTableCell
        {...props}
        value={
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.9rem",
            }}
          >
            <Typography>{formattedDate}</Typography>
          </Box>
        }
      />
    );
  },
});

export const currencies = [
  {
    name: "USD",
    value: "USD",
  },
  {
    name: "NGN",
    value: "NGN",
  },
];

export const units = [
  {
    name: "KG",
    value: "KG",
  },
];
