export const countryDailCode = {
  Afghanistan: {
    id: 1,
    name: "Afghanistan",
    isoAlpha2: "AF",
    isoAlpha3: "AFG",
    isoNumeric: 4,
    currency: { code: "AFN", name: "Afghani", symbol: "؋" },
    flag: "🇦🇫",
    code: "AF",
    dial_code: "+93",
  },
  "Aland Islands": {
    id: 1,
    name: "Aland Islands",
    isoAlpha2: "AX",
    isoAlpha3: "AFG",
    isoNumeric: 4,
    currency: { code: "AXN", name: "Aland", symbol: "؋" },
    flag: "🇦🇫",
    code: "AX",
    dial_code: "+93",
  },
  Albania: {
    id: 2,
    name: "Albania",
    isoAlpha2: "AL",
    isoAlpha3: "ALB",
    isoNumeric: 8,
    currency: { code: "ALL", name: "Lek", symbol: "Lek" },
    flag: "🇦🇱",
    code: "AL",
    dial_code: "+355",
  },
  Algeria: {
    id: 3,
    name: "Algeria",
    isoAlpha2: "DZ",
    isoAlpha3: "DZA",
    isoNumeric: 12,
    currency: { code: "DZD", name: "Dinar", symbol: "" },
    flag: "🇩🇿",
    code: "DZ",
    dial_code: "+213",
  },
  "American Samoa": {
    id: 4,
    name: "American Samoa",
    isoAlpha2: "AS",
    isoAlpha3: "ASM",
    isoNumeric: 16,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇦🇸",
    code: "AS",
    dial_code: "+1684",
  },
  Andorra: {
    id: 5,
    name: "Andorra",
    isoAlpha2: "AD",
    isoAlpha3: "AND",
    isoNumeric: 20,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇦🇩",
    code: "AD",
    dial_code: "+376",
  },
  Angola: {
    id: 6,
    name: "Angola",
    isoAlpha2: "AO",
    isoAlpha3: "AGO",
    isoNumeric: 24,
    currency: { code: "AOA", name: "Kwanza", symbol: "Kz" },
    flag: "🇦🇴",
    code: "AO",
    dial_code: "+244",
  },
  Anguilla: {
    id: 7,
    name: "Anguilla",
    isoAlpha2: "AI",
    isoAlpha3: "AIA",
    isoNumeric: 660,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇦🇮",
    code: "AI",
    dial_code: "+1264",
  },
  "Antigua and Barbuda": {
    id: 9,
    name: "Antigua and Barbuda",
    isoAlpha2: "AG",
    isoAlpha3: "ATG",
    isoNumeric: 28,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇦🇬",
    code: "AG",
    dial_code: "+1268",
  },
  Argentina: {
    id: 10,
    name: "Argentina",
    isoAlpha2: "AR",
    isoAlpha3: "ARG",
    isoNumeric: 32,
    currency: { code: "ARS", name: "Peso", symbol: "$" },
    flag: "🇦🇷",
    code: "AR",
    dial_code: "+54",
  },
  Armenia: {
    id: 11,
    name: "Armenia",
    isoAlpha2: "AM",
    isoAlpha3: "ARM",
    isoNumeric: 51,
    currency: { code: "AMD", name: "Dram", symbol: "" },
    flag: "🇦🇲",
    code: "AM",
    dial_code: "+374",
  },
  Aruba: {
    id: 12,
    name: "Aruba",
    isoAlpha2: "AW",
    isoAlpha3: "ABW",
    isoNumeric: 533,
    currency: { code: "AWG", name: "Guilder", symbol: "ƒ" },
    flag: "🇦🇼",
    code: "AW",
    dial_code: "+297",
  },
  Australia: {
    id: 13,
    name: "Australia",
    isoAlpha2: "AU",
    isoAlpha3: "AUS",
    isoNumeric: 36,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇦🇺",
    code: "AU",
    dial_code: "+61",
  },
  Austria: {
    id: 14,
    name: "Austria",
    isoAlpha2: "AT",
    isoAlpha3: "AUT",
    isoNumeric: 40,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇦🇹",
    code: "AT",
    dial_code: "+43",
  },
  Azerbaijan: {
    id: 15,
    name: "Azerbaijan",
    isoAlpha2: "AZ",
    isoAlpha3: "AZE",
    isoNumeric: 31,
    currency: { code: "AZN", name: "Manat", symbol: "ман" },
    flag: "🇦🇿",
    code: "AZ",
    dial_code: "+994",
  },
  Bahamas: {
    id: 16,
    name: "Bahamas",
    isoAlpha2: "BS",
    isoAlpha3: "BHS",
    isoNumeric: 44,
    currency: { code: "BSD", name: "Dollar", symbol: "$" },
    flag: "🇧🇸",
    code: "BS",
    dial_code: "+1242",
  },
  Bahrain: {
    id: 17,
    name: "Bahrain",
    isoAlpha2: "BH",
    isoAlpha3: "BHR",
    isoNumeric: 48,
    currency: { code: "BHD", name: "Dinar", symbol: "" },
    flag: "🇧🇭",
    code: "BH",
    dial_code: "+973",
  },
  Bangladesh: {
    id: 18,
    name: "Bangladesh",
    isoAlpha2: "BD",
    isoAlpha3: "BGD",
    isoNumeric: 50,
    currency: { code: "BDT", name: "Taka", symbol: "" },
    flag: "🇧🇩",
    code: "BD",
    dial_code: "+880",
  },
  Barbados: {
    id: 19,
    name: "Barbados",
    isoAlpha2: "BB",
    isoAlpha3: "BRB",
    isoNumeric: 52,
    currency: { code: "BBD", name: "Dollar", symbol: "$" },
    flag: "🇧🇧",
    code: "BB",
    dial_code: "+1246",
  },
  Belarus: {
    id: 20,
    name: "Belarus",
    isoAlpha2: "BY",
    isoAlpha3: "BLR",
    isoNumeric: 112,
    currency: { code: "BYR", name: "Ruble", symbol: "p." },
    flag: "🇧🇾",
    code: "BY",
    dial_code: "+375",
  },
  Belgium: {
    id: 21,
    name: "Belgium",
    isoAlpha2: "BE",
    isoAlpha3: "BEL",
    isoNumeric: 56,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇧🇪",
    code: "BE",
    dial_code: "+32",
  },
  Belize: {
    id: 22,
    name: "Belize",
    isoAlpha2: "BZ",
    isoAlpha3: "BLZ",
    isoNumeric: 84,
    currency: { code: "BZD", name: "Dollar", symbol: "BZ$" },
    flag: "🇧🇿",
    code: "BZ",
    dial_code: "+501",
  },
  Benin: {
    id: 23,
    name: "Benin",
    isoAlpha2: "BJ",
    isoAlpha3: "BEN",
    isoNumeric: 204,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇧🇯",
    code: "BJ",
    dial_code: "+229",
  },
  Bermuda: {
    id: 24,
    name: "Bermuda",
    isoAlpha2: "BM",
    isoAlpha3: "BMU",
    isoNumeric: 60,
    currency: { code: "BMD", name: "Dollar", symbol: "$" },
    flag: "🇧🇲",
    code: "BM",
    dial_code: "+1441",
  },
  Bhutan: {
    id: 25,
    name: "Bhutan",
    isoAlpha2: "BT",
    isoAlpha3: "BTN",
    isoNumeric: 64,
    currency: { code: "BTN", name: "Ngultrum", symbol: "" },
    flag: "🇧🇹",
    code: "BT",
    dial_code: "+975",
  },
  Bolivia: {
    id: 26,
    name: "Bolivia",
    isoAlpha2: "BO",
    isoAlpha3: "BOL",
    isoNumeric: 68,
    currency: { code: "BOB", name: "Boliviano", symbol: "$b" },
    flag: "🇹🇫",
  },
  "Bosnia and Herzegovina": {
    id: 27,
    name: "Bosnia and Herzegovina",
    isoAlpha2: "BA",
    isoAlpha3: "BIH",
    isoNumeric: 70,
    currency: { code: "BAM", name: "Marka", symbol: "KM" },
    flag: "🇧🇦",
    code: "BA",
    dial_code: "+387",
  },
  Botswana: {
    id: 28,
    name: "Botswana",
    isoAlpha2: "BW",
    isoAlpha3: "BWA",
    isoNumeric: 72,
    currency: { code: "BWP", name: "Pula", symbol: "P" },
    flag: "🇧🇼",
    code: "BW",
    dial_code: "+267",
  },
  "Bouvet Island": {
    id: 29,
    name: "Bouvet Island",
    isoAlpha2: "BV",
    isoAlpha3: "BVT",
    isoNumeric: 74,
    currency: { code: "NOK", name: "Krone", symbol: "kr" },
    flag: "🇧🇻",
    code: "BV",
    dial_code: "+47",
  },
  Brazil: {
    id: 30,
    name: "Brazil",
    isoAlpha2: "BR",
    isoAlpha3: "BRA",
    isoNumeric: 76,
    currency: { code: "BRL", name: "Real", symbol: "R$" },
    flag: "🇧🇷",
    code: "BR",
    dial_code: "+55",
  },
  "British Indian Ocean Territory": {
    id: 31,
    name: "British Indian Ocean Territory",
    isoAlpha2: "IO",
    isoAlpha3: "IOT",
    isoNumeric: 86,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇮🇴",
    code: "IO",
    dial_code: "+246",
  },
  "British Virgin Islands": {
    id: 32,
    name: "British Virgin Islands",
    isoAlpha2: "VG",
    isoAlpha3: "VGB",
    isoNumeric: 92,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Brunei: {
    id: 33,
    name: "Brunei",
    isoAlpha2: "BN",
    isoAlpha3: "BRN",
    isoNumeric: 96,
    currency: { code: "BND", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Bulgaria: {
    id: 34,
    name: "Bulgaria",
    isoAlpha2: "BG",
    isoAlpha3: "BGR",
    isoNumeric: 100,
    currency: { code: "BGN", name: "Lev", symbol: "лв" },
    flag: "🇧🇬",
    code: "BG",
    dial_code: "+359",
  },
  "Burkina Faso": {
    id: 35,
    name: "Burkina Faso",
    isoAlpha2: "BF",
    isoAlpha3: "BFA",
    isoNumeric: 854,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇧🇫",
    code: "BF",
    dial_code: "+226",
  },
  Burundi: {
    id: 36,
    name: "Burundi",
    isoAlpha2: "BI",
    isoAlpha3: "BDI",
    isoNumeric: 108,
    currency: { code: "BIF", name: "Franc", symbol: "" },
    flag: "🇧🇮",
    code: "BI",
    dial_code: "+257",
  },
  Cambodia: {
    id: 37,
    name: "Cambodia",
    isoAlpha2: "KH",
    isoAlpha3: "KHM",
    isoNumeric: 116,
    currency: { code: "KHR", name: "Riels", symbol: "៛" },
    flag: "🇰🇭",
    code: "KH",
    dial_code: "+855",
  },
  Cameroon: {
    id: 38,
    name: "Cameroon",
    isoAlpha2: "CM",
    isoAlpha3: "CMR",
    isoNumeric: 120,
    currency: { code: "XAF", name: "Franc", symbol: "FCF" },
    flag: "🇨🇲",
    code: "CM",
    dial_code: "+237",
  },
  Canada: {
    id: 39,
    name: "Canada",
    isoAlpha2: "CA",
    isoAlpha3: "CAN",
    isoNumeric: 124,
    currency: { code: "CAD", name: "Dollar", symbol: "$" },
    flag: "🇨🇦",
    code: "CA",
    dial_code: "+1",
  },
  "Cape Verde": {
    id: 40,
    name: "Cape Verde",
    isoAlpha2: "CV",
    isoAlpha3: "CPV",
    isoNumeric: 132,
    currency: { code: "CVE", name: "Escudo", symbol: "" },
    flag: "🇨🇻",
    code: "CV",
    dial_code: "+238",
  },
  "Cayman Islands": {
    id: 41,
    name: "Cayman Islands",
    isoAlpha2: "KY",
    isoAlpha3: "CYM",
    isoNumeric: 136,
    currency: { code: "KYD", name: "Dollar", symbol: "$" },
    flag: "🇰🇾",
    code: "KY",
    dial_code: "+345",
  },
  "Central African Republic": {
    id: 42,
    name: "Central African Republic",
    isoAlpha2: "CF",
    isoAlpha3: "CAF",
    isoNumeric: 140,
    currency: { code: "XAF", name: "Franc", symbol: "FCF" },
    flag: "🇨🇫",
    code: "CF",
    dial_code: "+236",
  },
  Chad: {
    id: 43,
    name: "Chad",
    isoAlpha2: "TD",
    isoAlpha3: "TCD",
    isoNumeric: 148,
    currency: { code: "XAF", name: "Franc", symbol: "" },
    flag: "🇹🇩",
    code: "TD",
    dial_code: "+235",
  },
  Chile: {
    id: 44,
    name: "Chile",
    isoAlpha2: "CL",
    isoAlpha3: "CHL",
    isoNumeric: 152,
    currency: { code: "CLP", name: "Peso", symbol: "" },
    flag: "🇨🇱",
    code: "CL",
    dial_code: "+56",
  },
  China: {
    id: 45,
    name: "China",
    isoAlpha2: "CN",
    isoAlpha3: "CHN",
    isoNumeric: 156,
    currency: { code: "CNY", name: "YuanRenminbi", symbol: "¥" },
    flag: "🇨🇳",
    code: "CN",
    dial_code: "+86",
  },
  "Christmas Island": {
    id: 46,
    name: "Christmas Island",
    isoAlpha2: "CX",
    isoAlpha3: "CXR",
    isoNumeric: 162,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇨🇽",
    code: "CX",
    dial_code: "+61",
  },
  "Cocos Islands": {
    id: 47,
    name: "Cocos Islands",
    isoAlpha2: "CC",
    isoAlpha3: "CCK",
    isoNumeric: 166,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Colombia: {
    id: 48,
    name: "Colombia",
    isoAlpha2: "CO",
    isoAlpha3: "COL",
    isoNumeric: 170,
    currency: { code: "COP", name: "Peso", symbol: "$" },
    flag: "🇨🇴",
    code: "CO",
    dial_code: "+57",
  },
  Comoros: {
    id: 49,
    name: "Comoros",
    isoAlpha2: "KM",
    isoAlpha3: "COM",
    isoNumeric: 174,
    currency: { code: "KMF", name: "Franc", symbol: "" },
    flag: "🇰🇲",
    code: "KM",
    dial_code: "+269",
  },
  "Cook Islands": {
    id: 50,
    name: "Cook Islands",
    isoAlpha2: "CK",
    isoAlpha3: "COK",
    isoNumeric: 184,
    currency: { code: "NZD", name: "Dollar", symbol: "$" },
    flag: "🇨🇰",
    code: "CK",
    dial_code: "+682",
  },
  "Costa Rica": {
    id: 51,
    name: "Costa Rica",
    isoAlpha2: "CR",
    isoAlpha3: "CRI",
    isoNumeric: 188,
    currency: { code: "CRC", name: "Colon", symbol: "₡" },
    flag: "🇨🇷",
    code: "CR",
    dial_code: "+506",
  },
  Croatia: {
    id: 52,
    name: "Croatia",
    isoAlpha2: "HR",
    isoAlpha3: "HRV",
    isoNumeric: 191,
    currency: { code: "HRK", name: "Kuna", symbol: "kn" },
    flag: "🇭🇷",
    code: "HR",
    dial_code: "+385",
  },
  Cuba: {
    id: 53,
    name: "Cuba",
    isoAlpha2: "CU",
    isoAlpha3: "CUB",
    isoNumeric: 192,
    currency: { code: "CUP", name: "Peso", symbol: "₱" },
    flag: "🇨🇺",
    code: "CU",
    dial_code: "+53",
  },
  Cyprus: {
    id: 54,
    name: "Cyprus",
    isoAlpha2: "CY",
    isoAlpha3: "CYP",
    isoNumeric: 196,
    currency: { code: "CYP", name: "Pound", symbol: "" },
    flag: "🇨🇾",
    code: "CY",
    dial_code: "+357",
  },
  "Czech Republic": {
    id: 55,
    name: "Czech Republic",
    isoAlpha2: "CZ",
    isoAlpha3: "CZE",
    isoNumeric: 203,
    currency: { code: "CZK", name: "Koruna", symbol: "Kč" },
    flag: "🇨🇿",
    code: "CZ",
    dial_code: "+420",
  },
  "Democratic Republic of the Congo": {
    id: 56,
    name: "Democratic Republic of the Congo",
    isoAlpha2: "CD",
    isoAlpha3: "COD",
    isoNumeric: 180,
    currency: { code: "CDF", name: "Franc", symbol: "" },
    flag: "🇹🇫",
  },
  Denmark: {
    id: 57,
    name: "Denmark",
    isoAlpha2: "DK",
    isoAlpha3: "DNK",
    isoNumeric: 208,
    currency: { code: "DKK", name: "Krone", symbol: "kr" },
    flag: "🇩🇰",
    code: "DK",
    dial_code: "+45",
  },
  Djibouti: {
    id: 58,
    name: "Djibouti",
    isoAlpha2: "DJ",
    isoAlpha3: "DJI",
    isoNumeric: 262,
    currency: { code: "DJF", name: "Franc", symbol: "" },
    flag: "🇩🇯",
    code: "DJ",
    dial_code: "+253",
  },
  Dominica: {
    id: 59,
    name: "Dominica",
    isoAlpha2: "DM",
    isoAlpha3: "DMA",
    isoNumeric: 212,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇩🇲",
    code: "DM",
    dial_code: "+1767",
  },
  "Dominican Republic": {
    id: 60,
    name: "Dominican Republic",
    isoAlpha2: "DO",
    isoAlpha3: "DOM",
    isoNumeric: 214,
    currency: { code: "DOP", name: "Peso", symbol: "RD$" },
    flag: "🇩🇴",
    code: "DO",
    dial_code: "+1849",
  },
  "East Timor": {
    id: 61,
    name: "East Timor",
    isoAlpha2: "TL",
    isoAlpha3: "TLS",
    isoNumeric: 626,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Ecuador: {
    id: 62,
    name: "Ecuador",
    isoAlpha2: "EC",
    isoAlpha3: "ECU",
    isoNumeric: 218,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇪🇨",
    code: "EC",
    dial_code: "+593",
  },
  Egypt: {
    id: 63,
    name: "Egypt",
    isoAlpha2: "EG",
    isoAlpha3: "EGY",
    isoNumeric: 818,
    currency: { code: "EGP", name: "Pound", symbol: "£" },
    flag: "🇪🇬",
    code: "EG",
    dial_code: "+20",
  },
  "El Salvador": {
    id: 64,
    name: "El Salvador",
    isoAlpha2: "SV",
    isoAlpha3: "SLV",
    isoNumeric: 222,
    currency: { code: "SVC", name: "Colone", symbol: "$" },
    flag: "🇸🇻",
    code: "SV",
    dial_code: "+503",
  },
  "Equatorial Guinea": {
    id: 65,
    name: "Equatorial Guinea",
    isoAlpha2: "GQ",
    isoAlpha3: "GNQ",
    isoNumeric: 226,
    currency: { code: "XAF", name: "Franc", symbol: "FCF" },
    flag: "🇬🇶",
    code: "GQ",
    dial_code: "+240",
  },
  Eritrea: {
    id: 66,
    name: "Eritrea",
    isoAlpha2: "ER",
    isoAlpha3: "ERI",
    isoNumeric: 232,
    currency: { code: "ERN", name: "Nakfa", symbol: "Nfk" },
    flag: "🇪🇷",
    code: "ER",
    dial_code: "+291",
  },
  Estonia: {
    id: 67,
    name: "Estonia",
    isoAlpha2: "EE",
    isoAlpha3: "EST",
    isoNumeric: 233,
    currency: { code: "EEK", name: "Kroon", symbol: "kr" },
    flag: "🇪🇪",
    code: "EE",
    dial_code: "+372",
  },
  Ethiopia: {
    id: 68,
    name: "Ethiopia",
    isoAlpha2: "ET",
    isoAlpha3: "ETH",
    isoNumeric: 231,
    currency: { code: "ETB", name: "Birr", symbol: "" },
    flag: "🇪🇹",
    code: "ET",
    dial_code: "+251",
  },
  "Falkland Islands": {
    id: 69,
    name: "Falkland Islands",
    isoAlpha2: "FK",
    isoAlpha3: "FLK",
    isoNumeric: 238,
    currency: { code: "FKP", name: "Pound", symbol: "£" },
    flag: "🇹🇫",
  },
  "Faroe Islands": {
    id: 70,
    name: "Faroe Islands",
    isoAlpha2: "FO",
    isoAlpha3: "FRO",
    isoNumeric: 234,
    currency: { code: "DKK", name: "Krone", symbol: "kr" },
    flag: "🇫🇴",
    code: "FO",
    dial_code: "+298",
  },
  Fiji: {
    id: 71,
    name: "Fiji",
    isoAlpha2: "FJ",
    isoAlpha3: "FJI",
    isoNumeric: 242,
    currency: { code: "FJD", name: "Dollar", symbol: "$" },
    flag: "🇫🇯",
    code: "FJ",
    dial_code: "+679",
  },
  Finland: {
    id: 72,
    name: "Finland",
    isoAlpha2: "FI",
    isoAlpha3: "FIN",
    isoNumeric: 246,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇫🇮",
    code: "FI",
    dial_code: "+358",
  },
  France: {
    id: 73,
    name: "France",
    isoAlpha2: "FR",
    isoAlpha3: "FRA",
    isoNumeric: 250,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇫🇷",
    code: "FR",
    dial_code: "+33",
  },
  "French Guiana": {
    id: 74,
    name: "French Guiana",
    isoAlpha2: "GF",
    isoAlpha3: "GUF",
    isoNumeric: 254,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇬🇫",
    code: "GF",
    dial_code: "+594",
  },
  "French Polynesia": {
    id: 75,
    name: "French Polynesia",
    isoAlpha2: "PF",
    isoAlpha3: "PYF",
    isoNumeric: 258,
    currency: { code: "XPF", name: "Franc", symbol: "" },
    flag: "🇵🇫",
    code: "PF",
    dial_code: "+689",
  },
  "French Southern Territories": {
    id: 76,
    name: "French Southern Territories",
    isoAlpha2: "TF",
    isoAlpha3: "ATF",
    isoNumeric: 260,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇹🇫",
    code: "TF",
    dial_code: "+262",
  },
  Gabon: {
    id: 77,
    name: "Gabon",
    isoAlpha2: "GA",
    isoAlpha3: "GAB",
    isoNumeric: 266,
    currency: { code: "XAF", name: "Franc", symbol: "FCF" },
    flag: "🇬🇦",
    code: "GA",
    dial_code: "+241",
  },
  Gambia: {
    id: 78,
    name: "Gambia",
    isoAlpha2: "GM",
    isoAlpha3: "GMB",
    isoNumeric: 270,
    currency: { code: "GMD", name: "Dalasi", symbol: "D" },
    flag: "🇬🇲",
    code: "GM",
    dial_code: "+220",
  },
  Georgia: {
    id: 79,
    name: "Georgia",
    isoAlpha2: "GE",
    isoAlpha3: "GEO",
    isoNumeric: 268,
    currency: { code: "GEL", name: "Lari", symbol: "" },
    flag: "🇬🇪",
    code: "GE",
    dial_code: "+995",
  },
  Germany: {
    id: 80,
    name: "Germany",
    isoAlpha2: "DE",
    isoAlpha3: "DEU",
    isoNumeric: 276,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇩🇪",
    code: "DE",
    dial_code: "+49",
  },
  Ghana: {
    id: 81,
    name: "Ghana",
    isoAlpha2: "GH",
    isoAlpha3: "GHA",
    isoNumeric: 288,
    currency: { code: "GHC", name: "Cedi", symbol: "¢" },
    flag: "🇬🇭",
    code: "GH",
    dial_code: "+233",
  },
  Gibraltar: {
    id: 82,
    name: "Gibraltar",
    isoAlpha2: "GI",
    isoAlpha3: "GIB",
    isoNumeric: 292,
    currency: { code: "GIP", name: "Pound", symbol: "£" },
    flag: "🇬🇮",
    code: "GI",
    dial_code: "+350",
  },
  Greece: {
    id: 83,
    name: "Greece",
    isoAlpha2: "GR",
    isoAlpha3: "GRC",
    isoNumeric: 300,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇬🇷",
    code: "GR",
    dial_code: "+30",
  },
  Greenland: {
    id: 84,
    name: "Greenland",
    isoAlpha2: "GL",
    isoAlpha3: "GRL",
    isoNumeric: 304,
    currency: { code: "DKK", name: "Krone", symbol: "kr" },
    flag: "🇬🇱",
    code: "GL",
    dial_code: "+299",
  },
  Grenada: {
    id: 85,
    name: "Grenada",
    isoAlpha2: "GD",
    isoAlpha3: "GRD",
    isoNumeric: 308,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇬🇩",
    code: "GD",
    dial_code: "+1473",
  },
  Guadeloupe: {
    id: 86,
    name: "Guadeloupe",
    isoAlpha2: "GP",
    isoAlpha3: "GLP",
    isoNumeric: 312,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇬🇵",
    code: "GP",
    dial_code: "+590",
  },
  Guam: {
    id: 87,
    name: "Guam",
    isoAlpha2: "GU",
    isoAlpha3: "GUM",
    isoNumeric: 316,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇬🇺",
    code: "GU",
    dial_code: "+1671",
  },
  Guatemala: {
    id: 88,
    name: "Guatemala",
    isoAlpha2: "GT",
    isoAlpha3: "GTM",
    isoNumeric: 320,
    currency: { code: "GTQ", name: "Quetzal", symbol: "Q" },
    flag: "🇬🇹",
    code: "GT",
    dial_code: "+502",
  },
  Guinea: {
    id: 89,
    name: "Guinea",
    isoAlpha2: "GN",
    isoAlpha3: "GIN",
    isoNumeric: 324,
    currency: { code: "GNF", name: "Franc", symbol: "" },
    flag: "🇬🇳",
    code: "GN",
    dial_code: "+224",
  },
  "Guinea-Bissau": {
    id: 90,
    name: "Guinea-Bissau",
    isoAlpha2: "GW",
    isoAlpha3: "GNB",
    isoNumeric: 624,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇬🇼",
    code: "GW",
    dial_code: "+245",
  },
  Guyana: {
    id: 91,
    name: "Guyana",
    isoAlpha2: "GY",
    isoAlpha3: "GUY",
    isoNumeric: 328,
    currency: { code: "GYD", name: "Dollar", symbol: "$" },
    flag: "🇬🇾",
    code: "GY",
    dial_code: "+592",
  },
  Haiti: {
    id: 92,
    name: "Haiti",
    isoAlpha2: "HT",
    isoAlpha3: "HTI",
    isoNumeric: 332,
    currency: { code: "HTG", name: "Gourde", symbol: "G" },
    flag: "🇭🇹",
    code: "HT",
    dial_code: "+509",
  },
  "Heard Island and McDonald Islands": {
    id: 93,
    name: "Heard Island and McDonald Islands",
    isoAlpha2: "HM",
    isoAlpha3: "HMD",
    isoNumeric: 334,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Honduras: {
    id: 94,
    name: "Honduras",
    isoAlpha2: "HN",
    isoAlpha3: "HND",
    isoNumeric: 340,
    currency: { code: "HNL", name: "Lempira", symbol: "L" },
    flag: "🇭🇳",
    code: "HN",
    dial_code: "+504",
  },
  "Hong Kong": {
    id: 95,
    name: "Hong Kong",
    isoAlpha2: "HK",
    isoAlpha3: "HKG",
    isoNumeric: 344,
    currency: { code: "HKD", name: "Dollar", symbol: "$" },
    flag: "🇭🇰",
    code: "HK",
    dial_code: "+852",
  },
  Hungary: {
    id: 96,
    name: "Hungary",
    isoAlpha2: "HU",
    isoAlpha3: "HUN",
    isoNumeric: 348,
    currency: { code: "HUF", name: "Forint", symbol: "Ft" },
    flag: "🇭🇺",
    code: "HU",
    dial_code: "+36",
  },
  Iceland: {
    id: 97,
    name: "Iceland",
    isoAlpha2: "IS",
    isoAlpha3: "ISL",
    isoNumeric: 352,
    currency: { code: "ISK", name: "Krona", symbol: "kr" },
    flag: "🇮🇸",
    code: "IS",
    dial_code: "+354",
  },
  India: {
    id: 98,
    name: "India",
    isoAlpha2: "IN",
    isoAlpha3: "IND",
    isoNumeric: 356,
    currency: { code: "INR", name: "Rupee", symbol: "₹" },
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  },
  Indonesia: {
    id: 99,
    name: "Indonesia",
    isoAlpha2: "ID",
    isoAlpha3: "IDN",
    isoNumeric: 360,
    currency: { code: "IDR", name: "Rupiah", symbol: "Rp" },
    flag: "🇮🇩",
    code: "ID",
    dial_code: "+62",
  },
  Iran: {
    id: 100,
    name: "Iran",
    isoAlpha2: "IR",
    isoAlpha3: "IRN",
    isoNumeric: 364,
    currency: { code: "IRR", name: "Rial", symbol: "﷼" },
    flag: "🇹🇫",
  },
  Iraq: {
    id: 101,
    name: "Iraq",
    isoAlpha2: "IQ",
    isoAlpha3: "IRQ",
    isoNumeric: 368,
    currency: { code: "IQD", name: "Dinar", symbol: "" },
    flag: "🇮🇶",
    code: "IQ",
    dial_code: "+964",
  },
  Ireland: {
    id: 102,
    name: "Ireland",
    isoAlpha2: "IE",
    isoAlpha3: "IRL",
    isoNumeric: 372,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇮🇪",
    code: "IE",
    dial_code: "+353",
  },
  Israel: {
    id: 103,
    name: "Israel",
    isoAlpha2: "IL",
    isoAlpha3: "ISR",
    isoNumeric: 376,
    currency: { code: "ILS", name: "Shekel", symbol: "₪" },
    flag: "🇮🇱",
    code: "IL",
    dial_code: "+972",
  },
  Italy: {
    id: 104,
    name: "Italy",
    isoAlpha2: "IT",
    isoAlpha3: "ITA",
    isoNumeric: 380,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39",
  },
  "Ivory Coast": {
    id: 105,
    name: "Ivory Coast",
    isoAlpha2: "CI",
    isoAlpha3: "CIV",
    isoNumeric: 384,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇹🇫",
  },
  Jamaica: {
    id: 106,
    name: "Jamaica",
    isoAlpha2: "JM",
    isoAlpha3: "JAM",
    isoNumeric: 388,
    currency: { code: "JMD", name: "Dollar", symbol: "$" },
    flag: "🇯🇲",
    code: "JM",
    dial_code: "+1876",
  },
  Japan: {
    id: 107,
    name: "Japan",
    isoAlpha2: "JP",
    isoAlpha3: "JPN",
    isoNumeric: 392,
    currency: { code: "JPY", name: "Yen", symbol: "¥" },
    flag: "🇯🇵",
    code: "JP",
    dial_code: "+81",
  },
  Jordan: {
    id: 108,
    name: "Jordan",
    isoAlpha2: "JO",
    isoAlpha3: "JOR",
    isoNumeric: 400,
    currency: { code: "JOD", name: "Dinar", symbol: "" },
    flag: "🇯🇴",
    code: "JO",
    dial_code: "+962",
  },
  Kazakhstan: {
    id: 109,
    name: "Kazakhstan",
    isoAlpha2: "KZ",
    isoAlpha3: "KAZ",
    isoNumeric: 398,
    currency: { code: "KZT", name: "Tenge", symbol: "лв" },
    flag: "🇰🇿",
    code: "KZ",
    dial_code: "+7",
  },
  Kenya: {
    id: 110,
    name: "Kenya",
    isoAlpha2: "KE",
    isoAlpha3: "KEN",
    isoNumeric: 404,
    currency: { code: "KES", name: "Shilling", symbol: "" },
    flag: "🇰🇪",
    code: "KE",
    dial_code: "+254",
  },
  Kiribati: {
    id: 111,
    name: "Kiribati",
    isoAlpha2: "KI",
    isoAlpha3: "KIR",
    isoNumeric: 296,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇰🇮",
    code: "KI",
    dial_code: "+686",
  },
  Kuwait: {
    id: 112,
    name: "Kuwait",
    isoAlpha2: "KW",
    isoAlpha3: "KWT",
    isoNumeric: 414,
    currency: { code: "KWD", name: "Dinar", symbol: "" },
    flag: "🇰🇼",
    code: "KW",
    dial_code: "+965",
  },
  Kyrgyzstan: {
    id: 113,
    name: "Kyrgyzstan",
    isoAlpha2: "KG",
    isoAlpha3: "KGZ",
    isoNumeric: 417,
    currency: { code: "KGS", name: "Som", symbol: "лв" },
    flag: "🇰🇬",
    code: "KG",
    dial_code: "+996",
  },
  Laos: {
    id: 114,
    name: "Laos",
    isoAlpha2: "LA",
    isoAlpha3: "LAO",
    isoNumeric: 418,
    currency: { code: "LAK", name: "Kip", symbol: "₭" },
    flag: "🇱🇦",
    code: "LA",
    dial_code: "+856",
  },
  Latvia: {
    id: 115,
    name: "Latvia",
    isoAlpha2: "LV",
    isoAlpha3: "LVA",
    isoNumeric: 428,
    currency: { code: "LVL", name: "Lat", symbol: "Ls" },
    flag: "🇱🇻",
    code: "LV",
    dial_code: "+371",
  },
  Lebanon: {
    id: 116,
    name: "Lebanon",
    isoAlpha2: "LB",
    isoAlpha3: "LBN",
    isoNumeric: 422,
    currency: { code: "LBP", name: "Pound", symbol: "£" },
    flag: "🇱🇧",
    code: "LB",
    dial_code: "+961",
  },
  Lesotho: {
    id: 117,
    name: "Lesotho",
    isoAlpha2: "LS",
    isoAlpha3: "LSO",
    isoNumeric: 426,
    currency: { code: "LSL", name: "Loti", symbol: "L" },
    flag: "🇱🇸",
    code: "LS",
    dial_code: "+266",
  },
  Liberia: {
    id: 118,
    name: "Liberia",
    isoAlpha2: "LR",
    isoAlpha3: "LBR",
    isoNumeric: 430,
    currency: { code: "LRD", name: "Dollar", symbol: "$" },
    flag: "🇱🇷",
    code: "LR",
    dial_code: "+231",
  },
  Libya: {
    id: 119,
    name: "Libya",
    isoAlpha2: "LY",
    isoAlpha3: "LBY",
    isoNumeric: 434,
    currency: { code: "LYD", name: "Dinar", symbol: "" },
    flag: "🇹🇫",
  },
  Liechtenstein: {
    id: 120,
    name: "Liechtenstein",
    isoAlpha2: "LI",
    isoAlpha3: "LIE",
    isoNumeric: 438,
    currency: { code: "CHF", name: "Franc", symbol: "CHF" },
    flag: "🇱🇮",
    code: "LI",
    dial_code: "+423",
  },
  Lithuania: {
    id: 121,
    name: "Lithuania",
    isoAlpha2: "LT",
    isoAlpha3: "LTU",
    isoNumeric: 440,
    currency: { code: "LTL", name: "Litas", symbol: "Lt" },
    flag: "🇱🇹",
    code: "LT",
    dial_code: "+370",
  },
  Luxembourg: {
    id: 122,
    name: "Luxembourg",
    isoAlpha2: "LU",
    isoAlpha3: "LUX",
    isoNumeric: 442,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇱🇺",
    code: "LU",
    dial_code: "+352",
  },
  Macao: {
    id: 123,
    name: "Macao",
    isoAlpha2: "MO",
    isoAlpha3: "MAC",
    isoNumeric: 446,
    currency: { code: "MOP", name: "Pataca", symbol: "MOP" },
    flag: "🇲🇴",
    code: "MO",
    dial_code: "+853",
  },
  Macedonia: {
    id: 124,
    name: "Macedonia",
    isoAlpha2: "MK",
    isoAlpha3: "MKD",
    isoNumeric: 807,
    currency: { code: "MKD", name: "Denar", symbol: "ден" },
    flag: "🇲🇰",
    code: "MK",
    dial_code: "+389",
  },
  Madagascar: {
    id: 125,
    name: "Madagascar",
    isoAlpha2: "MG",
    isoAlpha3: "MDG",
    isoNumeric: 450,
    currency: { code: "MGA", name: "Ariary", symbol: "" },
    flag: "🇲🇬",
    code: "MG",
    dial_code: "+261",
  },
  Malawi: {
    id: 126,
    name: "Malawi",
    isoAlpha2: "MW",
    isoAlpha3: "MWI",
    isoNumeric: 454,
    currency: { code: "MWK", name: "Kwacha", symbol: "MK" },
    flag: "🇲🇼",
    code: "MW",
    dial_code: "+265",
  },
  Malaysia: {
    id: 127,
    name: "Malaysia",
    isoAlpha2: "MY",
    isoAlpha3: "MYS",
    isoNumeric: 458,
    currency: { code: "MYR", name: "Ringgit", symbol: "RM" },
    flag: "🇲🇾",
    code: "MY",
    dial_code: "+60",
  },
  Maldives: {
    id: 128,
    name: "Maldives",
    isoAlpha2: "MV",
    isoAlpha3: "MDV",
    isoNumeric: 462,
    currency: { code: "MVR", name: "Rufiyaa", symbol: "Rf" },
    flag: "🇲🇻",
    code: "MV",
    dial_code: "+960",
  },
  Mali: {
    id: 129,
    name: "Mali",
    isoAlpha2: "ML",
    isoAlpha3: "MLI",
    isoNumeric: 466,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇲🇱",
    code: "ML",
    dial_code: "+223",
  },
  Malta: {
    id: 130,
    name: "Malta",
    isoAlpha2: "MT",
    isoAlpha3: "MLT",
    isoNumeric: 470,
    currency: { code: "MTL", name: "Lira", symbol: "" },
    flag: "🇲🇹",
    code: "MT",
    dial_code: "+356",
  },
  "Marshall Islands": {
    id: 131,
    name: "Marshall Islands",
    isoAlpha2: "MH",
    isoAlpha3: "MHL",
    isoNumeric: 584,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇲🇭",
    code: "MH",
    dial_code: "+692",
  },
  Martinique: {
    id: 132,
    name: "Martinique",
    isoAlpha2: "MQ",
    isoAlpha3: "MTQ",
    isoNumeric: 474,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇲🇶",
    code: "MQ",
    dial_code: "+596",
  },
  Mauritania: {
    id: 133,
    name: "Mauritania",
    isoAlpha2: "MR",
    isoAlpha3: "MRT",
    isoNumeric: 478,
    currency: { code: "MRO", name: "Ouguiya", symbol: "UM" },
    flag: "🇲🇷",
    code: "MR",
    dial_code: "+222",
  },
  Mauritius: {
    id: 134,
    name: "Mauritius",
    isoAlpha2: "MU",
    isoAlpha3: "MUS",
    isoNumeric: 480,
    currency: { code: "MUR", name: "Rupee", symbol: "₨" },
    flag: "🇲🇺",
    code: "MU",
    dial_code: "+230",
  },
  Mayotte: {
    id: 135,
    name: "Mayotte",
    isoAlpha2: "YT",
    isoAlpha3: "MYT",
    isoNumeric: 175,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇾🇹",
    code: "YT",
    dial_code: "+262",
  },
  Mexico: {
    id: 136,
    name: "Mexico",
    isoAlpha2: "MX",
    isoAlpha3: "MEX",
    isoNumeric: 484,
    currency: { code: "MXN", name: "Peso", symbol: "$" },
    flag: "🇲🇽",
    code: "MX",
    dial_code: "+52",
  },
  Micronesia: {
    id: 137,
    name: "Micronesia",
    isoAlpha2: "FM",
    isoAlpha3: "FSM",
    isoNumeric: 583,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Moldova: {
    id: 138,
    name: "Moldova",
    isoAlpha2: "MD",
    isoAlpha3: "MDA",
    isoNumeric: 498,
    currency: { code: "MDL", name: "Leu", symbol: "" },
    flag: "🇲🇩",
    code: "MD",
    dial_code: "+373",
  },
  Monaco: {
    id: 139,
    name: "Monaco",
    isoAlpha2: "MC",
    isoAlpha3: "MCO",
    isoNumeric: 492,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇲🇨",
    code: "MC",
    dial_code: "+377",
  },
  Mongolia: {
    id: 140,
    name: "Mongolia",
    isoAlpha2: "MN",
    isoAlpha3: "MNG",
    isoNumeric: 496,
    currency: { code: "MNT", name: "Tugrik", symbol: "₮" },
    flag: "🇲🇳",
    code: "MN",
    dial_code: "+976",
  },
  Montserrat: {
    id: 141,
    name: "Montserrat",
    isoAlpha2: "MS",
    isoAlpha3: "MSR",
    isoNumeric: 500,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇲🇸",
    code: "MS",
    dial_code: "+1664",
  },
  Morocco: {
    id: 142,
    name: "Morocco",
    isoAlpha2: "MA",
    isoAlpha3: "MAR",
    isoNumeric: 504,
    currency: { code: "MAD", name: "Dirham", symbol: "" },
    flag: "🇲🇦",
    code: "MA",
    dial_code: "+212",
  },
  Mozambique: {
    id: 143,
    name: "Mozambique",
    isoAlpha2: "MZ",
    isoAlpha3: "MOZ",
    isoNumeric: 508,
    currency: { code: "MZN", name: "Meticail", symbol: "MT" },
    flag: "🇲🇿",
    code: "MZ",
    dial_code: "+258",
  },
  Myanmar: {
    id: 144,
    name: "Myanmar",
    isoAlpha2: "MM",
    isoAlpha3: "MMR",
    isoNumeric: 104,
    currency: { code: "MMK", name: "Kyat", symbol: "K" },
    flag: "🇲🇲",
    code: "MM",
    dial_code: "+95",
  },
  Namibia: {
    id: 145,
    name: "Namibia",
    isoAlpha2: "NA",
    isoAlpha3: "NAM",
    isoNumeric: 516,
    currency: { code: "NAD", name: "Dollar", symbol: "$" },
    flag: "🇳🇦",
    code: "NA",
    dial_code: "+264",
  },
  Nauru: {
    id: 146,
    name: "Nauru",
    isoAlpha2: "NR",
    isoAlpha3: "NRU",
    isoNumeric: 520,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇳🇷",
    code: "NR",
    dial_code: "+674",
  },
  Nepal: {
    id: 147,
    name: "Nepal",
    isoAlpha2: "NP",
    isoAlpha3: "NPL",
    isoNumeric: 524,
    currency: { code: "NPR", name: "Rupee", symbol: "₨" },
    flag: "🇳🇵",
    code: "NP",
    dial_code: "+977",
  },
  Netherlands: {
    id: 148,
    name: "Netherlands",
    isoAlpha2: "NL",
    isoAlpha3: "NLD",
    isoNumeric: 528,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇳🇱",
    code: "NL",
    dial_code: "+31",
  },
  "Netherlands Antilles": {
    id: 149,
    name: "Netherlands Antilles",
    isoAlpha2: "AN",
    isoAlpha3: "ANT",
    isoNumeric: 530,
    currency: { code: "ANG", name: "Guilder", symbol: "ƒ" },
    flag: "",
    code: "AN",
    dial_code: "+599",
  },
  "New Caledonia": {
    id: 150,
    name: "New Caledonia",
    isoAlpha2: "NC",
    isoAlpha3: "NCL",
    isoNumeric: 540,
    currency: { code: "XPF", name: "Franc", symbol: "" },
    flag: "🇳🇨",
    code: "NC",
    dial_code: "+687",
  },
  "New Zealand": {
    id: 151,
    name: "New Zealand",
    isoAlpha2: "NZ",
    isoAlpha3: "NZL",
    isoNumeric: 554,
    currency: { code: "NZD", name: "Dollar", symbol: "$" },
    flag: "🇳🇿",
    code: "NZ",
    dial_code: "+64",
  },
  Nicaragua: {
    id: 152,
    name: "Nicaragua",
    isoAlpha2: "NI",
    isoAlpha3: "NIC",
    isoNumeric: 558,
    currency: { code: "NIO", name: "Cordoba", symbol: "C$" },
    flag: "🇳🇮",
    code: "NI",
    dial_code: "+505",
  },
  Niger: {
    id: 153,
    name: "Niger",
    isoAlpha2: "NE",
    isoAlpha3: "NER",
    isoNumeric: 562,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇳🇪",
    code: "NE",
    dial_code: "+227",
  },
  Nigeria: {
    id: 154,
    name: "Nigeria",
    isoAlpha2: "NG",
    isoAlpha3: "NGA",
    isoNumeric: 566,
    currency: { code: "NGN", name: "Naira", symbol: "₦" },
    flag: "🇳🇬",
    code: "NG",
    dial_code: "+234",
  },
  Niue: {
    id: 155,
    name: "Niue",
    isoAlpha2: "NU",
    isoAlpha3: "NIU",
    isoNumeric: 570,
    currency: { code: "NZD", name: "Dollar", symbol: "$" },
    flag: "🇳🇺",
    code: "NU",
    dial_code: "+683",
  },
  "Norfolk Island": {
    id: 156,
    name: "Norfolk Island",
    isoAlpha2: "NF",
    isoAlpha3: "NFK",
    isoNumeric: 574,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇳🇫",
    code: "NF",
    dial_code: "+672",
  },
  "North Korea": {
    id: 157,
    name: "North Korea",
    isoAlpha2: "KP",
    isoAlpha3: "PRK",
    isoNumeric: 408,
    currency: { code: "KPW", name: "Won", symbol: "₩" },
    flag: "🇹🇫",
  },
  "Northern Mariana Islands": {
    id: 158,
    name: "Northern Mariana Islands",
    isoAlpha2: "MP",
    isoAlpha3: "MNP",
    isoNumeric: 580,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇲🇵",
    code: "MP",
    dial_code: "+1670",
  },
  Norway: {
    id: 159,
    name: "Norway",
    isoAlpha2: "NO",
    isoAlpha3: "NOR",
    isoNumeric: 578,
    currency: { code: "NOK", name: "Krone", symbol: "kr" },
    flag: "🇳🇴",
    code: "NO",
    dial_code: "+47",
  },
  Oman: {
    id: 160,
    name: "Oman",
    isoAlpha2: "OM",
    isoAlpha3: "OMN",
    isoNumeric: 512,
    currency: { code: "OMR", name: "Rial", symbol: "﷼" },
    flag: "🇴🇲",
    code: "OM",
    dial_code: "+968",
  },
  Pakistan: {
    id: 161,
    name: "Pakistan",
    isoAlpha2: "PK",
    isoAlpha3: "PAK",
    isoNumeric: 586,
    currency: { code: "PKR", name: "Rupee", symbol: "₨" },
    flag: "🇵🇰",
    code: "PK",
    dial_code: "+92",
  },
  Palau: {
    id: 162,
    name: "Palau",
    isoAlpha2: "PW",
    isoAlpha3: "PLW",
    isoNumeric: 585,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇵🇼",
    code: "PW",
    dial_code: "+680",
  },
  "Palestinian Territory": {
    id: 163,
    name: "Palestinian Territory",
    isoAlpha2: "PS",
    isoAlpha3: "PSE",
    isoNumeric: 275,
    currency: { code: "ILS", name: "Shekel", symbol: "₪" },
    flag: "🇹🇫",
  },
  Panama: {
    id: 164,
    name: "Panama",
    isoAlpha2: "PA",
    isoAlpha3: "PAN",
    isoNumeric: 591,
    currency: { code: "PAB", name: "Balboa", symbol: "B/." },
    flag: "🇵🇦",
    code: "PA",
    dial_code: "+507",
  },
  "Papua New Guinea": {
    id: 165,
    name: "Papua New Guinea",
    isoAlpha2: "PG",
    isoAlpha3: "PNG",
    isoNumeric: 598,
    currency: { code: "PGK", name: "Kina", symbol: "" },
    flag: "🇵🇬",
    code: "PG",
    dial_code: "+675",
  },
  Paraguay: {
    id: 166,
    name: "Paraguay",
    isoAlpha2: "PY",
    isoAlpha3: "PRY",
    isoNumeric: 600,
    currency: { code: "PYG", name: "Guarani", symbol: "Gs" },
    flag: "🇵🇾",
    code: "PY",
    dial_code: "+595",
  },
  Peru: {
    id: 167,
    name: "Peru",
    isoAlpha2: "PE",
    isoAlpha3: "PER",
    isoNumeric: 604,
    currency: { code: "PEN", name: "Sol", symbol: "S/." },
    flag: "🇵🇪",
    code: "PE",
    dial_code: "+51",
  },
  Philippines: {
    id: 168,
    name: "Philippines",
    isoAlpha2: "PH",
    isoAlpha3: "PHL",
    isoNumeric: 608,
    currency: { code: "PHP", name: "Peso", symbol: "Php" },
    flag: "🇵🇭",
    code: "PH",
    dial_code: "+63",
  },
  Pitcairn: {
    id: 169,
    name: "Pitcairn",
    isoAlpha2: "PN",
    isoAlpha3: "PCN",
    isoNumeric: 612,
    currency: { code: "NZD", name: "Dollar", symbol: "$" },
    flag: "🇵🇳",
    code: "PN",
    dial_code: "+64",
  },
  Poland: {
    id: 170,
    name: "Poland",
    isoAlpha2: "PL",
    isoAlpha3: "POL",
    isoNumeric: 616,
    currency: { code: "PLN", name: "Zloty", symbol: "zł" },
    flag: "🇵🇱",
    code: "PL",
    dial_code: "+48",
  },
  Portugal: {
    id: 171,
    name: "Portugal",
    isoAlpha2: "PT",
    isoAlpha3: "PRT",
    isoNumeric: 620,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇵🇹",
    code: "PT",
    dial_code: "+351",
  },
  "Puerto Rico": {
    id: 172,
    name: "Puerto Rico",
    isoAlpha2: "PR",
    isoAlpha3: "PRI",
    isoNumeric: 630,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇵🇷",
    code: "PR",
    dial_code: "+1939",
  },
  Qatar: {
    id: 173,
    name: "Qatar",
    isoAlpha2: "QA",
    isoAlpha3: "QAT",
    isoNumeric: 634,
    currency: { code: "QAR", name: "Rial", symbol: "﷼" },
    flag: "🇶🇦",
    code: "QA",
    dial_code: "+974",
  },
  "Republic of the Congo": {
    id: 174,
    name: "Republic of the Congo",
    isoAlpha2: "CG",
    isoAlpha3: "COG",
    isoNumeric: 178,
    currency: { code: "XAF", name: "Franc", symbol: "FCF" },
    flag: "🇹🇫",
  },
  Reunion: {
    id: 175,
    name: "Reunion",
    isoAlpha2: "RE",
    isoAlpha3: "REU",
    isoNumeric: 638,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇷🇪",
    code: "RE",
    dial_code: "+262",
  },
  Romania: {
    id: 176,
    name: "Romania",
    isoAlpha2: "RO",
    isoAlpha3: "ROU",
    isoNumeric: 642,
    currency: { code: "RON", name: "Leu", symbol: "lei" },
    flag: "🇷🇴",
    code: "RO",
    dial_code: "+40",
  },
  Russia: {
    id: 177,
    name: "Russia",
    isoAlpha2: "RU",
    isoAlpha3: "RUS",
    isoNumeric: 643,
    currency: { code: "RUB", name: "Ruble", symbol: "руб" },
    flag: "🇷🇺",
    code: "RU",
    dial_code: "+7",
  },
  Rwanda: {
    id: 178,
    name: "Rwanda",
    isoAlpha2: "RW",
    isoAlpha3: "RWA",
    isoNumeric: 646,
    currency: { code: "RWF", name: "Franc", symbol: "" },
    flag: "🇷🇼",
    code: "RW",
    dial_code: "+250",
  },
  "Saint Helena": {
    id: 179,
    name: "Saint Helena",
    isoAlpha2: "SH",
    isoAlpha3: "SHN",
    isoNumeric: 654,
    currency: { code: "SHP", name: "Pound", symbol: "£" },
    flag: "🇹🇫",
  },
  "Saint Kitts and Nevis": {
    id: 180,
    name: "Saint Kitts and Nevis",
    isoAlpha2: "KN",
    isoAlpha3: "KNA",
    isoNumeric: 659,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇰🇳",
    code: "KN",
    dial_code: "+1869",
  },
  "Saint Lucia": {
    id: 181,
    name: "Saint Lucia",
    isoAlpha2: "LC",
    isoAlpha3: "LCA",
    isoNumeric: 662,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇱🇨",
    code: "LC",
    dial_code: "+1758",
  },
  "Saint Pierre and Miquelon": {
    id: 182,
    name: "Saint Pierre and Miquelon",
    isoAlpha2: "PM",
    isoAlpha3: "SPM",
    isoNumeric: 666,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇵🇲",
    code: "PM",
    dial_code: "+508",
  },
  "Saint Vincent and the Grenadines": {
    id: 183,
    name: "Saint Vincent and the Grenadines",
    isoAlpha2: "VC",
    isoAlpha3: "VCT",
    isoNumeric: 670,
    currency: { code: "XCD", name: "Dollar", symbol: "$" },
    flag: "🇻🇨",
    code: "VC",
    dial_code: "+1784",
  },
  Samoa: {
    id: 184,
    name: "Samoa",
    isoAlpha2: "WS",
    isoAlpha3: "WSM",
    isoNumeric: 882,
    currency: { code: "WST", name: "Tala", symbol: "WS$" },
    flag: "🇼🇸",
    code: "WS",
    dial_code: "+685",
  },
  "San Marino": {
    id: 185,
    name: "San Marino",
    isoAlpha2: "SM",
    isoAlpha3: "SMR",
    isoNumeric: 674,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇸🇲",
    code: "SM",
    dial_code: "+378",
  },
  "Sao Tome and Principe": {
    id: 186,
    name: "Sao Tome and Principe",
    isoAlpha2: "ST",
    isoAlpha3: "STP",
    isoNumeric: 678,
    currency: { code: "STD", name: "Dobra", symbol: "Db" },
    flag: "🇸🇹",
    code: "ST",
    dial_code: "+239",
  },
  "Saudi Arabia": {
    id: 187,
    name: "Saudi Arabia",
    isoAlpha2: "SA",
    isoAlpha3: "SAU",
    isoNumeric: 682,
    currency: { code: "SAR", name: "Rial", symbol: "﷼" },
    flag: "🇸🇦",
    code: "SA",
    dial_code: "+966",
  },
  Senegal: {
    id: 188,
    name: "Senegal",
    isoAlpha2: "SN",
    isoAlpha3: "SEN",
    isoNumeric: 686,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇸🇳",
    code: "SN",
    dial_code: "+221",
  },
  "Serbia and Montenegro": {
    id: 189,
    name: "Serbia and Montenegro",
    isoAlpha2: "CS",
    isoAlpha3: "SCG",
    isoNumeric: 891,
    currency: { code: "RSD", name: "Dinar", symbol: "Дин" },
    flag: "🇹🇫",
  },
  Seychelles: {
    id: 190,
    name: "Seychelles",
    isoAlpha2: "SC",
    isoAlpha3: "SYC",
    isoNumeric: 690,
    currency: { code: "SCR", name: "Rupee", symbol: "₨" },
    flag: "🇸🇨",
    code: "SC",
    dial_code: "+248",
  },
  "Sierra Leone": {
    id: 191,
    name: "Sierra Leone",
    isoAlpha2: "SL",
    isoAlpha3: "SLE",
    isoNumeric: 694,
    currency: { code: "SLL", name: "Leone", symbol: "Le" },
    flag: "🇸🇱",
    code: "SL",
    dial_code: "+232",
  },
  Singapore: {
    id: 192,
    name: "Singapore",
    isoAlpha2: "SG",
    isoAlpha3: "SGP",
    isoNumeric: 702,
    currency: { code: "SGD", name: "Dollar", symbol: "$" },
    flag: "🇸🇬",
    code: "SG",
    dial_code: "+65",
  },
  Slovakia: {
    id: 193,
    name: "Slovakia",
    isoAlpha2: "SK",
    isoAlpha3: "SVK",
    isoNumeric: 703,
    currency: { code: "SKK", name: "Koruna", symbol: "Sk" },
    flag: "🇸🇰",
    code: "SK",
    dial_code: "+421",
  },
  Slovenia: {
    id: 194,
    name: "Slovenia",
    isoAlpha2: "SI",
    isoAlpha3: "SVN",
    isoNumeric: 705,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇸🇮",
    code: "SI",
    dial_code: "+386",
  },
  "Solomon Islands": {
    id: 195,
    name: "Solomon Islands",
    isoAlpha2: "SB",
    isoAlpha3: "SLB",
    isoNumeric: 90,
    currency: { code: "SBD", name: "Dollar", symbol: "$" },
    flag: "🇸🇧",
    code: "SB",
    dial_code: "+677",
  },
  Somalia: {
    id: 196,
    name: "Somalia",
    isoAlpha2: "SO",
    isoAlpha3: "SOM",
    isoNumeric: 706,
    currency: { code: "SOS", name: "Shilling", symbol: "S" },
    flag: "🇸🇴",
    code: "SO",
    dial_code: "+252",
  },
  "South Africa": {
    id: 197,
    name: "South Africa",
    isoAlpha2: "ZA",
    isoAlpha3: "ZAF",
    isoNumeric: 710,
    currency: { code: "ZAR", name: "Rand", symbol: "R" },
    flag: "🇿🇦",
    code: "ZA",
    dial_code: "+27",
  },
  "South Georgia and the South Sandwich Islands": {
    id: 198,
    name: "South Georgia and the South Sandwich Islands",
    isoAlpha2: "GS",
    isoAlpha3: "SGS",
    isoNumeric: 239,
    currency: { code: "GBP", name: "Pound", symbol: "£" },
    flag: "🇬🇸",
    code: "GS",
    dial_code: "+500",
  },
  "South Korea": {
    id: 199,
    name: "South Korea",
    isoAlpha2: "KR",
    isoAlpha3: "KOR",
    isoNumeric: 410,
    currency: { code: "KRW", name: "Won", symbol: "₩" },
    flag: "🇹🇫",
  },
  Spain: {
    id: 200,
    name: "Spain",
    isoAlpha2: "ES",
    isoAlpha3: "ESP",
    isoNumeric: 724,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇪🇸",
    code: "ES",
    dial_code: "+34",
  },
  "Sri Lanka": {
    id: 201,
    name: "Sri Lanka",
    isoAlpha2: "LK",
    isoAlpha3: "LKA",
    isoNumeric: 144,
    currency: { code: "LKR", name: "Rupee", symbol: "₨" },
    flag: "🇱🇰",
    code: "LK",
    dial_code: "+94",
  },
  Sudan: {
    id: 202,
    name: "Sudan",
    isoAlpha2: "SD",
    isoAlpha3: "SDN",
    isoNumeric: 736,
    currency: { code: "SDD", name: "Dinar", symbol: "" },
    flag: "🇸🇩",
    code: "SD",
    dial_code: "+249",
  },
  Suriname: {
    id: 203,
    name: "Suriname",
    isoAlpha2: "SR",
    isoAlpha3: "SUR",
    isoNumeric: 740,
    currency: { code: "SRD", name: "Dollar", symbol: "$" },
    flag: "🇸🇷",
    code: "SR",
    dial_code: "+597",
  },
  "Svalbard and Jan Mayen": {
    id: 204,
    name: "Svalbard and Jan Mayen",
    isoAlpha2: "SJ",
    isoAlpha3: "SJM",
    isoNumeric: 744,
    currency: { code: "NOK", name: "Krone", symbol: "kr" },
    flag: "🇸🇯",
    code: "SJ",
    dial_code: "+47",
  },
  Swaziland: {
    id: 205,
    name: "Swaziland",
    isoAlpha2: "SZ",
    isoAlpha3: "SWZ",
    isoNumeric: 748,
    currency: { code: "SZL", name: "Lilangeni", symbol: "" },
    flag: "🇸🇿",
    code: "SZ",
    dial_code: "+268",
  },
  Sweden: {
    id: 206,
    name: "Sweden",
    isoAlpha2: "SE",
    isoAlpha3: "SWE",
    isoNumeric: 752,
    currency: { code: "SEK", name: "Krona", symbol: "kr" },
    flag: "🇸🇪",
    code: "SE",
    dial_code: "+46",
  },
  Switzerland: {
    id: 207,
    name: "Switzerland",
    isoAlpha2: "CH",
    isoAlpha3: "CHE",
    isoNumeric: 756,
    currency: { code: "CHF", name: "Franc", symbol: "CHF" },
    flag: "🇨🇭",
    code: "CH",
    dial_code: "+41",
  },
  Syria: {
    id: 208,
    name: "Syria",
    isoAlpha2: "SY",
    isoAlpha3: "SYR",
    isoNumeric: 760,
    currency: { code: "SYP", name: "Pound", symbol: "£" },
    flag: "🇹🇫",
  },
  Taiwan: {
    id: 209,
    name: "Taiwan",
    isoAlpha2: "TW",
    isoAlpha3: "TWN",
    isoNumeric: 158,
    currency: { code: "TWD", name: "Dollar", symbol: "NT$" },
    flag: "🇹🇼",
    code: "TW",
    dial_code: "+886",
  },
  Tajikistan: {
    id: 210,
    name: "Tajikistan",
    isoAlpha2: "TJ",
    isoAlpha3: "TJK",
    isoNumeric: 762,
    currency: { code: "TJS", name: "Somoni", symbol: "" },
    flag: "🇹🇯",
    code: "TJ",
    dial_code: "+992",
  },
  Tanzania: {
    id: 211,
    name: "Tanzania",
    isoAlpha2: "TZ",
    isoAlpha3: "TZA",
    isoNumeric: 834,
    currency: { code: "TZS", name: "Shilling", symbol: "" },
    flag: "🇹🇫",
  },
  Thailand: {
    id: 212,
    name: "Thailand",
    isoAlpha2: "TH",
    isoAlpha3: "THA",
    isoNumeric: 764,
    currency: { code: "THB", name: "Baht", symbol: "฿" },
    flag: "🇹🇭",
    code: "TH",
    dial_code: "+66",
  },
  Togo: {
    id: 213,
    name: "Togo",
    isoAlpha2: "TG",
    isoAlpha3: "TGO",
    isoNumeric: 768,
    currency: { code: "XOF", name: "Franc", symbol: "" },
    flag: "🇹🇬",
    code: "TG",
    dial_code: "+228",
  },
  Tokelau: {
    id: 214,
    name: "Tokelau",
    isoAlpha2: "TK",
    isoAlpha3: "TKL",
    isoNumeric: 772,
    currency: { code: "NZD", name: "Dollar", symbol: "$" },
    flag: "🇹🇰",
    code: "TK",
    dial_code: "+690",
  },
  Tonga: {
    id: 215,
    name: "Tonga",
    isoAlpha2: "TO",
    isoAlpha3: "TON",
    isoNumeric: 776,
    currency: { code: "TOP", name: "Paanga", symbol: "T$" },
    flag: "🇹🇴",
    code: "TO",
    dial_code: "+676",
  },
  "Trinidad and Tobago": {
    id: 216,
    name: "Trinidad and Tobago",
    isoAlpha2: "TT",
    isoAlpha3: "TTO",
    isoNumeric: 780,
    currency: { code: "TTD", name: "Dollar", symbol: "TT$" },
    flag: "🇹🇹",
    code: "TT",
    dial_code: "+1868",
  },
  Tunisia: {
    id: 217,
    name: "Tunisia",
    isoAlpha2: "TN",
    isoAlpha3: "TUN",
    isoNumeric: 788,
    currency: { code: "TND", name: "Dinar", symbol: "" },
    flag: "🇹🇳",
    code: "TN",
    dial_code: "+216",
  },
  Turkey: {
    id: 218,
    name: "Turkey",
    isoAlpha2: "TR",
    isoAlpha3: "TUR",
    isoNumeric: 792,
    currency: { code: "TRY", name: "Lira", symbol: "YTL" },
    flag: "🇹🇷",
    code: "TR",
    dial_code: "+90",
  },
  Turkmenistan: {
    id: 219,
    name: "Turkmenistan",
    isoAlpha2: "TM",
    isoAlpha3: "TKM",
    isoNumeric: 795,
    currency: { code: "TMM", name: "Manat", symbol: "m" },
    flag: "🇹🇲",
    code: "TM",
    dial_code: "+993",
  },
  "Turks and Caicos Islands": {
    id: 220,
    name: "Turks and Caicos Islands",
    isoAlpha2: "TC",
    isoAlpha3: "TCA",
    isoNumeric: 796,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇨",
    code: "TC",
    dial_code: "+1649",
  },
  Tuvalu: {
    id: 221,
    name: "Tuvalu",
    isoAlpha2: "TV",
    isoAlpha3: "TUV",
    isoNumeric: 798,
    currency: { code: "AUD", name: "Dollar", symbol: "$" },
    flag: "🇹🇻",
    code: "TV",
    dial_code: "+688",
  },
  "U.S. Virgin Islands": {
    id: 222,
    name: "U.S. Virgin Islands",
    isoAlpha2: "VI",
    isoAlpha3: "VIR",
    isoNumeric: 850,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Uganda: {
    id: 223,
    name: "Uganda",
    isoAlpha2: "UG",
    isoAlpha3: "UGA",
    isoNumeric: 800,
    currency: { code: "UGX", name: "Shilling", symbol: "" },
    flag: "🇺🇬",
    code: "UG",
    dial_code: "+256",
  },
  Ukraine: {
    id: 224,
    name: "Ukraine",
    isoAlpha2: "UA",
    isoAlpha3: "UKR",
    isoNumeric: 804,
    currency: { code: "UAH", name: "Hryvnia", symbol: "₴" },
    flag: "🇺🇦",
    code: "UA",
    dial_code: "+380",
  },
  "United Arab Emirates": {
    id: 225,
    name: "United Arab Emirates",
    isoAlpha2: "AE",
    isoAlpha3: "ARE",
    isoNumeric: 784,
    currency: { code: "AED", name: "Dirham", symbol: "" },
    flag: "🇦🇪",
    code: "AE",
    dial_code: "+971",
  },
  "United Kingdom": {
    id: 226,
    name: "United Kingdom",
    isoAlpha2: "GB",
    isoAlpha3: "GBR",
    isoNumeric: 826,
    currency: { code: "GBP", name: "Pound", symbol: "£" },
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
  },
  "United States": {
    id: 227,
    name: "United States",
    isoAlpha2: "US",
    isoAlpha3: "USA",
    isoNumeric: 840,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇺🇸",
    code: "US",
    dial_code: "+1",
  },
  "United States Minor Outlying Islands": {
    id: 228,
    name: "United States Minor Outlying Islands",
    isoAlpha2: "UM",
    isoAlpha3: "UMI",
    isoNumeric: 581,
    currency: { code: "USD", name: "Dollar", symbol: "$" },
    flag: "🇹🇫",
  },
  Uruguay: {
    id: 229,
    name: "Uruguay",
    isoAlpha2: "UY",
    isoAlpha3: "URY",
    isoNumeric: 858,
    currency: { code: "UYU", name: "Peso", symbol: "$U" },
    flag: "🇺🇾",
    code: "UY",
    dial_code: "+598",
  },
  Uzbekistan: {
    id: 230,
    name: "Uzbekistan",
    isoAlpha2: "UZ",
    isoAlpha3: "UZB",
    isoNumeric: 860,
    currency: { code: "UZS", name: "Som", symbol: "лв" },
    flag: "🇺🇿",
    code: "UZ",
    dial_code: "+998",
  },
  Vanuatu: {
    id: 231,
    name: "Vanuatu",
    isoAlpha2: "VU",
    isoAlpha3: "VUT",
    isoNumeric: 548,
    currency: { code: "VUV", name: "Vatu", symbol: "Vt" },
    flag: "🇻🇺",
    code: "VU",
    dial_code: "+678",
  },
  Vatican: {
    id: 232,
    name: "Vatican",
    isoAlpha2: "VA",
    isoAlpha3: "VAT",
    isoNumeric: 336,
    currency: { code: "EUR", name: "Euro", symbol: "€" },
    flag: "🇹🇫",
  },
  Venezuela: {
    id: 233,
    name: "Venezuela",
    isoAlpha2: "VE",
    isoAlpha3: "VEN",
    isoNumeric: 862,
    currency: { code: "VEF", name: "Bolivar", symbol: "Bs" },
    flag: "🇹🇫",
  },
  Vietnam: {
    id: 234,
    name: "Vietnam",
    isoAlpha2: "VN",
    isoAlpha3: "VNM",
    isoNumeric: 704,
    currency: { code: "VND", name: "Dong", symbol: "₫" },
    flag: "🇻🇳",
    code: "VN",
    dial_code: "+84",
  },
  "Wallis and Futuna": {
    id: 235,
    name: "Wallis and Futuna",
    isoAlpha2: "WF",
    isoAlpha3: "WLF",
    isoNumeric: 876,
    currency: { code: "XPF", name: "Franc", symbol: "" },
    flag: "🇼🇫",
    code: "WF",
    dial_code: "+681",
  },
  "Western Sahara": {
    id: 236,
    name: "Western Sahara",
    isoAlpha2: "EH",
    isoAlpha3: "ESH",
    isoNumeric: 732,
    currency: { code: "MAD", name: "Dirham", symbol: "" },
    flag: "🇹🇫",
  },
  Yemen: {
    id: 237,
    name: "Yemen",
    isoAlpha2: "YE",
    isoAlpha3: "YEM",
    isoNumeric: 887,
    currency: { code: "YER", name: "Rial", symbol: "﷼" },
    flag: "🇾🇪",
    code: "YE",
    dial_code: "+967",
  },
  Zambia: {
    id: 238,
    name: "Zambia",
    isoAlpha2: "ZM",
    isoAlpha3: "ZMB",
    isoNumeric: 894,
    currency: { code: "ZMK", name: "Kwacha", symbol: "ZK" },
    flag: "🇿🇲",
    code: "ZM",
    dial_code: "+260",
  },
  Zimbabwe: {
    id: 239,
    name: "Zimbabwe",
    isoAlpha2: "ZW",
    isoAlpha3: "ZWE",
    isoNumeric: 716,
    currency: { code: "ZWD", name: "Dollar", symbol: "Z$" },
    flag: "🇿🇼",
    code: "ZW",
    dial_code: "+263",
  },
};

export default countryDailCode;
