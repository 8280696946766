/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const StatementDashboard = lazy(() => import("pages/statementParser"));
const StatementEntity = lazy(() => import("pages/statementParser/entity"));
const StatementCategorization = lazy(() => import("pages/statementParser/categorization"));

export const BankStatementParserChildren = [
  {
    path: "statements",
    element: <StatementDashboard />,
  },
  // add route to company details
  {
    path: "entity",
    element: <StatementEntity />,
  },
  {
    path: "categorization/:fileId",
    element: <StatementCategorization />,
  },
];
