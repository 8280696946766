import React, { Suspense } from 'react'
import SplashScreen from 'components/SplashScreen'

interface Children {
  children: React.ReactNode
}

const Loading = ({ children }: Children) => {
  return <Suspense fallback={<SplashScreen />}>{children}</Suspense>
}

export default Loading
