import React from 'react'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)<{
  backgroundcolor?: string
  color?: string
  maxwidth?: number | string
  padding?: string
}>(({ theme, backgroundcolor = '#292C43', color = '#fff', maxwidth = 220, padding = '8px' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundcolor,
    color,
    maxWidth: maxwidth,
    fontSize: theme.typography.pxToRem(4),
    border: '1px solid #dadde9',
    fontWeight: 400,
    padding,
    boxShadow: '0px 4px 8px rgba(16, 24, 64, 0.16)',
    borderRadius: '4px',
    textAlign: 'center',
    lineHeight: '16px',
  },
}))

export default HtmlTooltip
