import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { StepperNav } from "interfaces/atlas";
import { saveTrade } from "services/atlas";
import { NewTradeContext } from "./newTradeContext";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { units } from "pages/atlas/company/helper";
import { NumberFormatBase } from "react-number-format";
import { currencies } from "pages/atlas/person/helpers/columns";
import BottomNav from "./bottomNav";
import { useNavigate } from "react-router-dom";
import Input from "components/Input";
import SelectInput from "components/SelectInput";

interface FormValues {
  buyerPriceAmount: string;
  buyerPriceCurrency: string;
  buyerPriceUnit: string;
}

const initialValues: FormValues = {
  buyerPriceAmount: "",
  buyerPriceCurrency: "",
  buyerPriceUnit: "",
};

const NewTradeBuyer = ({ handleBack }: StepperNav) => {
  const navigate = useNavigate();
  const { trade } = useContext(NewTradeContext);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (trade?.id) {
          await saveTrade(trade.id, {
            buyerPriceAmount: values.buyerPriceAmount || 0,
            buyerPriceCurrency: values.buyerPriceCurrency,
            buyerPriceUnit: values.buyerPriceUnit,
          });
          navigate("/atlas");
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
  });

  const { handleSubmit } = formik;

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Buyer Cost
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Buyer Price</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("buyerPriceAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              id={`buyerPriceAmount`}
              displayType="input"
              name={`buyerPriceAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.buyerPriceAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Buyer Price Currency</FormLabel>
            <SelectInput
              value={formik.values.buyerPriceCurrency}
              labelId={`buyerPriceCurrency`}
              options={currencies}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              name={`buyerPriceCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Buyer Price Unit</FormLabel>
            <SelectInput
              value={formik.values.buyerPriceUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`buyerPriceUnit`}
              options={units}
              name={`buyerPriceUnit`}
            />
          </Grid>
        </Grid>
        <BottomNav handleBack={handleBack} loading={loading} nextText="Submit" />
      </Box>
    </Box>
  );
};

export default NewTradeBuyer;
