export const formatStringDate = (date: string) => {
  if (date === "") return null;
  return new Date(date);
};

export const getValueFromPath = (object: any, path: string) => {
  return path.split(".").reduce((result, key) => result?.[key], object);
};

export const abbreviateLoanType = (loanType: string) => {
  const replacedLoanType = loanType?.replace(/([A-Z]+)/g, " $1") ?? "";
  const formattedLoanType = replacedLoanType.charAt(0).toUpperCase() + replacedLoanType.slice(1);
  const formattedLoanTypeKey = formattedLoanType
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");

  return formattedLoanTypeKey;
};

export const currencySymbol2 = {
  NGN: "₦",
  USD: "$",
  GBP: "£",
  EUR: "EUR",
};

export const chunk = <T>(arr: T[], size: number) => {
  let index = 0;
  const chunked: T[][] = [];

  while (index < arr.length) {
    chunked.push(arr.slice(index, index + size));
    index = index + size;
  }
  return chunked;
};

export const formatFileSize = (sizeInBytes: number): string => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (sizeInBytes < kilobyte) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < megabyte) {
    const sizeInKb = Math.round(sizeInBytes / kilobyte);
    return `${sizeInKb} KB`;
  } else {
    const sizeInMb = (sizeInBytes / megabyte).toFixed(2);
    return `${sizeInMb} MB`;
  }
};

export function scrabble(str: string, length = 3, reversed = false): string {
  if (reversed) {
    return str.slice(0, length) + str.slice(length).replace(/./g, "x");
  }
  return str.slice(0, str.length - length).replace(/./g, "x") + str.slice(-length);
}

export const generateRandomId: () => string = () => {
  const idValues = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  const randomUUID = () => {
    let id = "";
    for (let j = 0; j < 4; j += 1) {
      const randomPartIdx = parseInt((Math.random() * 36).toFixed(0), 10) % 36;
      id += idValues[randomPartIdx];
    }
    return id;
  };

  const stringedId = randomUUID();
  const uniqueId = "ID" + stringedId;

  return uniqueId;
};
