import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import SubMenuLink from "components/SubMenuLink";
import MenuLink from "components/MenuLink";
import { useAppSelector } from "redux/hooks";
// import { useAppSelector, useAppDispatch } from "redux/hooks";
// import { fetchLoggedInUserModules } from "redux/permissionSlice";
// import { useEffect } from "react";
import { AccessLevels } from "API";
import { getNoAccessModules } from "services/navigation";
import { SideNavigation, BottomNav } from "constants/Navigation";
// import SplashScreen from "components/SplashScreen";

interface DrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (item: boolean) => void;
}

export default function TemporaryDrawer({ openDrawer, setOpenDrawer }: DrawerProps) {
  const [showing, setShowing] = React.useState<string>("");
  const modules = useAppSelector((state) => state.permissions.loggedInUserModules);

  const noAccessModules = getNoAccessModules(modules);

  const userModulesAccess = modules?.map((moduleOption) => (moduleOption.access !== AccessLevels.noAccess ? moduleOption.name : null)).filter(Boolean);

  const list = () => (
    <Box sx={{ backgroundColor: "#212437" }}>
      <Box
        sx={{
          width: 250,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        role="presentation"
        onClick={() => {
          setOpenDrawer(false);
        }}
        onKeyDown={() => {
          setOpenDrawer(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-left",
            alignItems: "center",
            width: "100%",
            pl: "20px",
            pb: "4.875rem",
          }}
        >
          <Box sx={{ pt: 3, pb: 3, maxWidth: "7.8rem", maxHeight: "2.7rem" }}>
            <img alt="Fluna" src="/static/logo/fluna-logo.png" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
          </Box>
        </Box>

        {SideNavigation.filter((nav) => userModulesAccess?.includes(nav.module)).map((module) => (
          <Typography
            key={module.name}
            sx={{
              color: "#171721",
              cursor: "pointer",
              padding: "15px 0px",
              fontSize: "0.8125rem",
              fontWeight: "700",
            }}
          >
            <SubMenuLink key={module.name} module={module} opened={true} setShowing={setShowing} showing={showing} noAccessModules={noAccessModules} />
          </Typography>
        ))}
      </Box>

      <Box
        sx={{ backgroundColor: "#212437", border: "none", mt: "-5px" }}
        onClick={() => {
          setOpenDrawer(false);
        }}
        onKeyDown={() => {
          setOpenDrawer(false);
        }}
      >
        {BottomNav.filter((nav) => userModulesAccess?.includes(nav.module)).map((module) => (
          <>
            <Typography
              key={module.name}
              sx={{
                color: "#171721",
                cursor: "pointer",
                padding: "15px 0px",
                fontSize: "0.8125rem",
                fontWeight: "700",
              }}
            >
              <MenuLink key={module.name} module={module} opened={true} setShowing={setShowing} noAccessModules={noAccessModules} />
            </Typography>
          </>
        ))}
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        {/* {loading ? <SplashScreen /> : list()} */}
        {list()}
      </Drawer>
    </React.Fragment>
  );
}
