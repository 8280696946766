/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const LeadManagement = lazy(() => import("pages/salesTracker/leadManagement"));
const SupplierQuotes = lazy(() => import("pages/salesTracker/supplierQuotes"));
const BuyerOffers = lazy(() => import("pages/salesTracker/buyerOffers"));


export const SalesTrackerChildren = [
  {
    path: "lead-management",
    element: <LeadManagement />,
  },
  {
    path: "supplier",
    element: <SupplierQuotes />,
  },
  {
    path: "buyer",
    element: <BuyerOffers />,
  }
];
