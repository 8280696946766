
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IProduct } from "interfaces/atlas";
import { makeAPI } from "hooks/useAtlasAxios";
import { listPrices, listPriceSources, listProducts } from "atlasGraphql/atlas-queries";
import type { IPrices, ISources } from "interfaces/price-tracker";
import { fetchFromTable } from "helper/dynamodb";
import { ErrorHandler } from "helper/Handlers";



const currencyTable = process.env.REACT_APP_CURRENCY_TABLE as string;

interface PriceTrackerState {
  prices: IPrices[],
  products: any[];
  currenciesRates: any[];
  priceSources: ISources[],
  loadingPrices: boolean;
  loadingProducts: boolean;
  loadingPriceSources: boolean;
  loadingcurrenciesRates: boolean;
}
interface IFetchProducts {
  listProducts: IProduct[];
}
interface IFetchPrices {
  listPrices: IPrices[];
}
interface IFetchPricesSources {
  listPriceSources: ISources[];
}

const initialState: PriceTrackerState = {
  prices: [],
  products: [],
  priceSources: [],
  currenciesRates: [],
  loadingPrices: false,
  loadingProducts: false,
  loadingPriceSources: false,
  loadingcurrenciesRates: false,
};
export const fetchProducts = createAsyncThunk("atlas/fetchProducts", async () => {
  try {
    const products = await makeAPI<any, IFetchProducts>({
      query: listProducts,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return products.data.listProducts;
  } catch (err) {
    console.log(err);
    return [];
  }
});
export const fetchPrices = createAsyncThunk("atlas/fetchPrices", async () => {
  try {
    const prices = await makeAPI<any, IFetchPrices>({
      query: listPrices,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return prices.data.listPrices;
  } catch (err) {
    console.log(err);
    return [];
  }
});
export const fetchPriceSources = createAsyncThunk("atlas/fetchPriceSources", async () => {
  try {
    const priceSources = await makeAPI<any, IFetchPricesSources>({
      query: listPriceSources,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return priceSources.data.listPriceSources;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchAllCurrenciesRates = createAsyncThunk("treasury/fetchAllCurrencies", async () => {
  try {
    const datetime = new Date()
    const todaysDate = datetime.toISOString().slice(0, 10)
    const params = {
      ProjectionExpression: "id, createdAt, updatedAt, #Base, rates",
      ExpressionAttributeNames: { "#Base": "base", '#cn': 'dataCreated', },
      FilterExpression: '#cn <= :data',
      ExpressionAttributeValues: {
        ':data': todaysDate,
      },
      TableName: currencyTable,
    };
    const currenciesRates = (await fetchFromTable(params)) as any;
    const currenciesRatesSorted = currenciesRates?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    const finalCurrenciesRate = [
      currenciesRatesSorted[0] || {}
    ]
    return finalCurrenciesRate;

  } catch (err: any) {
    console.log("currencyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch currency data" });
    return [] as any;
  }
});

const PriceTrackerSlice = createSlice({
  name: "priceTracker",
  initialState,
  reducers: {
    setPrices: (
      state,
      action: PayloadAction<{
        data: IPrices[];
      }>,
    ) => {
      state.prices = action.payload.data
    },
    setProducts: (
      state,
      action: PayloadAction<{
        data: IProduct[];
      }>,
    ) => {
      state.products = action.payload.data;
    },
    setPriceSources: (
      state,
      action: PayloadAction<{
        data: ISources[];
      }>,
    ) => {
      state.priceSources = action.payload.data
    },
    setCurrenciesRates: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.currenciesRates = action.payload.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.loadingPrices = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
      state.loadingPrices = false;
    });
    builder.addCase(fetchPrices.rejected, (state, action) => {
      state.prices = [...state.prices, ...(action.payload as IPrices[])];
      state.loadingPrices = false;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.loadingProducts = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loadingProducts = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products = [...state.products, ...(action.payload as IProduct[])];
      state.loadingProducts = false;
    });
    builder.addCase(fetchPriceSources.pending, (state) => {
      state.loadingPriceSources = true;
    });
    builder.addCase(fetchPriceSources.fulfilled, (state, action) => {
      state.priceSources = action.payload;
      state.loadingPriceSources = false;
    });
    builder.addCase(fetchPriceSources.rejected, (state, action) => {
      state.priceSources = [...state.priceSources, ...(action.payload as ISources[])];
      state.loadingPriceSources = false;
    });
    builder.addCase(fetchAllCurrenciesRates.pending, (state) => {
      state.loadingcurrenciesRates = true;
    });
    builder.addCase(fetchAllCurrenciesRates.fulfilled, (state, action) => {
      state.currenciesRates = action.payload;
      state.loadingcurrenciesRates = false;
    });
    builder.addCase(fetchAllCurrenciesRates.rejected, (state, action) => {
      state.currenciesRates = [...state.currenciesRates, ...(action.payload as any)];
      state.loadingcurrenciesRates = false;
    });


  },
});

export const { setPrices, setProducts, setPriceSources, setCurrenciesRates } = PriceTrackerSlice.actions;

export default PriceTrackerSlice.reducer;
