/* eslint-disable @typescript-eslint/promise-function-async */

import React, { lazy } from "react";

const PriceTracker = lazy(() => import("pages/priceTracker"));


export const PriceTrackerChildren = [
  {
    path: "price-tracker",
    element: <PriceTracker />,
  },
];
