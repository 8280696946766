import { Box, SelectChangeEvent, Typography } from "@mui/material";
import SelectInput from "components/SelectInput";
import React, { useEffect, useState } from "react";
import { entryTypes } from "../helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPerson, retreiveEntities } from "services/atlas";
import Input from "components/Input";
import CountryDropdown from "components/CountryDropdown";
import countryList from "data/countryAndStates";
import CompareValues from "helper/CompareValues";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { blockUpdatesChecker } from "services/navigation";
import { FlunaModules } from "API";
import FlunaButton from "components/FlunaButton";

// import AutoCompleteWithSearchBar from "components/AutoCompleteWithSearchBar";

interface NewContactProps {
  entityType: string;
  handleInputChange: (e: SelectChangeEvent) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  country: string;
  entity: string;
  keyContact: string;
}

const NewContact = ({ entityType, handleInputChange }: NewContactProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<Array<{ name: string; value: string }>>([]);
  const userModules = useAppSelector((state) => state.permissions.loggedInUserModules);
  const blockAction = blockUpdatesChecker(userModules, FlunaModules.atlas);

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    country: "",
    entity: "",
    keyContact: "no",
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { entity } = values;
      const entityId = entities.find((val) => val.name === entity)?.value;

      try {
        setLoading(true);
        await addPerson({
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          country: values.country,
          entity: {
            connect: {
              id: entityId,
            },
          },
        });
        navigate("/atlas");
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string().required("Email is required").email("Email is not valid"),
      country: Yup.string().required("Country is required"),
      entity: Yup.string().required("Entity is required"),
    }),
  });

  const { handleSubmit, touched, errors, handleBlur, handleChange } = formik;

  useEffect(() => {
    (async () => {
      const response = await retreiveEntities();
      setEntities(
        (response || []).map((val) => ({
          name: val.name,
          value: val.id,
        })),
      );
    })();
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "40rem",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: "2rem",
        }}
      >
        New Entry
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <SelectInput
          name="entryType"
          id="entryType"
          options={entryTypes}
          labelId="entryType"
          value={entityType}
          onChange={handleInputChange}
          placeholder="Select Entry Type"
          title={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Entry Type
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />

        <CountryDropdown
          name="entity"
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Entity
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
          options={entities.sort(CompareValues("name", "asc"))}
          value={formik.values.entity}
          errors={formik.errors}
          touched={formik.touched}
          noImage={true}
          placeholder="Entity"
          setFieldValue={formik.setFieldValue}
          hasMargin={true}
          sx={{
            mt: 1,
            "& .MuiFormControl-root, & .MuiFilledInput-root": {
              //   maxWidth: "7rem",
              borderRadius: "7px",
            },
            "& .MuiFormControl-root": {
              border: "1px solid rgba(230, 122, 14, 0.5)",
            },
            "& .MuiFilledInput-root": {
              border: "none",
            },
            "& .MuiFilledInput-input": {
              padding: "16px 8px",
            },
          }}
        />
        <SelectInput
          name="keyContact"
          id="keyContact"
          sx={{
            mt: 3,
          }}
          onChange={formik.handleChange}
          options={[
            {
              name: "Yes",
              value: "yes",
            },
            {
              name: "No",
              value: "no",
            },
          ]}
          labelId="keyContact"
          value={formik?.values?.keyContact}
          placeholder="Select Option"
          error={formik.errors}
          touched={formik.touched}
          title={
            <Box>
              <Typography>Key Contact?</Typography>
            </Box>
          }
        />
        <Input
          type="text"
          id="firstName"
          error={errors}
          margin="normal"
          name="firstName"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.firstName}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Contact First Name
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Input
          type="text"
          id="lastName"
          error={errors}
          margin="normal"
          name="lastName"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.lastName}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Contact Last Name
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Input
          type="email"
          id="email"
          error={errors}
          margin="normal"
          name="email"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.email}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Contact Email Address
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Input
          type="text"
          id="phoneNumber"
          error={errors}
          margin="normal"
          name="phoneNumber"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.phoneNumber}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Contact Phone Number
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <CountryDropdown
          name="country"
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Contact Nationality
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
          options={countryList
            .map((country) => {
              return { name: country.country, value: country.country };
            })
            .sort(CompareValues("name", "asc"))}
          value={formik.values.country}
          errors={formik.errors}
          touched={formik.touched}
          noImage={true}
          placeholder="Country"
          setFieldValue={formik.setFieldValue}
          hasMargin={true}
          sx={{
            mt: 1,
            "& .MuiFormControl-root, & .MuiFilledInput-root": {
              //   maxWidth: "7rem",
              borderRadius: "7px",
            },
            "& .MuiFormControl-root": {
              border: "1px solid rgba(230, 122, 14, 0.5)",
            },
            "& .MuiFilledInput-root": {
              border: "none",
            },
            "& .MuiFilledInput-input": {
              padding: "16px 8px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "3rem",
          }}
        >
          <div />
          <FlunaButton
            type="submit"
            blockAction={blockAction}
            sx={{
              padding: ".5rem 3rem",
              borderRadius: ".5rem",
            }}
            disabled={loading}
            loading={loading}
            text="Save"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewContact;
