/* eslint-disable @typescript-eslint/promise-function-async */
import NewCompany from "pages/atlas/new";
import React, { lazy } from "react";

const AtlasHome = lazy(() => import("pages/atlas"));
const AtlasCompanyInstance = lazy(() => import("pages/atlas/company/instance"));
const CompanyInteractions = lazy(() => import("pages/atlas/company/instanceComponents/interactions/index"));

export const AtlasChildren = [
  {
    path: "atlas",
    element: <AtlasHome />,
  },
  {
    path: "atlas/company/:companyId",
    element: <AtlasCompanyInstance />,
  },
  {
    path: "atlas/company/:companyId/interactions",
    element: <CompanyInteractions />,
  },
  {
    path: "atlas/company/new",
    element: <NewCompany />,
  },
];
