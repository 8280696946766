/* eslint-disable @typescript-eslint/promise-function-async */

import React, { lazy } from "react";

const ApplicationDashboard = lazy(() => import("pages/lending/management"));
const PaymentsTrackerDashboard = lazy(() => import("pages/lending/tracker"));
const LoanDetails = lazy(() => import("pages/lending/management/loan-details"));
const LoanRenewalDetails = lazy(() => import("pages/lending/management/loanRenewal-details"));

export const LendingChildren = [
  {
    path: "loan-management",
    element: <ApplicationDashboard />,
  },
  {
    path: "loan-management/:companyId",
    element: <ApplicationDashboard />,
  },
  {
    path: "loan/:loanType/:loanId",
    element: <LoanDetails />,
  },
  {
    path: "loan-renewal/:loanType/:loanId",
    element: <LoanRenewalDetails />,
  },
  {
    path: "loan/:loanType/:loanId/:tab",
    element: <LoanDetails />,
  },
  {
    path: "loan-renewal/:loanType/:loanId/:tab",
    element: <LoanRenewalDetails />,
  },
  {
    path: "loan-payments",
    element: <PaymentsTrackerDashboard />,
  },
];
