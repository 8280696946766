import { type Investor, type InvestorData } from "interfaces/ipReconciliation";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface ReconciliationState {
  investors: Investor[];
  selectedInvestor: InvestorData;
  investorsBalance: Record<string, any>;
  loadingInvestors: boolean;
  loadingBalances: boolean;
}

const initialState: ReconciliationState = {
  investors: [],
  selectedInvestor: {},
  investorsBalance: {},
  loadingInvestors: false,
  loadingBalances: false,
};

const reconciliationSlice = createSlice({
  name: "reconciliation",
  initialState,
  reducers: {
    setInvestorsData: (
      state,
      action: PayloadAction<{
        data: Investor[];
      }>,
    ) => {
      state.investors = action.payload.data;
    },
    setSelectedInvestor: (
      state,
      action: PayloadAction<{
        data: InvestorData;
      }>,
    ) => {
      state.selectedInvestor = action.payload.data;
    },
    setLoadingInvestorData: (
      state,
      action: PayloadAction<{
        data: boolean;
      }>,
    ) => {
      state.loadingInvestors = action.payload.data;
    },
    setLoadingBalancesData: (
      state,
      action: PayloadAction<{
        data: boolean;
      }>,
    ) => {
      state.loadingBalances = action.payload.data;
    },
    setInvestorsBalance: (
      state,
      action: PayloadAction<{
        data: Record<string, any>;
      }>,
    ) => {
      state.investorsBalance = action.payload.data;
    },
  },
});

export const { setInvestorsData, setLoadingInvestorData, setSelectedInvestor, setInvestorsBalance, setLoadingBalancesData } = reconciliationSlice.actions;

export default reconciliationSlice.reducer;
