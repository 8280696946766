import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import {
  DynamoDBDocumentClient,
  GetCommand,
  ScanCommand,
  PutCommand,
  QueryCommand,
  UpdateCommand,
  DeleteCommand,
  BatchGetCommand,
  BatchWriteCommand,
} from "@aws-sdk/lib-dynamodb";
// import { v4 as uuidv4 } from 'uuid'

const REGION = process.env.REACT_APP_REGION as string;

const CREDENTIALS = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY as string,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
};

// Create the DynamoDB service client module using ES6 syntax.
const ddbClient = new DynamoDBClient({
  region: REGION,
  credentials: CREDENTIALS,
});

const marshallOptions = {
  // Whether to automatically convert empty strings, blobs, and sets to `null`.
  convertEmptyValues: false, // false, by default.
  // Whether to remove undefined values while marshalling.
  removeUndefinedValues: true, // false, by default.
  // Whether to convert typeof object to map attribute.
  convertClassInstanceToMap: false, // false, by default.
};

const unmarshallOptions = {
  // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
  wrapNumbers: false, // false, by default.
};

// Create the DynamoDB document client.
const ddbDocClient = DynamoDBDocumentClient.from(ddbClient, {
  marshallOptions,
  unmarshallOptions,
});

export const putInTable = async (TableName: string, item: any) => {
  try {
    const params = {
      TableName,
      Item: item,
    };

    await ddbDocClient.send(new PutCommand(params));
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const updateTableItem = async (params) => {
  try {
    const data = await ddbDocClient.send(new UpdateCommand(params));
    return data.Attributes;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const getFromTable = async (TableName: string, id: string) => {
  try {
    const params = {
      TableName,
      Key: {
        id,
      },
    };
    const data = await ddbDocClient.send(new GetCommand(params));
    return data.Item;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const putMultipleInTable = async (TableName: string, items: Array<Record<string, any>>) => {
  try {
    const putRequests = items.map((item) => ({
      PutRequest: {
        Item: item,
      },
    }));

    const params = {
      RequestItems: {
        [TableName]: putRequests,
      },
    };

    const data = await ddbDocClient.send(new BatchWriteCommand(params));

    console.log("batchWriteSucess", data);
    // return "uploaded successfully";
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getMultipleFromTable = async (TableName: string, Keys: Array<{ id: string }>) => {
  try {
    const params = {
      RequestItems: {
        [TableName]: {
          Keys,
        },
      },
    };
    const data = await ddbDocClient.send(new BatchGetCommand(params));
    const responses = data?.Responses;

    if (responses) {
      const items = responses[TableName];
      return items;
    } else {
      return [];
    }
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const simpleFetchFromTable = async (TABLE: string) => {
  try {
    const params = { TableName: TABLE };
    const data = await ddbDocClient.send(new ScanCommand(params));
    return data.Items;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const fetchFromTable = async (params: any) => {
  try {
    const data = await ddbDocClient.send(new ScanCommand(params));
    return data.Items;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const queryTable = async (params: any) => {
  try {
    const data = await ddbDocClient.send(new QueryCommand(params));
    return (data.Items as any[]) ?? [];
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const deleteFromTable = async (TableName: string, id: string) => {
  try {
    const params = {
      TableName,
      Key: {
        id,
      },
    };
    const data = await ddbDocClient.send(new DeleteCommand(params));
    return data;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const deleteMultipleInTable = async (
  TableName: string,
  deleteRequests: Array<{
    DeleteRequest: {
      Key: { id: string };
    };
  }>,
) => {
  try {
    const params = {
      RequestItems: {
        [TableName]: deleteRequests,
      },
    };

    const data = await ddbDocClient.send(new BatchWriteCommand(params));

    console.log("batchDeleteSucess", data);
    // return "uploaded successfully";
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchCompanyNamesCall = async () => {
  const params = {
    ProjectionExpression: "id, legalName",
    TableName: (process.env.COMPANY_TABLE || process.env.REACT_APP_COMPANY_TABLE) as string,
  };

  console.log("fetching called");
  try {
    const data = await ddbDocClient.send(new ScanCommand(params));
    return data.Items;
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};
