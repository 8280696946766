/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const Permissions = lazy(() => import("pages/permissions/index"));
// const RequestAccess = lazy(() => import("pages/permissions/RequestAccess"));

export const SettingsChildren = [
  {
    path: "permissions",
    element: <Permissions />,
  },
  // {
  //   path: "request-access",
  //   element: <RequestAccess />,
  // },
];
