/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, Typography } from "@mui/material";
import { IEntity, StepperNav } from "interfaces/atlas";
import { useFormik } from "formik";
import { retreiveProducts, saveEntity } from "services/atlas";
import { NewEntityContext } from "./newEntityContext";
import Input from "components/Input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SelectInput from "components/SelectInput";
import * as Yup from "yup";
import BottomNav from "./bottomNav";

interface FormValues {
  entityType: string;
  website: string;
  tinNumber: string;
  hubspotId: string;
  entityLogo: string;
  productExperience: string;
  incorporationDate: string;
}

const entityTypes = [
  {
    name: "Supplier",
    value: "Supplier",
  },
  {
    name: "Buyer",
    value: "Buyer",
  },
  {
    name: "Startup",
    value: "Startup",
  },
  {
    name: "Freight Forwarder",
    value: "FreightForwarder",
  },
  {
    name: "Trade Association",
    value: "TradeAssociation",
  },
  {
    name: "Local Agency",
    value: "LocalAgency",
  },
  {
    name: "National Agency",
    value: "NationalAgency",
  },
  {
    name: "International Agency",
    value: "InternationalAgency",
  },
];

const NewEntityBackground = ({ handleBack, handleNext }: StepperNav) => {
  const { entity, handleEntityUpdate } = useContext(NewEntityContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<{ name: string; value: string }>>([]);

  const initialValues: FormValues = {
    entityType: entity?.entityType || "",
    website: entity?.website || "",
    tinNumber: entity?.tinNumber || "",
    hubspotId: entity?.hubspotId || "",
    entityLogo: "",
    productExperience: "",
    incorporationDate: entity?.dateIncorporated || "",
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const savedEntity = await saveEntity(entity?.id || "", {
          website: values.website,
          tinNumber: values.tinNumber,
          entityType: values.entityType,
          hubspotId: values.hubspotId,
          dateIncorporated: values.incorporationDate,
          supplierProductExportExperience: {
            connect: {
              id: values.productExperience,
            },
          },
        });
        handleEntityUpdate(savedEntity?.data.updateEntity as IEntity);
        handleNext();
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
    validationSchema: Yup.object({
      // website: Yup.string().required("Entity website is required"),
      // tinNumber: Yup.string().required("TIN Number is required"),
      // hubspotId: Yup.string().required("Hubspot ID is required"),
      entityType: Yup.string().required("Entity type is required"),
      // incorporationDate: Yup.string().required("Product Experience is required"),
    }),
  });

  console.log(products);

  const { handleBlur, handleChange, handleSubmit, errors, touched } = formik;

  useEffect(() => {
    (async () => {
      const products = await retreiveProducts();
      const allProducts = (products || []).map((product) => ({
        name: product.name,
        value: product.id,
      }));
      setProducts(allProducts);
    })();
  }, []);

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Background
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <FormControl
          fullWidth
          sx={{
            mb: 2,
          }}
        >
          <Input
            type="text"
            id="entityName"
            margin="normal"
            name="entityName"
            onBlur={handleBlur}
            fullWidth={false}
            disabled={true}
            value={entity?.name}
            label={
              <Box>
                <Typography
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Entity Name
                  <Box
                    component="span"
                    sx={{
                      color: "#FF1300",
                    }}
                  >
                    *
                  </Box>
                </Typography>
              </Box>
            }
          />
        </FormControl>
        {/* <FormControl fullWidth> */}
        <SelectInput
          name="entityType"
          id="entityType"
          onChange={formik.handleChange}
          options={entityTypes}
          labelId="entityType"
          value={formik?.values?.entityType}
          placeholder="Select Options"
          error={formik.errors}
          touched={formik.touched}
          setFieldTouched={formik.setFieldTouched}
          title={
            <Box>
              <Typography>
                Entity Type{" "}
                <Box
                  component="span"
                  sx={{
                    color: "red",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        {/* </FormControl> */}
        <FormControl
          fullWidth
          sx={{
            mb: 2,
          }}
        >
          <Input
            type="text"
            id="website"
            error={errors}
            margin="normal"
            name="website"
            onBlur={handleBlur}
            required={false}
            fullWidth={false}
            touched={touched}
            value={formik.values.website}
            onChange={handleChange}
            label={
              <Box>
                <Typography
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Entity Website
                </Typography>
              </Box>
            }
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            mb: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={formik.values.incorporationDate ? dayjs(formik.values.incorporationDate) : null}
              format="DD/MM/YYYY"
              label={
                <Box
                  sx={{
                    color: "#8083A3",
                  }}
                >
                  Incorportion Date
                </Box>
              }
              sx={{
                width: "100%",
                border: "1px solid #ddd",
                borderRadius: "0.5rem",
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  borderRadius: "0.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  color: "#8083A3",
                  padding: "10px",
                  paddingY: "18px",
                  borderRadius: "0.5rem",
                },
              }}
              onChange={(e) => {
                formik.setFieldValue(`incorporationDate`, e?.toISOString());
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            mb: 2,
          }}
        >
          <Input
            type="text"
            id="tinNumber"
            error={errors}
            margin="normal"
            name="tinNumber"
            onBlur={handleBlur}
            required={false}
            fullWidth={false}
            touched={touched}
            value={formik.values.tinNumber}
            onChange={handleChange}
            label={
              <Box>
                <Typography
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  TIN Number
                </Typography>
              </Box>
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <SelectInput
            name="productExperience"
            id="productExperience"
            onChange={formik.handleChange}
            options={products}
            labelId="productExperience"
            value={formik?.values?.productExperience}
            placeholder="Select Options"
            error={formik.errors}
            touched={formik.touched}
            title={
              <Box>
                <Typography>Product Experience</Typography>
              </Box>
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <Input
            type="text"
            id="hubspotId"
            error={errors}
            margin="normal"
            name="hubspotId"
            onBlur={handleBlur}
            required={false}
            fullWidth={false}
            touched={touched}
            value={formik.values.hubspotId}
            onChange={handleChange}
            label={
              <Box>
                <Typography
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Hubspot ID
                </Typography>
              </Box>
            }
          />
        </FormControl>
        <BottomNav loading={loading} />
      </Box>
      {/* <FileUpload2
        open={toggleUpload}
        company={{ id: entityId!, legalName: entityName! }}
        handleClose={() => {
          setToggleUpload(false);
        }}
        documentName="Credit Risk Assessment Framework"
        setCurrentUploadState={setCurrentUploadState}
        id="proof-upload"
        mimeTypes={["image/*", "application/*"]}
      /> */}
    </Box>
  );
};

export default NewEntityBackground;
