/* eslint-disable @typescript-eslint/no-unused-vars */

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import React from "react";

interface IAutoCompleteWithSearchBar {
  id?: string;
  open: boolean;
  disabled?: boolean;
  optionLabel: string;
  options: any[];
  handleChange?: any;
  handleInputChange?: any;
  noOptionsText: string;
  handleClose: () => void;
  searchPlaceHolder: string;
  anchorEl: HTMLElement | null;
  getOptionLabel: (option: any) => string;
  renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => React.ReactElement | React.ReactNode;
}

interface PopperComponentProps {
  open: boolean;
  anchorEl?: any;
  disablePortal?: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    margin: 0,
    fontSize: 13,
    color: "inherit",
    boxShadow: "none",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    backgroundColor: "#fff",
    [`& .${autocompleteClasses.option}`]: {
      padding: 8,
      minHeight: "auto",
      alignItems: "flex-start",
      borderBottom: "1px solid #eaecef",
      [`&.${autocompleteClasses.focused}`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // These properties don't exist on a div element so they are abstracted
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  width: 226,
  fontSize: 13,
  borderRadius: 6,
  color: "#24292e",
  backgroundColor: "#fff",
  zIndex: theme.zIndex.modal,
  border: "1px solid #e1e4e8",
  boxShadow: "0 8px 24px rgba(149, 157, 165, 0.2)",
}));

const StyledInput = styled(InputBase)(() => ({
  padding: 10,
  width: "100%",
  borderBottom: "1px solid #eaecef",
  "& input": {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: "#F0F2F4",
    padding: "8px 35px 8px 8px",
  },
}));

const AutoCompleteWithSearchBar = ({
  id,
  open,
  options,
  anchorEl,
  handleClose,
  renderOption,
  noOptionsText,
  getOptionLabel,
  handleChange,
  handleInputChange,
  searchPlaceHolder,
  disabled,
}: IAutoCompleteWithSearchBar) => {
  return (
    <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <Autocomplete
            id={id}
            open={open}
            autoHighlight
            disabled={disabled}
            disableCloseOnSelect
            onChange={handleChange}
            onInputChange={handleInputChange}
            clearOnBlur={false}
            noOptionsText={noOptionsText}
            options={Object.values(options)}
            PopperComponent={PopperComponent}
            renderInput={(params) => (
              <Box sx={{ position: "relative" }}>
                <StyledInput {...params} ref={params.InputProps.ref} inputProps={params.inputProps} placeholder={searchPlaceHolder} />
                <SearchIcon
                  sx={{
                    top: "50%",
                    right: "3%",
                    color: "#8083A3",
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </Box>
            )}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderOption={(props, option) => renderOption(props, option)}
          />
        </Box>
      </ClickAwayListener>
    </StyledPopper>
  );
};

export default AutoCompleteWithSearchBar;
