import React from "react";
import { useAppSelector } from "redux/hooks";
import { Navigate, useLocation } from "react-router-dom";

const AuthGuard = (props: { children: React.ReactElement }): JSX.Element => {
  const { children } = props;
  const auth = useAppSelector((state) => state.auth);
  const location = useLocation();
  const pathname = location?.pathname;

  if (!auth.isAuthenticated) {
    const url = pathname && !pathname.includes("/auth/login") ? `/auth/login?redirectTo=${pathname}` : "/auth/login";
    return <Navigate to={url} />;
  }

  return children;
};

export default AuthGuard;
