export const getShipment = /* GraphQL */ `
  query getShipment($where: ShipmentExtendedWhereUniqueInput!) {
    getShipment(where: $where) {
      id
      shipmentNumber
      additionalShippingDocuments
      status
      unitType
      numberOfUnitsPerContainers
      targetNetWeightPerUnitContainer
      targetGrossWeightPerUnitContainer
      freightForwarder
      freightType
      shippingLine
      bookingNumber
      loadingPort
      destinationPort
      blNumber
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      targetGoodsReadyToStuffDate
      targetStuffingDate
      targetQualityInspectionDate
      expectedGateInDeadline
      expectedVesselSailDate
      expectedArrivalAtDestinationPort
      expectedTransitTime
      actualGoodsReadyToStuffDate
      actualStuffingDate
      actualQualityInspectionDate
      actualGateInDeadline
      actualVesselSailDate
      actualArrivalAtDestinationPort
      actualTransitTime
      mainBodyInfoBlDetails
      cosigneeInfoBlDetails
      shipperInfoBlDetails
      secondNotifyInfoBlDetails
      orderId
      order {
        id
        orderNumber
        entityId
        orderNumber
        status
        product
        owner
        buyerId
        supplierId
        incoterms
        weight
        requiredShippingDocuments
        additionalOrderDocuments
        pricePerMt
        currency
        currencyFrom
        currencyTo
        rate
        total
        shipByDeadline
        paymentTerms
        shipmentSchedule
        containerDressing
        productPackaging
        notes
      }
      containers {
        id
        shipmentId
        containerDressing
        containerType
        numberOfContainers
        locationOfContainerStuffing
        targetNetWeightInMtPerContainer
        targetTotalNetWeightInMtPerContainer
        unitType
        containerNumber
        noOfUnits
        netWeightPerUnitKg
        totalNetWeightInMt
        grossWeightPerUnitKg
        totalGrossWeightInMt
        createdOn
        modifiedOn
      }
      documents {
        id
        entityId
        personId
        tradeId
        orderId
        shipmentId
        key
        name
        version
        finalVersion
        archived
        bucketName
        createdOn
        documentTemplateId
        entityId
        expiryDate
        gcn
        issueDate
        modifiedOn
        records
        softDelete
        documentTemplate {
          id
          name
          category
        }
      }
      createdOn
      modifiedOn
    }
  }
`;

export const listEntities = /* GraphQL */ `
  query listEntities($where: EntityWhereInput, $orderBy: [EntityOrderByInput!], $skip: Int, $take: Int) {
    listEntities(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      entityType
      email
      entityType
      phoneNumber
      exportedBefore
      supplierProductExportExperience {
        id
        name
      }
      supplierSourcingCountries
      destinationCountries
      source
      country
      city
      website
    }
  }
`;

export const countOrders = /* GraphQL */ `
  query countOrders($where: OrderWhereInput, $orderBy: [OrderOrderByInput!], $skip: Int, $take: Int) {
    countOrders(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
  }
`;

export const listOrders = /* GraphQL */ `
  query listOrders($where: OrderWhereInput, $orderBy: [OrderOrderByInput!], $skip: Int, $take: Int) {
    listOrders(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      orderNumber
      entityId
      status
      product
      owner
      buyerId
      supplierId
      incoterms
      weight
      unitOfMeasurement
      requiredShippingDocuments
      additionalOrderDocuments
      pricePerMt
      currency
      currencyFrom
      currencyTo
      rate
      total
      shipByDeadline
      paymentTerms
      shipmentSchedule
      containerDressing
      productPackaging
      notes
      origin
      destination
      clientReferenceNumber
      hsCode
      importPermitNumber
      letterOfCreditNumber
      createdOn
      modifiedOn
    }
  }
`;

export const getOrder = /* GraphQL */ `
  query getOrder($where: OrderExtendedWhereUniqueInput!) {
    getOrder(where: $where) {
      id
      entityId
      status
      orderNumber
      product
      owner
      buyerId
      supplierId
      incoterms
      weight
      unitOfMeasurement
      requiredShippingDocuments
      additionalOrderDocuments
      pricePerMt
      currency
      currencyFrom
      currencyTo
      rate
      origin
      destination
      clientReferenceNumber
      hsCode
      importPermitNumber
      letterOfCreditNumber
      total
      shipByDeadline
      paymentTerms
      shipmentSchedule
      containerDressing
      productPackaging
      notes
      shipments {
        id
        orderId
        status
        shipmentNumber
        additionalShippingDocuments
        freightForwarder
        freightType
        shippingLine
        bookingNumber
        loadingPort
        destinationPort
        blNumber
        containerDressing
        containerType
        numberOfContainers
        locationOfContainerStuffing
        targetNetWeightInMtPerContainer
        targetTotalNetWeightInMtPerContainer
        targetGoodsReadyToStuffDate
        targetStuffingDate
        targetQualityInspectionDate
        expectedGateInDeadline
        expectedVesselSailDate
        expectedArrivalAtDestinationPort
        expectedTransitTime
        actualGoodsReadyToStuffDate
        actualStuffingDate
        actualQualityInspectionDate
        actualGateInDeadline
        actualVesselSailDate
        actualArrivalAtDestinationPort
        actualTransitTime
        mainBodyInfoBlDetails
        cosigneeInfoBlDetails
        shipperInfoBlDetails
        secondNotifyInfoBlDetails
        containers {
          id
          shipmentId
          containerDressing
          containerType
          numberOfContainers
          locationOfContainerStuffing
          targetNetWeightInMtPerContainer
          targetTotalNetWeightInMtPerContainer
          unitType
          containerNumber
          noOfUnits
          netWeightPerUnitKg
          totalNetWeightInMt
          grossWeightPerUnitKg
          totalGrossWeightInMt
          createdOn
          modifiedOn
        }
        documents {
          id
          entityId
          personId
          orderId
          shipmentId
          tradeId
          key
          name
          version
          finalVersion
          archived
          bucketName
          issueDate
          expiryDate
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
        createdOn
        modifiedOn
      }
      documents {
        id
        entityId
        personId
        orderId
        shipmentId
        tradeId
        key
        name
        version
        finalVersion
        archived
        bucketName
        createdOn
        documentTemplateId
        entityId
        expiryDate
        gcn
        id
        issueDate
        key
        modifiedOn
        name
        orderId
        personId
        records
        shipmentId
        softDelete
        tradeId
        version
        documentTemplate {
          id
          name
          category
        }
      }
      createdOn
      modifiedOn
    }
  }
`;

export const listShipments = /* GraphQL */ `
  query listShipments($where: ShipmentWhereInput, $orderBy: [ShipmentOrderByInput!], $skip: Int, $take: Int) {
    listShipments(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      orderId
      status
      unitType
      numberOfUnitsPerContainers
      targetNetWeightPerUnitContainer
      targetGrossWeightPerUnitContainer
      shipmentNumber
      additionalShippingDocuments
      freightForwarder
      freightType
      shippingLine
      bookingNumber
      loadingPort
      destinationPort
      blNumber
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      targetGoodsReadyToStuffDate
      targetStuffingDate
      targetQualityInspectionDate
      expectedGateInDeadline
      expectedVesselSailDate
      expectedArrivalAtDestinationPort
      expectedTransitTime
      actualGoodsReadyToStuffDate
      actualStuffingDate
      actualQualityInspectionDate
      actualGateInDeadline
      actualVesselSailDate
      actualArrivalAtDestinationPort
      actualTransitTime
      mainBodyInfoBlDetails
      cosigneeInfoBlDetails
      shipperInfoBlDetails
      secondNotifyInfoBlDetails
      containers {
        id
        shipmentId
        containerDressing
        containerType
        numberOfContainers
        locationOfContainerStuffing
        targetNetWeightInMtPerContainer
        targetTotalNetWeightInMtPerContainer
        unitType
        containerNumber
        noOfUnits
        netWeightPerUnitKg
        totalNetWeightInMt
        grossWeightPerUnitKg
        totalGrossWeightInMt
        createdOn
        modifiedOn
      }
      documents {
        id
        entityId
        personId
        tradeId
        orderId
        shipmentId
        key
        name
        version
        finalVersion
        archived
        bucketName
        createdOn
        documentTemplateId
        entityId
        expiryDate
        gcn
        id
        issueDate
        key
        modifiedOn
        name
        orderId
        personId
        records
        shipmentId
        softDelete
        tradeId
        version
        documentTemplate {
          id
          name
          category
        }
      }
      createdOn
      modifiedOn
    }
  }
`;

export const listContainers = /* GraphQL */ `
  query listContainers($where: ContainerWhereInput, $orderBy: [ContainerOrderByInput!], $skip: Int, $take: Int) {
    listContainers(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      shipmentId
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      unitType
      containerNumber
      noOfUnits
      netWeightPerUnitKg
      totalNetWeightInMt
      grossWeightPerUnitKg
      totalGrossWeightInMt
      createdOn
      modifiedOn
    }
  }
`;

export const createOrder = /* GraphQL */ `
  mutation createOrder($data: OrderCreateInput!) {
    createOrder(data: $data) {
      id
      status
      orderNumber
      product
      owner
      buyerId
      supplierId
      incoterms
      weight
      pricePerMt
      requiredShippingDocuments
      additionalOrderDocuments
      currency
      currencyFrom
      currencyTo
      rate
      total
      origin
      destination
      clientReferenceNumber
      hsCode
      importPermitNumber
      letterOfCreditNumber
      shipByDeadline
      paymentTerms
      shipmentSchedule
      containerDressing
      productPackaging
      notes
      createdOn
      modifiedOn
    }
  }
`;

export const createShipment = /* GraphQL */ `
  mutation createShipment($data: ShipmentCreateInput!) {
    createShipment(data: $data) {
      id
      orderId
      status
      freightForwarder
      freightType
      shippingLine
      bookingNumber
      loadingPort
      destinationPort
      blNumber
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      targetGoodsReadyToStuffDate
      targetStuffingDate
      targetQualityInspectionDate
      expectedGateInDeadline
      expectedVesselSailDate
      expectedArrivalAtDestinationPort
      expectedTransitTime
      actualGoodsReadyToStuffDate
      actualStuffingDate
      actualQualityInspectionDate
      actualGateInDeadline
      actualVesselSailDate
      actualArrivalAtDestinationPort
      actualTransitTime
      mainBodyInfoBlDetails
      cosigneeInfoBlDetails
      shipperInfoBlDetails
      secondNotifyInfoBlDetails
      createdOn
      modifiedOn
    }
  }
`;

export const updateOrder = /* GraphQL */ `
  mutation updateOrder($where: OrderExtendedWhereUniqueInput!, $data: OrderUpdateInput) {
    updateOrder(where: $where, data: $data) {
      id
      entityId
      status
      orderNumber
      product
      owner
      buyerId
      supplierId
      incoterms
      weight
      unitOfMeasurement
      requiredShippingDocuments
      additionalOrderDocuments
      pricePerMt
      currency
      currencyFrom
      currencyTo
      rate
      total
      origin
      destination
      clientReferenceNumber
      hsCode
      importPermitNumber
      letterOfCreditNumber
      shipByDeadline
      paymentTerms
      shipmentSchedule
      containerDressing
      productPackaging
      notes
      shipments {
        id
        orderId
        status
        additionalShippingDocuments
        freightForwarder
        freightType
        shippingLine
        bookingNumber
        loadingPort
        destinationPort
        blNumber
        containerDressing
        containerType
        numberOfContainers
        locationOfContainerStuffing
        targetNetWeightInMtPerContainer
        targetTotalNetWeightInMtPerContainer
        targetGoodsReadyToStuffDate
        targetStuffingDate
        targetQualityInspectionDate
        expectedGateInDeadline
        expectedVesselSailDate
        expectedArrivalAtDestinationPort
        expectedTransitTime
        actualGoodsReadyToStuffDate
        actualStuffingDate
        actualQualityInspectionDate
        actualGateInDeadline
        actualVesselSailDate
        actualArrivalAtDestinationPort
        actualTransitTime
        mainBodyInfoBlDetails
        cosigneeInfoBlDetails
        shipperInfoBlDetails
        secondNotifyInfoBlDetails
        containers {
          id
          shipmentId
          containerDressing
          containerType
          numberOfContainers
          locationOfContainerStuffing
          targetNetWeightInMtPerContainer
          targetTotalNetWeightInMtPerContainer
          unitType
          containerNumber
          noOfUnits
          netWeightPerUnitKg
          totalNetWeightInMt
          grossWeightPerUnitKg
          totalGrossWeightInMt
          createdOn
          modifiedOn
        }
        documents {
          id
          entityId
          personId
          orderId
          shipmentId
          tradeId
          key
          name
          version
          finalVersion
          archived
          bucketName
          issueDate
          expiryDate
          documentTemplateId
          documentTemplate {
            id
            name
            category
            documentFields
          }
          records
          createdOn
          modifiedOn
        }
        createdOn
        modifiedOn
      }
      documents {
        id
        entityId
        personId
        orderId
        shipmentId
        tradeId
        key
        name
        version
        finalVersion
        archived
        bucketName
        createdOn
        documentTemplateId
        entityId
        expiryDate
        gcn
        id
        issueDate
        key
        modifiedOn
        name
        orderId
        personId
        records
        shipmentId
        softDelete
        tradeId
        version
        documentTemplate {
          id
          name
          category
        }
      }
    }
  }
`;

export const updateShipment = /* GraphQL */ `
  mutation updateShipment($where: ShipmentExtendedWhereUniqueInput!, $data: ShipmentUpdateInput) {
    updateShipment(where: $where, data: $data) {
      id
      orderId
      status
      freightForwarder
      freightType
      shippingLine
      bookingNumber
      loadingPort
      destinationPort
      blNumber
      additionalShippingDocuments
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      targetGoodsReadyToStuffDate
      targetStuffingDate
      targetQualityInspectionDate
      expectedGateInDeadline
      expectedVesselSailDate
      expectedArrivalAtDestinationPort
      expectedTransitTime
      actualGoodsReadyToStuffDate
      actualStuffingDate
      actualQualityInspectionDate
      actualGateInDeadline
      actualVesselSailDate
      actualArrivalAtDestinationPort
      actualTransitTime
      mainBodyInfoBlDetails
      cosigneeInfoBlDetails
      shipperInfoBlDetails
      secondNotifyInfoBlDetails
    }
  }
`;

export const createContainer = /* GraphQL */ `
  mutation createContainer($data: ContainerCreateInput!) {
    createContainer(data: $data) {
      id
      unitType
      containerNumber
      noOfUnits
      netWeightPerUnitKg
      totalNetWeightInMt
      grossWeightPerUnitKg
      totalGrossWeightInMt
      createdOn
      modifiedOn
    }
  }
`;

export const createDocument = /* GraphQL */ `
  mutation createDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      id
      orderId
      shipmentId
      entityId
      personId
      tradeId
      version
      finalVersion
      archived
      key
      name
      bucketName
      gcn
      issueDate
      expiryDate
    }
  }
`;

export const updateDocument = /* GraphQL */ `
  mutation updateDocument($where: DocumentExtendedWhereUniqueInput!, $data: DocumentUpdateInput) {
    updateDocument(where: $where, data: $data) {
      id
      orderId
      shipmentId
      entityId
      personId
      tradeId
      version
      finalVersion
      archived
      key
      name
      bucketName
      gcn
      issueDate
      expiryDate
      modifiedOn
    }
  }
`;

export const updateContainer = /* GraphQL */ `
  mutation updateContainer($where: ContainerExtendedWhereUniqueInput!, $data: ContainerUpdateInput) {
    updateContainer(where: $where, data: $data) {
      id
      containerDressing
      containerType
      numberOfContainers
      locationOfContainerStuffing
      targetNetWeightInMtPerContainer
      targetTotalNetWeightInMtPerContainer
      unitType
      containerNumber
      noOfUnits
      netWeightPerUnitKg
      totalNetWeightInMt
      grossWeightPerUnitKg
      totalGrossWeightInMt
      createdOn
      modifiedOn
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query listProducts($where: ProductsWhereInput, $orderBy: [ProductsOrderByInput!], $skip: Int, $take: Int) {
    listProducts(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      category
      createdOn
      modifiedOn
      entryMode
      note
      owner
      productType
    }
  }
`;
