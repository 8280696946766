/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

// Authentication steps
export const Login = lazy(() => import("pages/auth/login"));

export const Register = lazy(() => import("pages/auth/register"));

export const TwoFactorVerification = lazy(() => import("pages/auth/TwoFactorVerification"));

// CHILDREN ARRAY
export const AuthChildren = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "two-factor-verification",
    element: <TwoFactorVerification />,
  },
];
