/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import FlunaButton from "components/FlunaButton";
import { useNavigate } from "react-router-dom";
import SelectInput from "components/SelectInput";
import NewEntity from "./newComponents/newEntity";
import { entryTypes } from "./helper";
import NewContact from "./newComponents/newContact";
import NewProduct from "./newComponents/newProduct";
import NewTrade from "./newComponents/newTrade";

const NewCompany = () => {
  const navigate = useNavigate();
  const [entityType, setEntityType] = useState<string>("");

  const handleInputChange = (e: SelectChangeEvent) => {
    setEntityType(e.target.value);
  };

  const handleBack = () => {
    navigate("/atlas");
  };

  const components = {
    new_entity: <NewEntity entityType={entityType} handleInputChange={handleInputChange} />,
    new_contact: <NewContact entityType={entityType} handleInputChange={handleInputChange} />,
    new_trade: <NewTrade entityType={entityType} handleInputChange={handleInputChange} />,
    // new_product: <NewProduct entityType={entityType} handleInputChange={handleInputChange} />,
  };

  const component = components[entityType];

  return (
    <Box
      component="div"
      sx={{
        marginTop: "2rem",
        padding: {
          xs: "1rem 1rem",
          sm: "0.5rem 3.5rem",
          md: "0.5rem 5rem 0.5rem 3.5rem",
        },
        color: "#171721",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FlunaButton
          variant="text"
          sx={{
            p: 0,
            my: 2,
            color: "#8F95B2",
          }}
          text="Back"
          onClick={handleBack}
          color="primary"
          icon={<ArrowBack />}
        />
      </Box>
      {component ? (
        <Box>{component}</Box>
      ) : (
        <Box
          sx={{
            maxWidth: "40rem",
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: "2rem",
            }}
          >
            New Entry
          </Typography>
          <Box>
            <SelectInput
              name="entryType"
              id="entryType"
              options={entryTypes}
              labelId="entryType"
              value={entityType}
              onChange={handleInputChange}
              placeholder="Select Entry Type"
              title={
                <Box>
                  <Typography>
                    Entry Type{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "#FF1300",
                      }}
                    >
                      *
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NewCompany;
