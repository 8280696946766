/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import NewTradeStepper from "./newTradeStepper";
import useTradeContext from "./newTradeContext";
import { entryTypes } from "../helper";
import SelectInput from "components/SelectInput";
import CompareValues from "helper/CompareValues";
import { addTrade, retreiveEntities, retreiveProducts, saveTrade } from "services/atlas";
import Input from "components/Input";
import FlunaButton from "components/FlunaButton";
import { useAppSelector } from "redux/hooks";
import { blockUpdatesChecker } from "services/navigation";
import { FlunaModules } from "API";
import AutoCompleteAlt from "components/AutocompleteAlt";
import { ITrade } from "interfaces/atlas";

interface INewTrade {
  entityType: string;
  handleInputChange: (e: SelectChangeEvent) => void;
}

interface FormValues {
  entity: { name: string; value: string };
  product: Array<{ name: string; value: string }>;
  tradeName: string;
  productDescription: string;
}

const initialValues = {
  entity: {
    name: "",
    value: "",
  },
  product: [],
  tradeName: "",
  productDescription: "",
};

const NewTrade = ({ entityType, handleInputChange }: INewTrade) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(2);
  const [entities, setEntities] = useState<Array<{ name: string; value: string }>>([]);
  const [products, setProducts] = useState<any>([]);
  const userModules = useAppSelector((state) => state.permissions.loggedInUserModules);
  const blockAction = blockUpdatesChecker(userModules, FlunaModules.atlas);
  const { Provider, handleTradeUpdate } = useTradeContext();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ product, productDescription, entity, tradeName }) => {
      try {
        setLoading(true);
        let tradeData: any = {
          tradeName,
          productDescription,
        };

        tradeData = {
          ...tradeData,
          entity: {
            connect: {
              id: entity.value,
            },
          },
        };

        const result = await addTrade(tradeData);
        const tradeId = result?.data.createTrade.id;

        if (products.length > 0) {
          const map: any = [];
          product.forEach(({ value }) => {
            map.push(
              saveTrade(tradeId!, {
                products: {
                  connect: {
                    id: value,
                  },
                },
              }),
            );
          });
          await Promise.all(map);
        }

        if (result?.status === 200) {
          handleTradeUpdate(result.data.createTrade);
        }
        setSubmitted(true);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    initialValues,
    validationSchema: Yup.object({
      entity: Yup.object({
        name: Yup.string().required("Entity is required"),
      }),
      product: Yup.array().min(1, "Product is required").required("Product is required"),
      tradeName: Yup.string().required("Trade Name is required"),
      productDescription: Yup.string().required("Product Description is required"),
    }),
  });

  const { handleSubmit, touched, errors, handleBlur, handleChange } = formik;

  useEffect(() => {
    (async () => {
      const response = await retreiveEntities();
      setEntities(
        (response || []).map((val) => ({
          name: val.name,
          value: val.id,
          type: val.entityType,
        })),
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const products = await retreiveProducts();
      setProducts(
        products?.map((product) => ({
          name: product.name,
          value: product.id,
        })),
      );
    })();
  }, []);

  if (submitted) {
    return (
      <Box>
        <Provider>
          <NewTradeStepper activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />
        </Provider>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "40rem",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: "2rem",
        }}
      >
        New Entry
      </Typography>
      <Box>
        <SelectInput
          name="entryType"
          id="entryType"
          options={entryTypes}
          labelId="entryType"
          value={entityType}
          onChange={handleInputChange}
          placeholder="Select Entry Type"
          title={
            <Box>
              <Typography
                sx={{
                  gap: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Entry Type
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <AutoCompleteAlt
          id="entity"
          onBlur={handleBlur}
          error={!!errors.entity?.name && touched.entity?.name}
          errorMessage={errors.entity?.name}
          value={formik.values.entity}
          options={entities.sort(CompareValues("name", "asc"))}
          onChange={(e, value) => {
            formik.setFieldValue("entity", value);
          }}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                  color: errors.entity?.name && touched.entity?.name ? "rgba(0, 0, 0, 0.6) !important" : "inherit",
                }}
              >
                Select Entity
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Input
          sx={{
            mt: 1,
          }}
          type="text"
          id="tradeName"
          error={errors}
          margin="normal"
          name="tradeName"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.tradeName}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Trade Name
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <AutoCompleteAlt
          multiple
          id="product"
          onBlur={handleBlur}
          error={!!errors.product && !!touched.product}
          errorMessage={errors.product as string}
          value={formik.values.product}
          options={products.sort(CompareValues("name", "asc"))}
          onChange={(e, value) => {
            formik.setFieldValue("product", value);
          }}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Select Product(s)
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Input
          multiline
          type="text"
          id="productDescription"
          error={errors}
          margin="normal"
          name="productDescription"
          onBlur={handleBlur}
          required={false}
          fullWidth={false}
          touched={touched}
          value={formik.values.productDescription}
          onChange={handleChange}
          label={
            <Box>
              <Typography
                sx={{
                  gap: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Product Description
                <Box
                  component="span"
                  sx={{
                    color: "#FF1300",
                  }}
                >
                  *
                </Box>
              </Typography>
            </Box>
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "3rem",
          }}
        >
          <div />
          <FlunaButton
            type="submit"
            blockAction={blockAction}
            sx={{
              padding: ".5rem 3rem",
              borderRadius: ".5rem",
            }}
            disabled={loading}
            loading={loading}
            text="Save"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewTrade;
