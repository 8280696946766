import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import DashboardMobileNavbar from "./DashboardDrawerNavbar";
import { SettingsNav } from "constants/Navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { useAppSelector } from "redux/hooks";
import activityHelper from "utils/ActivityHelper";
import ProfileAvatar from "components/ProfileAvatar";
// import { Badge } from '@mui/material'

const DashboardNavAppBar = styled(AppBar)`
  background-color: "#fff";
  color: "#000";
`;

const DashboardNavbar = () => {
  const { loggedInUser } = useAppSelector((state) => state.permissions);
  const { firstName = "", lastName = "", picture = "" } = loggedInUser;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const matches = useMediaQuery("(min-width:900px)");

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        backgroundColor: {
          xs: "#8AB0FB2B",
          sm: "#0550E6",
          md: "#0550E6",
        },
        color: {
          xs: "#212437",
          sm: "#212437",
          md: "#212437",
        },
        width: {
          xs: "30px",
          sm: "40px",
          md: "40px",
        },
        height: {
          xs: "30px",
          sm: "40px",
          md: "40px",
        },
        fontSize: {
          xs: "12px",
          sm: "20px",
          md: "20px",
        },
        fontWeight: {
          xs: 600,
          sm: 500,
          md: 500,
        },
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase(),
    };
  };

  React.useEffect(() => {
    activityHelper();
  }, []);

  return (
    <>
      <DashboardNavAppBar
        elevation={0}
        position="static"
        sx={{
          backgroundColor: "#fff",
          borderBottom: "1px solid #E4E6E8",
          width: "100%",
          position: "sticky",
          top: "0",
          zIndex: "100",
        }}
      >
        <DashboardMobileNavbar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            component="div"
          >
            <Box
              sx={{
                flexGrow: 0,
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                },
              }}
            >
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ color: "#8F95B2" }}
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                border: "1px solid #E4E6E8",
                borderRadius: "8px",
                cursor: "pointer",
                display: {
                  xs: "none",
                  sm: "block",
                  md: "block",
                },
              }}
            ></Box>
            <Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: {
                    xs: "10px",
                    sm: "0px",
                    md: "0px",
                  },
                }}
              >
                {/* <Badge color="primary" badgeContent={badgeCount}> */}
                <IconButton
                  sx={{
                    p: 0,
                    color: {
                      xs: "#8F95B2",
                    },
                  }}
                >
                  {matches ? <img src="/static/NotifIcon.svg" /> : <NotificationsOutlinedIcon fontSize={"medium"} />}
                </IconButton>
                {/* </Badge> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#171721",
                    padding: {
                      xs: "0px 0px",
                      sm: "0px 12px",
                      md: "0px 12px",
                    },
                    cursor: "pointer",
                    gap: {
                      xs: "10px",
                      sm: "0px",
                      md: "0px",
                    },
                  }}
                  onClick={handleOpenUserMenu}
                >
                  <ProfileAvatar
                    {...stringAvatar(`${firstName} ${lastName}`)}
                    name={`${firstName} ${lastName}`}
                    imageSrc={picture}
                    hasImage
                    sx={{
                      background: "#8AB0FB2B",
                      color: "#212437",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "1.1em",
                      fontWeight: 700,
                      padding: "0px 6px",
                      display: {
                        xs: "none",
                        sm: "block",
                        md: "block",
                      },
                    }}
                  >
                    {`${firstName} ${lastName}`}
                  </Typography>

                  <IconButton
                    sx={{
                      p: 0,
                      color: {
                        xs: "#8F95B2",
                      },
                    }}
                  >
                    <KeyboardArrowDownSharpIcon />
                  </IconButton>
                </Box>
                {/* <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
              <AccountCircleIcon />
            </IconButton> */}
                <Menu
                  sx={{ mt: "45px", ml: "" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {SettingsNav.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => {
                          setting.action();
                        }}
                      >
                        {setting.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </DashboardNavAppBar>
    </>
  );
};
export default DashboardNavbar;
