import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

export const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    background: "rgba(191, 59, 39, 0.03)",
    color: "rgba(191, 59, 39, 1)",
    [`& .${stepConnectorClasses.line}`]: {
      position: "relative",
      width: 5,
      border: 3,
      left: 0,
      height: "3.5rem",
      backgroundColor: "#E77A0C",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    background: "rgba(191, 59, 39, 0.03)",
    color: "rgba(191, 59, 39, 1)",
    [`& .${stepConnectorClasses.line}`]: {
      position: "relative",
      width: 5,
      border: 3,
      left: 0,
      height: "3.5rem",
      backgroundColor: "#E77A0C",
      borderRadius: 1,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 5,
    border: 3,
    height: "3.5rem",
    left: 0,
    backgroundColor: "#E9EAEF",
    borderRadius: 1,
  },
}));
export const ColorlibConnector2 = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      position: "relative",
      width: 3,
      border: 1,
      left: 0,
      marginLeft: "-3px",
      height: "2.5rem",
      backgroundColor: "#E77A0C",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      position: "relative",
      width: 3,
      border: 1,
      left: 0,
      marginLeft: "-3px",
      height: "2.5rem",
      backgroundColor: "#E77A0C",
      borderRadius: 1,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 3,
    border: 1,
    marginLeft: "-3px",
    height: "2.5rem",
    left: 0,
    backgroundColor: "#E9EAEF",
    borderRadius: 1,
  },
}));
export const CustomStepperOld = styled(Stepper)(() => ({
  ".MuiStepLabel-iconContainer": {
    display: "none",
  },
  ".MuiStepConnector-root": {
    border: "1px solid #E9EAEF",
  },
}));
export const CustomStepper = styled(Stepper)(() => ({
  ".MuiStepLabel-iconContainer": {
    background: "#BC6003",
    borderRadius: "50%",
    border: "1px solid #FFDBAD",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "0.7rem",
    padding: "0",
  },
  "& .MuiStepLabel-root .Mui-completed": {
    color: "#FFDBAD",

    // circle color (COMPLETED)
  },

  "& .Mui-active .MuiStepIcon-text": {
    fill: "#BC6003",
    color: "#FFDBAD !important",
    fontFamily: "SF Pro Display Medium",
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    // circle color/text (Active) #E9EAEF
  },

  "& .MuiStepLabel-root .Mui-active": {
    color: "#FFDBAD !important", // circle color (ACTIVE)
  },

  ".MuiStepIcon-text ": {
    fill: "#171721",
    fontFamily: "SF Pro Display Medium",
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  },
  "& .Mui-disabled .MuiStepIcon-root": { color: "#E4E4E4" },
}));
