/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const FinanceHome = lazy(() => import("pages/finance/debtInvestorReconciliation"));
const InvestorInvestments = lazy(() => import("pages/finance/debtInvestorReconciliation/investments"));

export const FinanceChildren = [
  {
    path: "finance",
    element: <FinanceHome />,
  },
  {
    path: "finance/investor-reconciliation/:investorId",
    element: <InvestorInvestments />,
  },
];
