export const entryTypes = [
  {
    name: "New Entity",
    value: "new_entity",
  },
  {
    name: "New Contact",
    value: "new_contact",
  },
  {
    name: "New Trade",
    value: "new_trade",
  },
  // {
  //   name: "New Product",
  //   value: "new_product",
  // },
  // {
  //   name: "Update Entity with Trade",
  //   value: "update_entity_with_trade",
  // },
  // {
  //   name: "Update Entity with Certificate",
  //   value: "update_entity_with_certificate",
  // },
  // {
  //   name: "Update Entity with other Documents",
  //   value: "update_entity_with_other_documents",
  // },
  // {
  //   name: "Update Entity with Contact",
  //   value: "update_entity_with_contact",
  // },
];
