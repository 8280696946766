/* eslint-disable @typescript-eslint/no-empty-function */

import { ITrade } from "interfaces/atlas";
import React, { PropsWithChildren, createContext, useState } from "react";

export const NewTradeContext = createContext<{
  trade: ITrade | null;
  handleTradeUpdate: (trade: ITrade) => void;
}>({
  trade: null,
  handleTradeUpdate: () => {},
});

const useTradeContext = () => {
  const [trade, setTrade] = useState<ITrade | null>(null);

  const handleTradeUpdate = (trade: ITrade) => {
    setTrade(trade);
  };

  return {
    handleTradeUpdate,
    Provider: ({ children }: PropsWithChildren) => {
      return (
        <NewTradeContext.Provider
          value={{
            trade,
            handleTradeUpdate,
          }}
        >
          {children}
        </NewTradeContext.Provider>
      );
    },
  };
};

export default useTradeContext;
