import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import { fetchFromTable } from "helper/dynamodb";
import type { Account, Bank, Transaction, Vendor, VertoFXWallet, Wallet } from "interfaces/account";
import type { IMCAOwner, IMCAccount } from "interfaces/mcAccounts";

const companyTable = process.env.REACT_APP_COMPANY_TABLE as string;

interface HomeState {
  companies: IMCAccount[];
  accounts: Account[];
  vendors: Vendor[];
  banks: Bank[];
  wallets: Wallet[];
  owners: IMCAOwner[];
  vertoFXWallets: VertoFXWallet[];
  fetchingCompanies: boolean;
  accountTransactions: Transaction[];
}

const initialState: HomeState = {
  companies: [],
  accounts: [],
  vendors: [],
  banks: [],
  wallets: [],
  owners: [],
  vertoFXWallets: [],
  fetchingCompanies: false,
  accountTransactions: [],
};

export const fetchCompanies = createAsyncThunk("mca/fetchMCACompanies", async () => {
  try {
    const params = {
      ProjectionExpression: "id, createdAt, legalName, vertoStatus, #Owner, usageType",
      ExpressionAttributeNames: { "#Owner": "owner" },
      TableName: companyTable,
    };

    const companies = (await fetchFromTable(params)) as IMCAccount[];

    return companies;
  } catch (err) {
    console.log("companyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch company data" });
    return [] as IMCAccount[];
  }
});

const mcaSlice = createSlice({
  name: "mcAccount",
  initialState,
  reducers: {
    setCompanies: (
      state,
      action: PayloadAction<{
        data: IMCAccount[];
      }>,
    ) => {
      state.companies = action.payload.data;
    },
    setAccounts: (
      state,
      action: PayloadAction<{
        data: Account[];
      }>,
    ) => {
      state.accounts = action.payload.data;
    },
    setVendors: (
      state,
      action: PayloadAction<{
        data: Vendor[];
      }>,
    ) => {
      state.vendors = action.payload.data;
    },
    setBanks: (
      state,
      action: PayloadAction<{
        data: Bank[];
      }>,
    ) => {
      state.banks = action.payload.data;
    },
    setWallets: (
      state,
      action: PayloadAction<{
        data: Wallet[];
      }>,
    ) => {
      state.wallets = action.payload.data;
    },
    setVertoFXWallets: (
      state,
      action: PayloadAction<{
        data: VertoFXWallet[];
      }>,
    ) => {
      state.vertoFXWallets = action.payload.data;
    },
    setOwners: (
      state,
      action: PayloadAction<{
        data: IMCAOwner[];
      }>,
    ) => {
      state.owners = action.payload.data;
    },
    setAccountTransactions: (
      state,
      action: PayloadAction<{
        data: Transaction[];
      }>,
    ) => {
      state.accountTransactions = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state) => {
      state.fetchingCompanies = true;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.fetchingCompanies = false;
    });
    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.companies = action.payload as IMCAccount[];
      state.fetchingCompanies = false;
    });
  },
});

export const { setCompanies, setAccounts, setVendors, setBanks, setWallets, setVertoFXWallets, setOwners, setAccountTransactions } = mcaSlice.actions;

export default mcaSlice.reducer;
