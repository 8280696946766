export const listLeads = /* GraphQL */ `
  query listLeads($where: LeadsWhereInput, $orderBy: [LeadsOrderByInput!], $skip: Int, $take: Int) {
    listLeads(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      source
      inboundLeadId
      clientType
      assignUser
      engagementStatus
      status
      sentMail
      notes
      companyName
      contactName
      unitOfMeasurement
      contactPhone
      contactEmailAddress
      website
      message
      quantity
      productDestinationCountry
      productCategory
      productName
      entityId
      createdOn
      modifiedOn
    }
  }
`;

export const getLead = /* GraphQL */ `
  query getLeads($where: LeadsExtendedWhereUniqueInput!) {
    getLeads(where: $where) {
      id
      source
      inboundLeadId
      clientType
      assignUser
      engagementStatus
      status
      notes
      sentMail
      unitOfMeasurement
      companyName
      contactName
      contactPhone
      contactEmailAddress
      website
      message
      quantity
      productDestinationCountry
      productCategory
      productName
      entityId
      entity {
        id
        name
      }
      createdOn
      modifiedOn
    }
  }
`;

export const createLead = /* GraphQL */ `
  mutation createLeads($data: LeadsCreateInput!) {
    createLeads(data: $data) {
      id
      source
      inboundLeadId
      clientType
      assignUser
      engagementStatus
      status
      notes
      sentMail
      companyName
      unitOfMeasurement
      contactName
      contactPhone
      contactEmailAddress
      website
      message
      quantity
      productDestinationCountry
      productCategory
      productName
      entityId
      createdOn
      modifiedOn
    }
  }
`;

export const updateLead = /* GraphQL */ `
  mutation updateLeads($where: LeadsExtendedWhereUniqueInput!, $data: LeadsUpdateInput) {
    updateLeads(where: $where, data: $data) {
      id
      source
      inboundLeadId
      clientType
      assignUser
      engagementStatus
      status
      notes
      sentMail
      unitOfMeasurement
      companyName
      contactName
      contactPhone
      contactEmailAddress
      website
      message
      quantity
      productDestinationCountry
      productCategory
      productName
      createdOn
      modifiedOn
    }
  }
`;
