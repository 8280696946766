import InviteMember from "pages/permissions/emailTemplates/inviteMember";
import { sendMail } from "./users";
import RequestAccessTemplate from "pages/permissions/emailTemplates/AccessRequested";
import { API } from "aws-amplify";
import { createFlunaMembers, updateFlunaMembers } from "graphql/mutations";
import type { CreateFlunaMembersMutation, ListFlunaMembersQuery, UpdateFlunaMembersMutation } from "API";
import type { GraphQLResult } from "@aws-amplify/api-graphql";
import type { IUsersData } from "interfaces/permissions";
import { ErrorHandler } from "helper/Handlers";
import AccessRequestNotification from "pages/permissions/emailTemplates/AccessRequestNotification";
import dayjs from "dayjs";
import { useTreasuryGraphql } from "helper/useTreasuryGraphql";
import { decodeToken, encodeToken } from "treasuryData/queries";
import { listFlunaMembers } from "graphql/queries";

const graphQLEndpoint = process.env.REACT_APP_TREASURY_GRAPHQL_ENDPOINT as string;
const graphQLApiKey = process.env.REACT_APP_TREASURY_GRAPHQL_API_KEY as string;

export const inviteMemberEmailHandler = async (recipientEmail: string) => {
  const recipientMails = [recipientEmail];

  const mailSubject = "You have been invited";

  const serializedObject = JSON.stringify({ recipientEmail, expiresAt: dayjs().add(1, "day").valueOf() });
  const tokenResult = await useTreasuryGraphql(graphQLEndpoint, encodeToken, { value: serializedObject, secret: recipientEmail }, graphQLApiKey);
  const invitationCode = tokenResult?.data?.encodeToken as string;
  const mailBody = InviteMember(recipientEmail, invitationCode);

  await sendMail({
    showNotif: false,
    recipientMails,
    mailSubject,
    mailBody,
    ccMails: [],
    bccMails: [],
    replyAddresses: [],
  });
};

export const decodeInviteToken = async (token: string, email: string) => {
  const tokenResult = await useTreasuryGraphql(graphQLEndpoint, decodeToken, { token, secret: email }, graphQLApiKey);
  const decodedData = tokenResult?.data?.decodeToken as string;
  const decodedObject = JSON.parse(decodedData);
  const { recipientEmail, expiresAt } = decodedObject;
  return { recipientEmail, expiresAt };
};

export const AccessRequestedEmailHandler = async (recipientEmail: string) => {
  const recipientMails = [recipientEmail];

  const mailSubject = "Your request has been sent";

  const mailBody = RequestAccessTemplate();

  await sendMail({
    showNotif: false,
    recipientMails,
    mailSubject,
    mailBody,
    ccMails: [],
    bccMails: [],
    replyAddresses: [],
  });
};
export const ApproveRequestEmailHandler = async (recipientEmails, memberEmail: string, notes) => {
  const mailSubject = "A Member has Requested Access";

  const mailBody = AccessRequestNotification(memberEmail, notes);

  await sendMail({
    showNotif: false,
    recipientMails: recipientEmails,
    mailSubject,
    mailBody,
    ccMails: [],
    bccMails: [],
    replyAddresses: [],
  });
};

export const getAllFlunaMembers = async (variables?: any) => {
  try {
    let nextToken = null;
    let items: any = [];

    do {
      const listFlunaMembersResponse = (await API.graphql({
        query: listFlunaMembers,
        variables,
      })) as GraphQLResult<ListFlunaMembersQuery>;

      const fetchedItems = listFlunaMembersResponse.data?.listFlunaMembers?.items as IUsersData[];
      items = [...items, ...fetchedItems];
      nextToken = listFlunaMembersResponse.data?.listFlunaMembers?.nextToken as any;
    } while (nextToken);

    items = items.filter((item: any) => item?.firstName && item?.lastName);
    return { success: true, data: items };
  } catch (err) {
    console.log("GetUserError: ", err);
    ErrorHandler({ message: "Unable to fetch all users data" });
    return { success: false, data: [] as IUsersData[] };
  }
};

export const handleUpdateFlunaMember = async (userData: IUsersData) => {
  try {
    const updateFlunaMembersResponse = (await API.graphql({
      query: updateFlunaMembers,
      variables: { input: userData },
    })) as GraphQLResult<UpdateFlunaMembersMutation>;

    return updateFlunaMembersResponse.data?.updateFlunaMembers as IUsersData;
  } catch (err) {
    console.log("UserUpdateErr", err);
    ErrorHandler({ message: "Unable to edit users data" });
    return [] as IUsersData[];
  }
};

export const handleCreateFlunaMember = async (userData: IUsersData) => {
  try {
    const createFlunaMembersResponse = (await API.graphql({
      query: createFlunaMembers,
      variables: { input: userData },
    })) as GraphQLResult<CreateFlunaMembersMutation>;

    return createFlunaMembersResponse.data?.createFlunaMembers as IUsersData;
  } catch (err) {
    console.log("UserCreateErr", err);
    ErrorHandler({ message: "Unable to create user" });
    return [] as IUsersData[];
  }
};
