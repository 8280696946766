import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { Box, Tooltip } from '@mui/material'
import { type Children } from 'interfaces/Route'

export interface Navs {
  name: string
  navigation: string
  element?: any
  notif?: number
  coming?: boolean
  children?: Children[]
  secondarySidebar?: boolean
}

interface SecondaryMenuLinkProps {
  page: Navs
  opened: boolean
}

function SecondaryMenuLink({ page, opened }: SecondaryMenuLinkProps) {
  const resolved = useResolvedPath(page.navigation)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <>
      <Link to={page.navigation} style={{ textDecoration: 'none' }}>
        <Box
          key={page.name}
          sx={{
            color: match ? '#E77A0C' : '#8083A3',
            backgroundColor: match ? '#FFFBF8' : '',
            cursor: 'pointer',
            padding: '14px 18px',
            margin: '4px 0px',
            font: 'SF Pro Display',
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: '600',
            display: 'flex',
            alignitems: 'left',
            borderRight: match ? '2px solid #F26D49' : '',
          }}
        >
          <Box
            alignItems="center"
            mr={2}
            fontSize={opened ? '1.2em' : '1.4em'}
            color={match ? '#E77A0C' : '#8083A3'}
            width={opened ? '0.8em' : '1.4em'}
            height={opened ? '0.8em' : '1.4em'}
          >
            {opened ? (
              page.element
            ) : (
              <Tooltip title={`${page.name}`} placement="right-start">
                {page.element}
              </Tooltip>
            )}
          </Box>
          {page.name}
        </Box>
      </Link>
    </>
  )
}

export default SecondaryMenuLink
