import React from "react";
import { Autocomplete, UseAutocompleteProps, TextField, Typography, SxProps } from "@mui/material";

type Bool = boolean | undefined;
type AutoCompleteProps = UseAutocompleteProps<any, Bool, Bool, Bool>;

type IAutoCompleteAlt = {
  error?: boolean;
  errorMessage?: string;
  sx?: SxProps;
  label: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
} & AutoCompleteProps;

const AutoCompleteAlt = ({ sx, label, error, onBlur, errorMessage, ...rest }: IAutoCompleteAlt) => {
  return (
    <>
      <Autocomplete
        id="entity"
        sx={{
          mt: 3,
          "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderRadius: "0.5rem",
            border: error ? "2px solid #A73636" : "1px solid #E4E6E8",
          },
          ...sx,
        }}
        {...rest}
        onBlur={onBlur}
        isOptionEqualToValue={(option, value) => {
          const lowercasedOption = option.value.toLowerCase();
          const valueLowercased = value.value.toLowerCase();

          return lowercasedOption === valueLowercased;
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      {error && (
        <Typography
          className="error"
          sx={{
            mt: 1,
            color: "#A73636",
            fontSize: "14px",
            position: "relative",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default AutoCompleteAlt;
