import * as React from "react";
import Avatar from "@mui/material/Avatar";
import type { SxProps, Theme } from "@mui/material";

interface AvatarProps {
  name?: string;
  bgColor?: string;
  sx?: SxProps<Theme> | undefined;
  hasIcon?: boolean;
  hasImage?: boolean;
  imageSrc?: string;
  icon?: React.ReactNode;
}
export const stringToColor = (string: string) => {
  let hash = 0;
  let i: number;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export default function ProfileAvatar(props: AvatarProps) {
  const stringAvatar = (name: string) => {
    return {
      sx: {
        ...props.sx,
        bgcolor: props.bgColor ?? stringToColor(name),
      },
      children: `${name.split(" ")[0] ? name.split(" ")[0][0] : ""}${name.split(" ")[1] ? name.split(" ")[1][0] : ""}`.toUpperCase(),
    };
  };
  return (
    <>
      {!props.hasIcon && !props.hasImage && props.name && <Avatar {...stringAvatar(props.name)} />}
      {props.hasIcon && <Avatar sx={{ ...props.sx }}>{props.icon}</Avatar>}
      {props.imageSrc && props.hasImage && <Avatar sx={{ ...props.sx }} alt={props.name} src={props.imageSrc} />}
    </>
  );
}
