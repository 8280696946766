/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const Home = lazy(() => import("pages/home/index")); // Default page
const CompanyDetails = lazy(() => import("pages/company/companyDetails"));

export const KYCChildren = [
  {
    path: "",
    element: <Home />,
  },
  {
    path: "company/:companyId/:tab",
    element: <CompanyDetails />,
  },
  {
    path: "company/:companyId",
    element: <CompanyDetails />,
  },
];
