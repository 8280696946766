/* eslint-disable */
import React from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import CustomLink from "components/CustomLink";
import { Box, Tooltip, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { type NavigationItem } from "interfaces/Route";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

interface SubMenuProps {
  module: NavigationItem;
  opened: boolean;
  setShowing: React.Dispatch<React.SetStateAction<string>>;
  showing: string;
  noAccessModules: NavigationItem[];
}

function SubMenuLink({ noAccessModules, module, opened, showing, setShowing }: SubMenuProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const handleClick = () => {
    if (!isNoAccess) {
      setShowing(module.name);
      setClicked(!clicked);
    }
  };

  const path = module.navigation;
  const subPathMatch = module.subNavigation?.map((path) => useMatch({ path, end: true }))?.filter((path) => path !== null);

  const matchSub = useMatch({ path, end: true }) || subPathMatch?.length;

  const isNoAccess = noAccessModules.find((noAccessModule) => noAccessModule.name === module.name);
  const [clicked, setClicked] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          color: "#171721",
          cursor: "pointer",
          padding: "8px 18px",
          margin: "4px 0px",
          fontSize: "1rem",
          fontWeight: "600",
          display: "flex",
          alignitems: "center",
          justifyContent: opened ? "space-between" : "center",
          borderLeft: matchSub && !module.children ? "4px solid #F26D49" : "4px solid #212437",
        }}
        key={module.name}
      >
        <CustomLink
          to={isNoAccess ? "/request-access" : module.navigation}
          disableMatch={!isMobile && !isNoAccess}
          onClick={handleClick}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: clicked ? "#F26D49" : "#ffffff",
            textDecoration: "none",
          }}
        >
          <Box
            alignItems="center"
            // mr="1rem"
            fontSize={opened ? "1.2em" : "1.4em"}
            display={{ xs: "inline-block", sm: "inline-block", md: "block" }}
          >
            {opened ? (
              // page.element
              ""
            ) : (
              <Tooltip title={module.name} placement="right-start">
                {module.element}
              </Tooltip>
            )}
          </Box>
          {opened ? module.name : ""}
          {opened && clicked ? <ArrowDropUp sx={{ marginLeft: "auto" }} /> : <ArrowDropDown sx={{ marginLeft: "auto" }} />}
        </CustomLink>

        {isNoAccess && (
          <Box display="flex" alignItems="center">
            <LockPersonIcon sx={{ color: "#fff" }} fontSize="small" />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          // display: `${showing === page.name ? 'flex' : 'none'}`,
          display: clicked ? "flex" : "none",
          flexDirection: "column",
        }}
      >
        {module?.children?.map((e) => {
          const updatedPath = e.navigation + "/*";
          const resolvedSub = useResolvedPath(updatedPath);
          const resolvedSubPaths = e.subNavigation?.map((subNav) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return useResolvedPath(`${subNav}/*`);
          });
          const subPathMatch = resolvedSubPaths?.map((path) => useMatch({ path: path.pathname, end: true }))?.filter((path) => path !== null);
          const matchSub = useMatch({ path: resolvedSub.pathname, end: true }) || subPathMatch?.length;

          return (
            <Box
              key={e.name}
              sx={{
                color: "#171721",
                cursor: "pointer",
                padding: {
                  xs: "0.5rem 0px 0.5rem 1.5rem",
                  sm: "0.5rem 0px 0.5rem 1.5rem",
                  md: "0.5rem 0px 0.5rem 3rem",
                },
                fontSize: "0.9rem",
                fontWeight: "400",
                display: "flex",
                alignitems: "center",
                justifyContent: opened ? "space-between" : "center",
                borderLeft: matchSub ? "4px solid #F26D49" : "4px solid #212437",
              }}
            >
              <CustomLink to={isNoAccess ? "/request-access" : e.navigation} subPaths={e.subNavigation}>
                <Box alignItems="center" mr="1rem" fontSize={opened ? "1.2em" : "1.4em"} display={"inline-block"}>
                  {opened ? (
                    e.element
                  ) : (
                    <Tooltip title={e.name} placement="right">
                      {e.element}
                    </Tooltip>
                  )}
                </Box>
                {opened ? e.name : ""}
              </CustomLink>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default SubMenuLink;
