import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Investor, InvestorTableData } from 'interfaces/investors'


interface InvestorState {
    investors: InvestorTableData[]
    selectedInvestor: Investor | null
    isUpdateState: boolean
}


const initialState: InvestorState = {
    investors: [],
    selectedInvestor: null,
    isUpdateState: false,
}


const investorSlice = createSlice({
    name: 'investors',
    initialState,
    reducers: {
      setAllInvestors: (state, action) => {
        state.investors = action.payload.data
      },
      setSelectedInvestor: (
        state,
        action: PayloadAction<{
          data: Investor
        }>
      ) => {
        state.selectedInvestor = action.payload.data
      },
      setUpdateState: (state, action) => {
        state.isUpdateState = action.payload.state
      },
    },
  })

  export const {
    setAllInvestors,
    setSelectedInvestor,
    setUpdateState,
  } = investorSlice.actions
  
  export default investorSlice.reducer