/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const CampaignBuilderLog = lazy(() => import("pages/trade/campaignBuilder/log"));
const CreateCampaign = lazy(() => import("pages/trade/campaignBuilder/createCampaign"));
const CreateOrder = lazy(() => import("pages/trade/tradeTracker/Orders/orderForm/createOrder"));
const CreateShipment = lazy(() => import("pages/trade/tradeTracker/Shipments/shipmentForm/createShipment"));

const TradeTracker = lazy(() => import("pages/trade/tradeTracker/Orders"));
const TradeTrackerOrderDetails = lazy(() => import("pages/trade/tradeTracker/Orders/orderDetails"));
const TradeTrackerShipmentDetails = lazy(() => import("pages/trade/tradeTracker/Shipments/shipmentDetails"));

export const TradeChildren = [
  {
    path: "campaign-builder/log",
    element: <CampaignBuilderLog />,
  },
  {
    path: "campaign-builder/new-campaign",
    element: <CreateCampaign />,
  },
  {
    path: "trade-tracker",
    element: <TradeTracker />,
  },
  {
    path: "trade-tracker/orders/:id",
    element: <TradeTrackerOrderDetails />,
  },
  {
    path: "trade-tracker/orders/:id/shipment/:shipmentId",
    element: <TradeTrackerShipmentDetails />,
  },
  {
    path: "trade-tracker/create-order",
    element: <CreateOrder />,
  },
  {
    path: "trade-tracker/update-order/:orderId/:type",
    element: <CreateOrder />,
  },
  {
    path: "trade-tracker/:orderId/update-shipment/:shipmentId/:type",
    element: <CreateShipment />,
  },
  {
    path: "trade-tracker/:orderId/create-shipment/:type",
    element: <CreateShipment />,
  },
];
