import React, { useEffect } from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import routes from "routes/Routes";
import { Toaster } from "react-hot-toast";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import theme from "utils/theme";
import ErrorBoundary from "utils/ErrorBoundary";
import { Hub } from "aws-amplify";
import { setLogout, saveAuthUser } from "services/auth";
import SplashScreen from "components/SplashScreen";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { getLoggedInUser } from "redux/authSlice";

const App = () => {
  const content = useRoutes(routes);
  const auth = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          saveAuthUser(data);
          break;
        case "signOut":
          setLogout();
          break;
      }
    });

    dispatch(getLoggedInUser());

    return unsubscribe;
  }, []);

  return (
    <HelmetProvider>
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <ThemeProvider theme={theme}>
          <Toaster />
          <CssBaseline />
          {auth.isInitialized ? content : <SplashScreen />}
        </ThemeProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
