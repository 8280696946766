import { fetchFromTable, putInTable, getFromTable, queryTable } from "helper/dynamodb";
import { type RolesOptionsData, type IUserData } from "interfaces/home";
import { setUserTableData } from "redux/usersSlice";
import store from "redux/store";
import { ErrorHandler, SuccessHandler } from "helper/Handlers";
import { InvitationTypes, type InvitationWithCompanyOwnerId, type sendMailPayload } from "interfaces/company";
import Environments from "constants/Environments";
import { encodeToken, inviteUser } from "treasuryData/queries";
import { useTreasuryGraphql } from "helper/useTreasuryGraphql";
import invitationEmailTemplate from "mailTemplates/invitationEmailTemplate";
import { customGenericMailSender, getFlunaMembers } from "graphql/queries";
import { FlunaMembers, GetFlunaMembersQuery, type CustomGenericMailSenderQuery } from "API";
import { type GraphQLResult } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";

const userTable = process.env.REACT_APP_USER_TABLE as string;
const rolesTable = process.env.REACT_APP_ROLES_TABLE as string;
const environment = process.env.REACT_APP_NODE_ENV as string;
const poolId = process.env.REACT_APP_TREASURY_POOL_ID as string;
const graphQLEndpoint = process.env.REACT_APP_TREASURY_GRAPHQL_ENDPOINT as string;
const graphQLApiKey = process.env.REACT_APP_TREASURY_GRAPHQL_API_KEY as string;

export const fetchingUsers = async () => {
  const params = {
    TableName: userTable,
  };

  const users = (await fetchFromTable(params)) as IUserData[];

  store.dispatch(setUserTableData({ data: users }));
};

export const createNewUser = async (userData: IUserData) => {
  try {
    await putInTable(userTable, userData);

    await fetchingUsers();
  } catch (err) {
    console.error("Error creating user:", err);
    ErrorHandler({ message: "Unable to create user" });
  }
};

export const editUser = async (userData: IUserData) => {
  try {
    await putInTable(userTable, userData);

    await fetchingUsers();
  } catch (err) {
    console.error("Error editing user:", err);

    throw err;
  }
};

export const fetchingCompanyRoles = async (companyId) => {
  const companyRolesParams = {
    TableName: rolesTable,
    KeyConditionExpression: "#companyId = :companyId",
    IndexName: "byCompanyRole",
    ExpressionAttributeNames: { "#companyId": "companyId", "#Active": "active" },
    ExpressionAttributeValues: {
      ":companyId": companyId,
      ":active": true,
    },
    ProjectionExpression: "id, #companyId, title, #Active",
    FilterExpression: "#Active = :active",
  };

  const fetchedCompanyRoles = (await queryTable(companyRolesParams)) as RolesOptionsData[];

  return fetchedCompanyRoles;
};

export const sendTeamInvite = async (payload: InvitationWithCompanyOwnerId) => {
  const { companyOwnerId, ...objectToSerialise } = payload;
  const { email, companyName, firstName, lastName } = payload;

  const companyOwnerDetails = await getFromTable(userTable, companyOwnerId);
  const companyOwnerUserName = `${companyOwnerDetails?.firstName as string} ${companyOwnerDetails?.lastName as string}`;

  const serializedObject = JSON.stringify({ ...objectToSerialise, username: companyOwnerUserName });

  try {
    const tokenResult = await useTreasuryGraphql(graphQLEndpoint, encodeToken, { value: serializedObject, secret: email }, graphQLApiKey);

    const invitationCode = tokenResult?.data?.encodeToken as string;
    const invitationLink = `${Environments[environment || "dev"]}/auth/register?inviteCode=${invitationCode}&email=${email}`;

    const userInvitation = await useTreasuryGraphql(
      graphQLEndpoint,
      inviteUser,
      {
        input: {
          firstName,
          lastName,
          companyName,
          inviteCode: invitationCode,
          invitationType: InvitationTypes.createUser,
          email,
          IdentityPoolId: poolId,
        },
      },
      graphQLApiKey,
    );

    if (userInvitation && userInvitation?.data?.inviteUser === "success") {
      const recipientMails = [email];
      const mailSubject = "Invite to Fluna";
      const mailBody = `'${invitationEmailTemplate(`${firstName} ${lastName}`, companyOwnerUserName, companyName, invitationLink)}'`;

      await sendMail({
        mailBody,
        mailSubject,
        ccMails: [],
        bccMails: [],
        recipientMails,
        showNotif: true,
      });

      return true;
    } else {
      ErrorHandler({ message: userInvitation?.data?.inviteUser || "" });
      return false;
    }
  } catch (e) {
    console.log(e);
    ErrorHandler({ message: "unable to send user invite" });
  }
};

export const sendMail = async (payload: sendMailPayload) => {
  const { showNotif, recipientMails, mailSubject, mailBody, ccMails, bccMails, replyAddresses, mailSender } = payload;
  try {
    const response = (await API.graphql({
      query: customGenericMailSender,
      variables: {
        recipientMails,
        mailSubject,
        mailBody,
        mailSender: mailSender || '',
        ccMails,
        bccMails,
        replyAddresses,
      },
    })) as GraphQLResult<CustomGenericMailSenderQuery>;

    if (showNotif) {
      SuccessHandler({ message: "Mail Sent Successfully" });
    }
    console.log("Mail sent for ", mailSubject);
    return {
      status: "Success",
      data: JSON.parse(response?.data?.customGenericMailSender as string),
    };
  } catch (err: any) {
    ErrorHandler({ message: "Unable to send mail" });
    return { status: "Error", error: err };
  }
};

export const getFlunaMember = async (variables?: any) => {
  try {
    const getFlunaMemberResponse = (await API.graphql({
      query: getFlunaMembers,
      variables,
    })) as GraphQLResult<GetFlunaMembersQuery>;

    return { success: true, data: getFlunaMemberResponse.data?.getFlunaMembers as FlunaMembers };
  } catch (err) {
    console.log("GetFlunaMemberError: ", err);
    ErrorHandler({ message: "Unable to fetch Fluna Member data" });
    return { success: false, data: null };
  }
};
