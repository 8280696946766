/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const Overview = lazy(() => import("pages/kpiMetrics/index"));

export const KPIChildren = [
  {
    path: "overview",
    element: <Overview />,
  },
];
