import { createSlice, createAsyncThunk, type PayloadAction } from "@reduxjs/toolkit";
import { getLead, listLeads } from "atlasGraphql/sales-tracker-queries";
import { makeAPI } from "hooks/useAtlasAxios";
import { type ILeads } from "interfaces/salesTracker";

interface IFetchLeads {
  listLeads: ILeads[];
}

interface IFetchSingleLead {
  getLeads: ILeads;
}

interface SalesTrackerState {
  rawInboundLeads: ILeads[];
  suppliersLeads: ILeads[];
  buyersLeads: ILeads[];
  logisiticsLeads: ILeads[];
  othersLeads: ILeads[];
  spamLeads: ILeads[];
  selectedRawInboundLeads: ILeads | null;
  loadingLeads: boolean;
  loadingSingleLeads: boolean;
  loadingSuppliersLeads: boolean;
  loadingBuyersLeads: boolean;
  loadingLogisiticsLeads: boolean;
  loadingSpamLeads: boolean;
  loadingOthersLeads: boolean;
}

const initialState: SalesTrackerState = {
  rawInboundLeads: [],
  suppliersLeads: [],
  buyersLeads: [],
  logisiticsLeads: [],
  othersLeads: [],
  spamLeads: [],
  selectedRawInboundLeads: null,
  loadingLeads: false,
  loadingSuppliersLeads: false,
  loadingBuyersLeads: false,
  loadingLogisiticsLeads: false,
  loadingSpamLeads: false,
  loadingOthersLeads: false,
  loadingSingleLeads: false,
};

export const fetchRawInboundLeads = createAsyncThunk("atlas/fetchRawInboundLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchSupplierLeads = createAsyncThunk("atlas/fetchSupplierLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchBuyersLeads = createAsyncThunk("atlas/fetchBuyersLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchLogisticsLeads = createAsyncThunk("atlas/fetchLogisticsLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchOthersLeads = createAsyncThunk("atlas/fetchOthersLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchSpamLeads = createAsyncThunk("atlas/fetchSpamLeads", async ({ filters }: { filters?: any }) => {
  try {
    const leads = await makeAPI<any, IFetchLeads>({
      query: listLeads,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return leads.data.listLeads || [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchSelectedRawInboundLeads = createAsyncThunk("atlas/fetchSelectedRawInboundLeads", async (where: any) => {
  try {
    const leads = await makeAPI<any, IFetchSingleLead>({
      query: getLead,
      variables: {
        where,
      },
    });

    return leads.data.getLeads || null;
  } catch (err) {
    console.log(err);
    return null;
  }
});

const SalesTrackerSlice = createSlice({
  name: "salesTracker",
  initialState,
  reducers: {
    setLeadsData: (
      state,
      action: PayloadAction<{
        data: ILeads;
      }>,
    ) => {
      state.selectedRawInboundLeads = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRawInboundLeads.pending, (state) => {
      state.loadingLeads = true;
    });
    builder.addCase(fetchRawInboundLeads.fulfilled, (state, action) => {
      state.rawInboundLeads = action.payload;
      state.loadingLeads = false;
    });
    builder.addCase(fetchRawInboundLeads.rejected, (state, action) => {
      state.rawInboundLeads = [...state.rawInboundLeads, ...(action.payload as ILeads[])];
      state.loadingLeads = false;
    });

    builder.addCase(fetchSupplierLeads.pending, (state) => {
      state.loadingSuppliersLeads = true;
    });
    builder.addCase(fetchSupplierLeads.fulfilled, (state, action) => {
      state.suppliersLeads = action.payload;
      state.loadingSuppliersLeads = false;
    });
    builder.addCase(fetchSupplierLeads.rejected, (state, action) => {
      state.suppliersLeads = [...state.suppliersLeads, ...(action.payload as ILeads[])];
      state.loadingSuppliersLeads = false;
    });

    builder.addCase(fetchBuyersLeads.pending, (state) => {
      state.loadingBuyersLeads = true;
    });
    builder.addCase(fetchBuyersLeads.fulfilled, (state, action) => {
      state.buyersLeads = action.payload;
      state.loadingBuyersLeads = false;
    });
    builder.addCase(fetchBuyersLeads.rejected, (state, action) => {
      state.buyersLeads = [...state.buyersLeads, ...(action.payload as ILeads[])];
      state.loadingBuyersLeads = false;
    });

    builder.addCase(fetchLogisticsLeads.pending, (state) => {
      state.loadingLogisiticsLeads = true;
    });
    builder.addCase(fetchLogisticsLeads.fulfilled, (state, action) => {
      state.logisiticsLeads = action.payload;
      state.loadingLogisiticsLeads = false;
    });
    builder.addCase(fetchLogisticsLeads.rejected, (state, action) => {
      state.logisiticsLeads = [...state.logisiticsLeads, ...(action.payload as ILeads[])];
      state.loadingLogisiticsLeads = false;
    });

    builder.addCase(fetchOthersLeads.pending, (state) => {
      state.loadingOthersLeads = true;
    });
    builder.addCase(fetchOthersLeads.fulfilled, (state, action) => {
      state.othersLeads = action.payload;
      state.loadingOthersLeads = false;
    });
    builder.addCase(fetchOthersLeads.rejected, (state, action) => {
      state.othersLeads = [...state.othersLeads, ...(action.payload as ILeads[])];
      state.loadingOthersLeads = false;
    });

    builder.addCase(fetchSpamLeads.pending, (state) => {
      state.loadingSpamLeads = true;
    });
    builder.addCase(fetchSpamLeads.fulfilled, (state, action) => {
      state.spamLeads = action.payload;
      state.loadingSpamLeads = false;
    });
    builder.addCase(fetchSpamLeads.rejected, (state, action) => {
      state.spamLeads = [...state.spamLeads, ...(action.payload as ILeads[])];
      state.loadingSpamLeads = false;
    });

    builder.addCase(fetchSelectedRawInboundLeads.pending, (state) => {
      state.loadingSingleLeads = true;
    });
    builder.addCase(fetchSelectedRawInboundLeads.fulfilled, (state, action) => {
      state.selectedRawInboundLeads = action.payload;
      state.loadingSingleLeads = false;
    });
    builder.addCase(fetchSelectedRawInboundLeads.rejected, (state, action) => {
      state.selectedRawInboundLeads = null;
      state.loadingLeads = false;
      state.loadingSingleLeads = false;
    });
  },
});

export const { setLeadsData } = SalesTrackerSlice.actions;

export default SalesTrackerSlice.reducer;
