/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import { fetchFromTable } from "helper/dynamodb";

const loanTable = process.env.REACT_APP_COMPANY_LOANS_TABLE as string;
const loanPaymentsTable = process.env.REACT_APP_LOANS_PAYMENTS_TABLE as string;
const invDiscTable = process.env.REACT_APP_INVOICE_DISC_TABLE as string;
const invDiscPaymentsTable = process.env.REACT_APP_INVOICE_DISC_PAYMENTS_TABLE as string;

interface HomeState {
  loans: any[];
  loanPayments: any[];
  invDiscs: any[];
  invDiscPayments: any[];
  fetchingLoans: boolean;
}

const initialState: HomeState = {
  loans: [],
  loanPayments: [],
  invDiscs: [],
  invDiscPayments: [],
  fetchingLoans: false,
};

export const fetchLoanPaymentsData = createAsyncThunk("loans/fetchLoanPayments", async () => {
  try {
    const params = {
      ProjectionExpression: "id, dueDate, #Status, paymentType, amount, companyId, loanId, currency",
      ExpressionAttributeNames: { "#Status": "status" },
      ExpressionAttributeValues: { ":status": "Repayment" },
      FilterExpression: "#Status <> :status",
      TableName: loanPaymentsTable,
    };

    const loansPayments = (await fetchFromTable(params)) as any[];
    return loansPayments;
  } catch (err: any) {
    console.log("conpanyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch loans Payments data" });
    return [] as any[];
  }
});

export const fetchInvDiscPaymentsData = createAsyncThunk("loans/fetchInvDiscPayments", async () => {
  try {
    const params = {
      ProjectionExpression: "id, dueDate, #Status, paymentType, amount, companyId, invoiceDiscountingId, currency",
      ExpressionAttributeNames: { "#Status": "status" },
      ExpressionAttributeValues: { ":status": "Repayment" },
      FilterExpression: "#Status <> :status",
      TableName: invDiscPaymentsTable,
    };

    const invDiscPayments = (await fetchFromTable(params)) as any[];
    return invDiscPayments;
  } catch (err: any) {
    console.log("conpanyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch Inv. Disc. Payments data" });
    return [] as any[];
  }
});

export const fetchLoansData = createAsyncThunk("loans/fetchLoans", async () => {
  try {
    const params = {
      ProjectionExpression: "id, creditStructureApproved, renewalId, loanType, #Status, companyID",
      ExpressionAttributeNames: { "#Status": "status" },
      ExpressionAttributeValues: { ":status": "Active" },
      FilterExpression: "#Status = :status",
      TableName: loanTable,
    };

    const loans = (await fetchFromTable(params)) as any[];
    return loans;
  } catch (err: any) {
    console.log("conpanyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch loans data" });
    return [] as any[];
  }
});

export const fetchInvDiscData = createAsyncThunk("loans/fetchInvDisc", async () => {
  try {
    const params = {
      ProjectionExpression: "id, overviewApproved,  #Status, companyID",
      ExpressionAttributeNames: { "#Status": "status" },
      ExpressionAttributeValues: { ":status": "active" },
      FilterExpression: "#Status = :status",
      TableName: invDiscTable,
    };

    const loans = (await fetchFromTable(params)) as any[];
    return loans;
  } catch (err: any) {
    console.log("conpanyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch loans data" });
    return [] as any[];
  }
});

// Then, handle actions in your reducers:
const loanBookSlice = createSlice({
  name: "loanBooks",
  initialState,
  reducers: {
    setLoanBookData: (
      state,
      action: PayloadAction<{
        data: any[];
      }>,
    ) => {
      state.loans = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoansData.pending, (state) => {
      state.fetchingLoans = true;
    });
    builder.addCase(fetchLoansData.fulfilled, (state, action) => {
      state.loans = action.payload;
      state.fetchingLoans = false;
    });
    builder.addCase(fetchLoansData.rejected, (state, action) => {
      state.fetchingLoans = false;
    });
    builder.addCase(fetchInvDiscData.fulfilled, (state, action) => {
      state.invDiscs = action.payload;
      state.fetchingLoans = false;
    });
    builder.addCase(fetchLoanPaymentsData.pending, (state) => {
      state.fetchingLoans = true;
    });
    builder.addCase(fetchLoanPaymentsData.fulfilled, (state, action) => {
      state.loanPayments = action.payload;
      state.fetchingLoans = false;
    });
    builder.addCase(fetchLoanPaymentsData.rejected, (state, action) => {
      state.fetchingLoans = false;
    });
    builder.addCase(fetchInvDiscPaymentsData.fulfilled, (state, action) => {
      state.invDiscPayments = action.payload;
    });
  },
});

export const { setLoanBookData } = loanBookSlice.actions;

export default loanBookSlice.reducer;
