import * as React from "react";
import { Link, useMatch, useResolvedPath, type LinkProps } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

interface UpdatedProps extends LinkProps {
  disableMatch?: boolean;
  to: string;
  subPaths?: string[];
}

function CustomLink({ children, disableMatch, to, subPaths, ...props }: UpdatedProps) {
  const updatedPath = to + "/*";
  const resolved = useResolvedPath(updatedPath);
  const resolvedSubPaths = subPaths?.map((subNav) => {
    return useResolvedPath(`${subNav}/*`);
  })
  const subPathMatch = resolvedSubPaths?.map((path) => useMatch({ path: path.pathname, end: true }))?.filter((path) => path !== null);
  const pathMatch = useMatch({ path: resolved.pathname, end: true }) || subPathMatch?.length;

  const desktopWidth = useMediaQuery("(min-width:900px)");

  return (
    <>
      {desktopWidth ? (
        <Link
          style={{
            textDecoration: "none",
            color: pathMatch && !disableMatch ? "#F26D49" : "#D5D7E3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "6px 0px",
            lineHeight: "20px",
            fontSize: "16px",
          }}
          to={to}
          {...props}
        >
          {children}
        </Link>
      ) : (
        <Link
          style={{
            textDecoration: "none",
            color: pathMatch && !disableMatch ? "#E77A0C" : "#D5D7E3",
            padding: "0px 10px",
          }}
          to={to}
          {...props}
        >
          {children}
        </Link>
      )}
    </>
  );
}

export default CustomLink;
