import { Box, Grid, StepLabel, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import Step from "@mui/material/Step";
import { ColorlibConnector2, CustomStepper } from "components/Stepper";
import NewTradeOverview from "./newTradeOverview";
import NewTradeSupplier from "./newTradeSupplier";
import NewTradeBuyer from "./newTradeBuyer";

interface IProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
}

const newTradeNavs = ["", "New Trade", "Trade Overview", "Supplier Cost", "Buyer Cost"];

const NewTradeStepper = ({ activeStep, handleBack, handleNext }: IProps) => {
  const pages: React.ReactNode[] = [
    "",
    "",
    <NewTradeOverview key={2} handleNext={handleNext} handleBack={handleBack} />,
    <NewTradeSupplier key={3} handleNext={handleNext} handleBack={handleBack} />,
    <NewTradeBuyer key={4} handleNext={handleNext} handleBack={handleBack} />,
  ];

  return (
    <Box>
      <Helmet title="Fluna Inc. | Request Payment" />
      <Grid container columnSpacing={4} sx={{ padding: "2rem" }}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box sx={{ maxWidth: 300, marginBottom: "1rem" }}>
            <Box
              sx={{
                border: "1px solid #E9EAEF",
                padding: "1rem 0 1rem 1rem",
                borderRadius: "0.5rem",
              }}
            >
              {activeStep < pages.length && (
                <CustomStepper activeStep={activeStep - 1} orientation="vertical" connector={<ColorlibConnector2 />}>
                  {newTradeNavs.slice(1).map((nav, index) => {
                    if (nav === "") {
                      return null;
                    }

                    return (
                      <Step key={index}>
                        <StepLabel
                          sx={{
                            padding: "0",
                          }}
                        >
                          <Box component="span">
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1rem",
                                  sm: "0.813rem",
                                  md: "1rem",
                                },
                                color: activeStep === newTradeNavs.indexOf(nav) || activeStep > newTradeNavs.indexOf(nav) ? "#BC6003" : "#8083A3",
                              }}
                            >
                              {nav}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </CustomStepper>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          {pages[activeStep]}
        </Grid>
      </Grid>
      {/* {activeStep >= pages.length && <SharePaymentRequest />} */}
    </Box>
  );
};

export default NewTradeStepper;
