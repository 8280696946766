const RequestAccessTemplate = () => {
  return `
      <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
  </head>

  <body>
    <table
      role="presentation"
      style="
        max-width: 765px;
        width: 100%;
        margin: auto;
        font-family: 'Public Sans', sans-serif;
      "
    >
      <tr>
        <td
          style="
            border-right: 1px solid rgba(233, 234, 239, 1);
            border-left: 1px solid rgba(233, 234, 239, 1);
            padding: 10px;
          "
        >
          <img
            src="https://flunawebassets.s3.eu-west-2.amazonaws.com/Fluna+Logo+Package+For+Folders-01+(1)+1.png"
            alt="Logo"
            width="80"
            style="height: auto; display: block"
          />
        </td>
      </tr>
      <tr>
        <td
          style="
            background: linear-gradient(
              90deg,
              rgba(243, 121, 36, 1) 0%,
              rgba(235, 54, 39, 1) 100%
            );
            border-radius: 0 0 10px 10px;
            padding: 16px;
          "
        >
          <p
            style="
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              color: white;
            "
          >
            Your Request has been sent
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="
            padding: 40px;
            line-height: 32px;
            font-size: 18px;
            color: #253858;
            font-style: normal;
            font-weight: 400;
          "
        >
          <p>Hi there,</p>
          <p>
            Your request to access
            <a
              style="text-decoration: none; color: #253858"
              href="https://www.core.fluna.co/"
            >
              <u> Fluna Core.</u>
            </a>
            has been sent successfully.
          </p>
          <p>
            You’ll be notified via email when your request has been granted.
          </p>
        </td>
      </tr>
      <tr>
        <td style="padding: 20px 0px"></td>
      </tr>
      <tr>
        <td
          style="
            padding: 40px;
            line-height: 32px;
            font-size: 16px 0;
            color: #253858;
            font-style: normal;
            font-weight: 400;
          "
        >
          <p>Best, <br />Fluna Team</p>
        </td>
      </tr>
      <tr>
        <td
          style="
            text-align: left;
            padding: 16px 40px;
            color: #8083a3;
            font-style: normal;
            font-weight: 400;
          "
        >
          <p style="font-size: 14px; line-height: 32px">
            Questions? Feel free to send us a mail (<a
              href="mailto:help@fluna.co"
              style="color: #e77a0c; text-decoration: none"
              >help@fluna.co</a
            >) or
            <a
              href="https://tawk.to/chat/63e6299ec2f1ac1e203282da/1goth5552"
              target="_blank"
              rel="noreferrer noopener"
              style="color: #e77a0c; text-decoration: none"
              >chat with our team</a
            >.
          </p>
          <p style="font-size: 12px; line-height: 22px">
            © Fluna Inc. | info@fluna.co
          </p>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};

export default RequestAccessTemplate;
