import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type IPPayments } from "interfaces/ipPayments";

interface PaymentState {
  deposits: IPPayments[];
  withdrawals: IPPayments[];
  interests: IPPayments[];
  principal: IPPayments[];
  fetchingPayments: boolean;
}

const initialState: PaymentState = {
  deposits: [],
  withdrawals: [],
  interests: [],
  principal: [],
  fetchingPayments: false,
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setDeposits: (
      state,
      action: PayloadAction<{
        data: IPPayments[];
      }>,
    ) => {
      state.deposits = action.payload.data;
    },
    setWithdrawals: (
      state,
      action: PayloadAction<{
        data: IPPayments[];
      }>,
    ) => {
      state.withdrawals = action.payload.data;
    },
    setInterests: (
      state,
      action: PayloadAction<{
        data: IPPayments[];
      }>,
    ) => {
      state.interests = action.payload.data;
    },
    setPrincipal: (
      state,
      action: PayloadAction<{
        data: IPPayments[];
      }>,
    ) => {
      state.principal = action.payload.data;
    },
  },
});

export const { setDeposits, setWithdrawals, setInterests, setPrincipal } = paymentSlice.actions;

export default paymentSlice.reducer;
