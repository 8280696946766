const resetPasswordEmailTemplate = function (userName: string) {
  return `
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <style>
      @media screen and (max-width: 576px) {
        table {
          width: calc(100% - 48px) !important;
          margin: 0 24px !important;
        }
        td {
          padding: 40px 0px 0px !important;
        }
        .banner-icon {
          padding: 0px !important;
        }
        .banner-text {
          padding: 16px 24px !important;
        }
        .banner-text > p {
          font-size: 20px !important;
        }
        td > a {
          font-size: 14px !important;
        }
      }
    </style>
  </head>
  <body style="margin: 0; padding: 0">
    <table
      role="presentation"
      style="
        max-width: 602px;
        width: 100%;
        margin: auto;
        font-family: 'Public Sans', sans-serif;
        color: #171721;
        border-collapse: collapse;
      "
    >
      <tr>
        <td class="banner-icon">
          <img
            src="https://flunawebassets.s3.eu-west-2.amazonaws.com/Fluna+Logo+Package+For+Folders-01+(1)+1.png"
            alt="Logo"
            width="90"
            style="height: auto; display: block; padding: 0.5rem 0.75rem"
          />
        </td>
      </tr>
      <tr>
        <td
          class="banner-text"
          style="
            background: linear-gradient(
              100deg,
              rgba(243, 121, 36, 1) 55.8%,
              rgba(235, 54, 39, 1) 98.76%
            );
            border-radius: 0 0 10px 10px;
            padding: 48px 42px;
          "
        >
          <p
            style="
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: #fff;
            "
          >
            Password Change
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="
            padding: 40px 40px 0px 40px;
            line-height: 26px;
            font-size: 16px;
            color: #253858;
            font-style: normal;
            font-weight: 400;
          "
        >
          <p>Dear ${userName},</p>
          <p style="padding-top: 24px">
            We are writing to inform you that your password has been changed. If
            you did not request this change, please contact us immediately at
            <a href="mailto:help@fluna.co" style="color: #253858">
              help@fluna.co</a
            >
            to report the issue.
          </p>
          <p style="padding-top: 24px">
            Your account security is important to us and we take all necessary
            measures to ensure the safety of your personal and company
            information. If you suspect any unauthorized activity on your
            account or have any concerns, please do not hesitate to reach out to
            us.
          </p>
        </td>
      </tr>
      <tr>
        <td style="text-align: left; padding: 24px 40px">
          <p
            style="
              padding: 0px;
              line-height: 32px;
              font-size: 16px;
              color: #253858;
              font-style: normal;
              font-weight: 400;
            "
          >
            Best, <br />
            Fluna Team
          </p>
          <p
            style="
              font-size: 14px;
              line-height: 24px;
              text-align: left;
              font-weight: 400;
              color: #8083a3;
              margin-top: 8px;
            "
          >
            Questions? Feel free to send us an email (<a
              href="mailto:support@fluna.co"
              style="color: #e77a0c; text-decoration: none"
            >
              support@fluna.co </a
            >) or
            <span style="color: #e77a0c">
              <a
                href="https://tawk.to/chat/63e6299ec2f1ac1e203282da/1goth5552"
                target="_blank"
                rel="noreferrer noopener"
                style="color: #e77a0c"
              >
                chat with our team
              </a>
            </span>
          </p>
          <p
            style="
              font-size: 12px;
              line-height: 22px;
              font-weight: 400;
              text-align: left;
              color: #8083a3;
              margin-top: -16px;
            "
          >
            © Fluna Inc. |
            <a
              href="mailto:info@fluna.co"
              style="color: #8083a3; text-decoration: none"
            >
              info@fluna.co
            </a>
          </p>
        </td>
      </tr>
    </table>
  </body>
</html>
   `;
};

export default resetPasswordEmailTemplate;
