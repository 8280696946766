import React, { type ReactElement, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { InputBase, Typography } from "@mui/material";
import AutoCompleteWithSearchBar from "components/AutoCompleteWithSearchBar";

interface Props {
  onChange: (field: string, value: string) => void;
  setFieldTouched?: (field: string, value: boolean) => void;
  value: string;
  options: Array<{
    value: string;
    name: string;
    display?: string | ReactElement;
    avatar?: string;
  }>;
  placeholder?: string;
  title: string | React.ReactElement;
  dataTestid?: string;
  name: string;
  className?: string;
  error: Record<string, string>;
  touched?: Record<string, boolean>;
  labelId: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  noImage?: boolean;
  hasFlag?: boolean;
  variant?: "outlined" | "standard" | "filled";
  sx?: Record<string, string | number>;
}

const MenuProps = {
  PaperProps: {
    style: {
      display: "none",
    },
  },
};

const SelectWrapper = styled(FormControl)(() => ({
  width: "100%",
  textAlign: "left",
  marginBottom: "0.5rem",
  marginTop: "1rem",
}));

const CustomLabel = styled(InputLabel)(() => ({
  position: "absolute",
  marginTop: "0.1rem",
  color: "#8083A3",
  fontWeight: "600",
  "&.Mui-focused, input:focus": {
    marginTop: "1.2rem",
  },
  "&.MuiFormLabel-filled": {
    marginTop: "1.2rem",
    fontFamily: "SF Pro Display Medium",
  },
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: "1rem",
    width: "100%",
    padding: "1.8rem 0 0.2625rem 0.7rem",
    fontWeight: "700",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    textAlign: "left",
    border: "1px solid #E4E6E8",
    borderRadius: "0.5rem",
    "&:focus": {
      borderRadius: "0.5rem",
      border: `2px solid  ${theme.palette.primary.main}`,
      padding: "1.8rem 0 0.2625rem 0.7rem",
    },
    "&.Mui-disabled": {
      WebkitTextFillColor: "#8083A3",
      color: " #8083A3",
      backgroundColor: "#F5F5FA",
    },
    "&.Mui-error": {
      border: "2px solid #A73636",
      color: "rgba(0, 0, 0, 0.6) !important",
    },
  },
  "&.MuiFormLabel-root MuiInputLabel-root.Mui-error": {
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  label: {
    color: "#8083A3",
    fontFamily: "SF Pro Display Medium",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    marginTop: "2px",
    border: "none !important",
  },
  placeholder: {
    color: "#8083A3 !important",
    fontFamily: "SF Pro Display Medium",
    lineHeight: "18px",
  },
}));

const findAlabel = (options: Array<{ value: string; name: string }>, value: string) => {
  const result = options.find((option) => option.value === value);
  return result?.name;
};

const CountryDropdownMultiple = ({
  onChange,
  options,
  title,
  value,
  required,
  disabled,
  readonly,
  name,
  error,
  placeholder,
  labelId,
  id,
  className,
  variant,
  sx,
  hasFlag,
  touched,
  setFieldTouched,
}: Props) => {
  const [listOpen, setListOpen] = React.useState(false);
  const [anchorElSender, setAnchorElSender] = React.useState<null | HTMLElement>(null);

  let isError = false;
  let isTouched = false;
  if (error[name]) {
    isError = true;
  }
  const multipleValue = value.length > 0 ? value.split(",") : [];

  if (touched?.[name]) {
    isTouched = true;
  }

  const handOnClose = () => {
    setListOpen(false);
    document.getElementById(id || "")?.blur();
    // setFieldTouched ? setTimeout(() => setFieldTouched(name, true)) : null
  };

  const handleSelect = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setListOpen(true);
    setFieldTouched?.(name, true);
  };

  const handleClick = (event: React.BaseSyntheticEvent<HTMLElement> | any, value: string) => {
    event.stopPropagation();
    const newValues = typeof value === "string" ? value.split(",") : value;
    let selectedValues = [...multipleValue];

    newValues.forEach((newValue) => {
      if (newValue === "All" && !selectedValues.includes(newValue)) {
        selectedValues = ["All"];
      } else if (!selectedValues.includes(newValue)) {
        selectedValues = selectedValues.filter((i) => i !== "All");
        selectedValues.push(newValue);
      } else {
        const index = selectedValues.indexOf(newValue);
        if (index > -1) {
          selectedValues.splice(index, 1);
        }
      }
    });

    const setValues = selectedValues.length > 0 ? selectedValues.join() : "";
    onChange(name, setValues);
    handOnClose();
  };

  const handleOptionClick = (event: React.BaseSyntheticEvent<HTMLElement> | any, value: string) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedValues = multipleValue.filter((val) => val !== value);
    const setValues = selectedValues.length > 0 ? selectedValues.join() : "";
    onChange(name, setValues);
  };

  useEffect(() => {
    setAnchorElSender(document.getElementById(id || ""));
  }, []);
  return (
    <>
      <SelectWrapper className={className} variant={variant || "outlined"} sx={sx}>
        <CustomLabel required={required} id={labelId}>
          {title}
        </CustomLabel>
        <Select
          autoFocus={false}
          labelId={labelId}
          multiple
          name={name}
          value={multipleValue}
          id={id}
          disabled={disabled}
          onOpen={handleSelect}
          placeholder={placeholder}
          required={required}
          readOnly={readonly}
          onClose={handOnClose}
          input={<CustomInput required={required} id={id} />}
          sx={{
            width: "100%",
            borderRadius: "0.5rem",
          }}
          label={title}
          error={isError && isTouched}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                zIndex: 5,
              }}
            >
              {selected.map((value, i) => (
                <Chip
                  key={i}
                  label={findAlabel(options, value)}
                  variant="outlined"
                  component={"span"}
                  onMouseDown={(e: React.BaseSyntheticEvent<any>) => {
                    handleOptionClick(e, value);
                  }}
                  onTouchStart={(e: React.BaseSyntheticEvent<any>) => {
                    handleOptionClick(e, value);
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        ></Select>
      </SelectWrapper>

      <AutoCompleteWithSearchBar
        open={listOpen}
        id={"sender-label"}
        optionLabel="fullText"
        anchorEl={anchorElSender}
        searchPlaceHolder="Search"
        noOptionsText="Country not found"
        options={options}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        getOptionLabel={(option) => `${option.name}`}
        handleClose={() => {
          handOnClose();
        }}
        renderOption={(_, option) => {
          return (
            <MenuItem
              key={option.value}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E6E8F0",
                },
              }}
              onClick={(e) => {
                handleClick(e, option.name);
              }}
            >
              <Box
                sx={{
                  gap: "0.3rem",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  padding: "3px",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                {hasFlag && option?.avatar && <span style={{ fontSize: "1.2rem" }}>{option.avatar}</span>}
                <Typography
                  sx={{
                    fontFamily: "SF Pro Display Medium",
                    color: "#171721",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  {`${option.name}`}
                </Typography>
              </Box>
            </MenuItem>
          );
        }}
      />

      {error[name] && touched && touched[name] && <Typography sx={{ color: "#A73636", mt: 1 }}>{error[name]}</Typography>}
    </>
  );
};

export default CountryDropdownMultiple;
