import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";

const BottomNav = ({
  handleBack,
  isPreview,
  loading,
  nextText = "Next",
}: {
  handleBack?: () => void;
  isPreview?: boolean;
  isOnlending?: boolean;
  loading?: boolean;
  nextText?: string;
}) => {
  return (
    <Box width="100%" sx={{ marginTop: "2rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {handleBack && (
            <Button
              variant="contained"
              sx={{
                padding: ".5rem 3rem",
                borderRadius: ".5rem",
                backgroundColor: "#ddd",
                "&:hover": {
                  backgroundColor: "#ddd",
                },
              }}
              onClick={() => {
                handleBack();
              }}
            >
              Previous
            </Button>
          )}
        </Box>
        <LoadingButton
          loading={loading}
          disabled={loading}
          type="submit"
          variant="contained"
          sx={{
            padding: ".5rem 3rem",
            borderRadius: ".5rem",
          }}
        >
          {isPreview ? "Preview" : nextText}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BottomNav;
