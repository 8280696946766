const CompareValues =
  (key: string, order = 'asc') =>
  (inputA: any, inputB: any) => {
    if (
      !Object.prototype.hasOwnProperty.call(inputA, key) ||
      !Object.prototype.hasOwnProperty.call(inputB, key)
    ) {
      // property doesn't exist on either objects
      return 0
    }

    const getMatchingOutput = (input: any) => {
      if (typeof input[key] === 'string') {
        return input[key].toUpperCase()
      } else if (typeof input[key]?.getMonth === 'function') {
        return new Date(input[key]).getTime()
      }
      return input[key]
    }

    const outputA = getMatchingOutput(inputA)
    const outputB = getMatchingOutput(inputB)
    let comparison: any

    if (outputA > outputB) {
      comparison = 1
    } else if (outputA < outputB) {
      comparison = -1
    }

    return order === 'desc' ? comparison * -1 : comparison
  }

export default CompareValues
