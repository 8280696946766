/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { type PropsWithChildren } from "react";
import * as Sentry from "@sentry/browser";
import { Button, Box, Typography } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import OnboardingNavbar from "layout/Onboarding/OnboardingNavbar";

type IProps = PropsWithChildren<{
  [index: string]: any;
  children: React.ReactNode;
}>;

interface IState {
  hasError?: boolean;
  eventId: any;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (error.toString().indexOf("ChunkLoadError") > -1) {
      window.location.reload();
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <Box>
          <OnboardingNavbar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "90vh",
              textAlign: "center",
              padding: "0 10px",
            }}
          >
            <img src="/static/reportCrash.svg" alt="logo" loading="lazy" />
            <Typography
              sx={{
                fontSize: "2rem",
                color: "#171721",
                padding: "1rem 0",
                fontWeight: 600,
              }}
            >
              Something went wrong
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                color: "#8083A3",
                padding: "1rem 0",
                fontWeight: 400,
              }}
            >
              Please try again or refresh the page. If issue persist, please reach out to us.
            </Typography>
            <Button
              type="button"
              variant="text"
              sx={{
                padding: "0.75rem 2.5rem",
                textTransform: "none",
                margin: "1rem 0",
                fontSize: "0.875rem",
                backgroundColor: "#fff",
                border: "2px solid #E5E5E5",
                color: "#000",
                boxShadow: "none",
                "&:hover": {
                  background: "#fff",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                Sentry.showReportDialog({
                  eventId: this.state.eventId,
                });
              }}
            >
              <MessageIcon sx={{ color: "#8F95B2", paddingRight: "0.5rem" }} />
              Message Support
            </Button>
          </Box>
        </Box>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}
