/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import { fetchFromTable } from "helper/dynamodb";
import { type companyTableData } from "interfaces/company";

const companyTable = process.env.REACT_APP_COMPANY_TABLE as string;

interface HomeState {
  companies: companyTableData[];
  fetchingCompanies: boolean;
}

const initialState: HomeState = {
  companies: [],
  fetchingCompanies: false,
};

export const fetchCompanyTableData = createAsyncThunk("companies/fetchCompanyTableData", async () => {
  try {
    const params = {
      ProjectionExpression: "id, createdAt, usageType, #Owner, companyKYCStatus, stage, headquartersCountry,headquartersCity,headquartersStreetName, legalName, companyQualified, ownedBankAccounts",
      ExpressionAttributeNames: { "#Owner": "owner" },
      TableName: companyTable,
    };

    const companies = (await fetchFromTable(params)) as companyTableData[];
    return companies;
  } catch (err: any) {
    console.log("conpanyFetchErr", err);
    ErrorHandler({ message: "Unable to fetch company data" });
    return [] as companyTableData[];
  }
});

// Then, handle actions in your reducers:
const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanyTableData: (
      state,
      action: PayloadAction<{
        data: companyTableData[];
      }>,
    ) => {
      state.companies = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyTableData.pending, (state) => {
      state.fetchingCompanies = true;
    });
    builder.addCase(fetchCompanyTableData.fulfilled, (state, action) => {
      state.companies = action.payload;
      state.fetchingCompanies = false;
    });
    builder.addCase(fetchCompanyTableData.rejected, (state, action) => {
      state.companies = [...state.companies, ...(action.payload as companyTableData[])];
      state.fetchingCompanies = false;
    });
  },
});

export const { setCompanyTableData } = companiesSlice.actions;

export default companiesSlice.reducer;
