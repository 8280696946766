/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type EntitiesByCurrencyQuery, type ListTextractRecordsQuery } from "API";
import { entitiesByCurrency, listTextractRecords } from "graphql/queries";
import { ErrorHandler } from "helper/Handlers";
import { type ITextractRecords } from "interfaces/statementParser";
import { type GraphQLResult } from "@aws-amplify/api-graphql";
import { API } from "aws-amplify";
import { type IEntities } from "interfaces/entity";

interface StatementParserState {
  records: ITextractRecords[];
  entities: IEntities[];
  fetchingRecords: boolean;
  fetchingEntities: boolean;
}

const initialState: StatementParserState = {
  records: [],
  fetchingRecords: false,
  entities: [],
  fetchingEntities: false,
};

export const fetchRecordsData = createAsyncThunk("statementParser/fetchRecords", async () => {
  try {
    const listTextractRecordsResponse = (await API.graphql({
      query: listTextractRecords,
    })) as GraphQLResult<ListTextractRecordsQuery>;

    return listTextractRecordsResponse.data?.listTextractRecords?.items as ITextractRecords[];
  } catch (err: any) {
    console.error("error fetching processed file", err?.message);
    console.error("error fetching processed file full", err);
    ErrorHandler({ message: "Unable to fetch users data" });
    return [] as ITextractRecords[];
  }
});

export const fetchEntitiesData = createAsyncThunk("statementParser/fetchEntities", async (currency: string) => {
  try {
    let totalEntities = [] as IEntities[];

    const getEntities = async (nextToken?: string) => {
      const listEntitiesResponse = (await API.graphql({
        query: entitiesByCurrency,
        variables: { currency, nextToken },
      })) as GraphQLResult<EntitiesByCurrencyQuery>;

      const entities = listEntitiesResponse.data?.entitiesByCurrency?.items || [];
      const cleanedEntities = entities.filter((entity) => !!entity);
      totalEntities = [...totalEntities, ...(cleanedEntities as unknown as IEntities[])];

      const next = listEntitiesResponse?.data?.entitiesByCurrency?.nextToken as string | null;
      if (next) {
        await getEntities(next);
      }
    };

    await getEntities();

    return totalEntities;
  } catch (err: any) {
    console.error("error fetching processed file", err?.message);
    console.error("error fetching processed file full", err);
    ErrorHandler({ message: "Unable to fetch entities" });
    return [] as IEntities[];
  }
});

// Then, handle actions in your reducers:
const statementParserSlice = createSlice({
  name: "statementParser",
  initialState,
  reducers: {
    updateRecordsTableData: (
      state,
      action: PayloadAction<{
        data: ITextractRecords[];
      }>,
    ) => {
      state.records = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecordsData.pending, (state) => {
      state.fetchingRecords = true;
    });
    builder.addCase(fetchRecordsData.fulfilled, (state, action) => {
      state.records = action.payload || [];
      state.fetchingRecords = false;
    });
    builder.addCase(fetchRecordsData.rejected, (state, action) => {
      state.records = action.payload as ITextractRecords[];
      state.fetchingRecords = false;
    });
    builder.addCase(fetchEntitiesData.pending, (state) => {
      state.fetchingEntities = true;
    });
    builder.addCase(fetchEntitiesData.fulfilled, (state, action) => {
      state.entities = action.payload || [];
      state.fetchingEntities = false;
    });
    builder.addCase(fetchEntitiesData.rejected, (state, action) => {
      state.entities = action.payload as ITextractRecords[];
      state.fetchingEntities = false;
    });
  },
});

export const { updateRecordsTableData } = statementParserSlice.actions;

export default statementParserSlice.reducer;
