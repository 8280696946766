import axios, { AxiosHeaders } from "axios";

interface IAxiosRequest<V> {
  query: string;
  variables?: V;
  method?: "POST" | "PUT" | "GET" | "PATCH" | "put" | "post" | "get" | "patch";
}

const url = process.env.REACT_APP_ATLAS_ENDPOINT as string;
const apiKey = process.env.REACT_APP_ATLAS_API_KEY as string;

const myHeaders = new AxiosHeaders();

myHeaders.set("Content-Type", "application/json");
myHeaders.set("x-api-key", apiKey);

const requestObject = <V>({ query, variables, method = "POST" }: IAxiosRequest<V>) => {
  const basicRequestOptions = {
    data: { query, variables },
    method,
    headers: myHeaders,
    url,
  };

  return basicRequestOptions;
};

const makeAPI = async <Req, Res>(props: IAxiosRequest<Req>) => {
  const response = await axios<{
    data: Res;
  }>(requestObject(props));

  return {
    data: response.data.data,
    status: response.status,
  };
};

export { makeAPI };
