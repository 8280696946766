import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { listPeople, listCampaigns, listCampaignsMessageStatus, listProducts, listAllEntities } from "atlasGraphql/atlas-queries";
import { makeAPI } from "hooks/useAtlasAxios";
import { type IPerson, type IEntity, type IProduct } from "interfaces/atlas";
import { type ICampaignLogData, type ICampaigns, type ICampaignMessageStatus } from "interfaces/campaignBuilder";

interface IFetchPersons {
  listPeople: IPerson[];
}

interface IFetchEntities {
  listEntities: IEntity[];
}

interface IFetchCampaigns {
  listCampaigns: ICampaigns[];
}

interface IFetchCampaignMessageStatuses {
  listCampaignsMessageStatus: ICampaignMessageStatus[];
}

interface IFetchProducts {
  listProducts: IProduct[];
}

interface CampaignState {
  people: IPerson[];
  loading: boolean;
  loadingCampaigns: boolean;
  loadingCampaignsMessageStatus: boolean;
  entities: IEntity[];
  campaigns: ICampaigns[];
  products: IProduct[];
  campaignMessageStatus: ICampaignMessageStatus[];
  createCampaignDetails: ICampaignLogData;
}

const initialState: CampaignState = {
  people: [],
  entities: [],
  campaigns: [],
  products: [],
  createCampaignDetails: {},
  campaignMessageStatus: [],
  loading: false,
  loadingCampaigns: false,
  loadingCampaignsMessageStatus: false,
};

export const fetchEntities = createAsyncThunk("campaign/fetchEntities", async ({ filters }: { filters?: any }) => {
  try {
    const entities = await makeAPI<any, IFetchEntities>({
      query: listAllEntities,
      variables: {
        orderBy: { createdOn: "DESC" },
        where: filters?.where || {},
      },
    });

    return entities.data.listEntities ?? [];
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchProducts = createAsyncThunk("campaign/fetchProducts", async () => {
  try {
    const products = await makeAPI<any, IFetchProducts>({
      query: listProducts,
      variables: {
        orderBy: { name: "ASC" },
      },
    });
    return products.data.listProducts;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchPersons = createAsyncThunk("campaign/fetchPersons", async () => {
  try {
    const persons = await makeAPI<any, IFetchPersons>({
      query: listPeople,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return persons.data.listPeople;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchCampaigns = createAsyncThunk("campaign/fetchCampaigns", async () => {
  try {
    const entities = await makeAPI<any, IFetchCampaigns>({
      query: listCampaigns,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });

    return entities.data.listCampaigns;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const fetchCampaignsMessageStatus = createAsyncThunk("campaign/fetchCampaignMessageStatus", async () => {
  try {
    const entities = await makeAPI<any, IFetchCampaignMessageStatuses>({
      query: listCampaignsMessageStatus,
      variables: {
        orderBy: { createdOn: "DESC" },
      },
    });
    return entities.data.listCampaignsMessageStatus;
  } catch (err) {
    console.log(err);
    return [];
  }
});

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCreateCampaignDetails: (
      state,
      action: PayloadAction<{
        data: ICampaignLogData;
      }>,
    ) => {
      state.createCampaignDetails = { ...state.createCampaignDetails, ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEntities.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchEntities.rejected, (state, action) => {
      state.entities = [...state.entities, ...(action.payload as IEntity[])];
      state.loading = false;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products = [...state.products, ...(action.payload as IProduct[])];
      state.loading = false;
    });
    builder.addCase(fetchPersons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPersons.fulfilled, (state, action) => {
      state.people = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchPersons.rejected, (state, action) => {
      state.people = [...state.people, ...(action.payload as IPerson[])];
      state.loading = false;
    });
    builder.addCase(fetchCampaigns.pending, (state) => {
      state.loadingCampaigns = true;
    });
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload;
      state.loadingCampaigns = false;
    });
    builder.addCase(fetchCampaigns.rejected, (state, action) => {
      state.campaigns = [...state.campaigns, ...(action.payload as ICampaigns[])];
      state.loadingCampaigns = false;
    });
    builder.addCase(fetchCampaignsMessageStatus.pending, (state) => {
      state.loadingCampaignsMessageStatus = true;
    });
    builder.addCase(fetchCampaignsMessageStatus.fulfilled, (state, action) => {
      state.campaignMessageStatus = action.payload;
      state.loadingCampaignsMessageStatus = false;
    });
    builder.addCase(fetchCampaignsMessageStatus.rejected, (state, action) => {
      state.campaignMessageStatus = [...state.campaignMessageStatus, ...(action.payload as ICampaignMessageStatus[])];
      state.loadingCampaignsMessageStatus = false;
    });
  },
});

export const { setCreateCampaignDetails } = campaignSlice.actions;

export default campaignSlice.reducer;
