import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { StepperNav } from "interfaces/atlas";
import { saveTrade } from "services/atlas";
import { NewTradeContext } from "./newTradeContext";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import Input from "components/Input";
import BottomNav from "./bottomNav";
import CountryDropdown from "components/CountryDropdown";
import countryList from "data/countryAndStates";
import CompareValues from "helper/CompareValues";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface FormValues {
  originCountry: string;
  originPort: string;
  destinationCountry: string;
  destinationPort: string;
  shippingArrivalDate: string;
  shippingDurationDate: string;
  buyerPaymentTerms: string;
  buyerIncomeTerms: string;
  shippingMode: string;
  shippingLine: string;
  freightForwarderId: string;
}

const initialValues: FormValues = {
  originCountry: "",
  originPort: "",
  destinationCountry: "",
  destinationPort: "",
  shippingArrivalDate: "",
  shippingDurationDate: "",
  buyerPaymentTerms: "",
  buyerIncomeTerms: "",
  shippingMode: "",
  shippingLine: "",
  freightForwarderId: "",
};

const NewTradeOverview = ({ handleNext }: StepperNav) => {
  const { trade } = useContext(NewTradeContext);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async ({ originCountry, originPort, destinationCountry, destinationPort }) => {
      try {
        setLoading(true);
        if (trade?.id) {
          await saveTrade(trade.id, {
            originCountry,
            originPort,
            destinationCountry,
            destinationPort,
          });
          handleNext();
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
  });

  const { handleBlur, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Trade Overview
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={6}>
            <FormLabel htmlFor="originCountry">Origin Country</FormLabel>
            <CountryDropdown
              name="originCountry"
              label=""
              options={countryList
                .map(({ country }) => {
                  return {
                    name: country,
                    value: country,
                  };
                })
                .sort(CompareValues("name", "asc"))}
              value={formik.values.originCountry}
              errors={formik.errors}
              touched={formik.touched}
              noImage={true}
              setFieldValue={formik.setFieldValue}
              hasMargin={true}
              sx={{
                "& .MuiFormControl-root, & .MuiFilledInput-root": {
                  width: "100%",
                  mt: ".3rem",
                  borderRadius: "7px",
                },
                "& .MuiFilledInput-input": {
                  padding: "15px 8px",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="originPort" className="m-0 p-0">
              Origin Port
            </FormLabel>
            <Input
              type="text"
              id="originPort"
              error={errors}
              margin="normal"
              name="originPort"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              sx={{ mt: ".2rem", mb: 0 }}
              value={formik.values.originPort}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Origin Port
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="destinationCountry">Destination Country</FormLabel>
            <CountryDropdown
              name="destinationCountry"
              label=""
              options={countryList
                .map(({ country }) => {
                  return {
                    name: country,
                    value: country,
                  };
                })
                .sort(CompareValues("name", "asc"))}
              value={formik.values.destinationCountry}
              errors={formik.errors}
              touched={formik.touched}
              noImage={true}
              setFieldValue={formik.setFieldValue}
              hasMargin={true}
              sx={{
                "& .MuiFormControl-root, & .MuiFilledInput-root": {
                  width: "100%",
                  mt: ".3rem",
                  borderRadius: "7px",
                },
                "& .MuiFilledInput-input": {
                  padding: "15px 8px",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="destinationPort">Destination Port</FormLabel>
            <Input
              type="text"
              id="destinationPort"
              error={errors}
              margin="normal"
              sx={{ mt: ".2rem", mb: 0 }}
              name="destinationPort"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.destinationPort}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Destination Port
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="shippingDurationDate">Shipment Departure Date</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(formik.values.shippingDurationDate || null)}
                format="DD/MM/YYYY"
                sx={{
                  width: "100%",
                  borderRadius: "0.5rem",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    borderRadius: "0.5rem",
                    padding: "9px 8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E4E6E8",
                    borderColor: "#E4E6E8!important",
                  },
                  "& .MuiInputBase-input": {
                    color: "#8083A3",
                    padding: "9px",
                    borderRadius: "0.5rem",
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue(`shippingDurationDate`, e?.toISOString());
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="shippingArrivalDate">Shipment Arrival Date</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(formik.values.shippingArrivalDate || null)}
                format="DD/MM/YYYY"
                sx={{
                  width: "100%",
                  borderRadius: "0.5rem",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    borderRadius: "0.5rem",
                    padding: "9px 8px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #E4E6E8",
                    borderColor: "#E4E6E8!important",
                  },
                  "& .MuiInputBase-input": {
                    color: "#8083A3",
                    padding: "9px",
                    borderRadius: "0.5rem",
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue(`shippingArrivalDate`, e?.toISOString());
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="buyerPaymentTerms">Buyer Payment Terms</FormLabel>
            <Input
              multiline
              type="text"
              id="buyerPaymentTerms"
              error={errors}
              margin="normal"
              sx={{ mt: ".2rem", mb: 0 }}
              name="buyerPaymentTerms"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.buyerPaymentTerms}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Buyer Payment Terms
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="buyerIncomeTerms">Buyer Inco Terms</FormLabel>
            <Input
              multiline
              type="text"
              id="buyerIncomeTerms"
              error={errors}
              margin="normal"
              sx={{ mt: ".2rem", mb: 0 }}
              name="buyerIncomeTerms"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.buyerIncomeTerms}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Buyer Inco Terms
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="shippingMode">Shipping Mode</FormLabel>
            <Input
              multiline
              type="text"
              id="shippingMode"
              error={errors}
              sx={{ mt: ".2rem", mb: 0 }}
              margin="normal"
              name="shippingMode"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.shippingMode}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Shipping Mode
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="shippingLine">Shipping Line</FormLabel>
            <Input
              multiline
              type="text"
              id="shippingLine"
              error={errors}
              margin="normal"
              sx={{ mt: ".2rem", mb: 0 }}
              name="shippingLine"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.shippingLine}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Shipping Line
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel htmlFor="freightForwarderId">Freight Forwarder ID</FormLabel>
            <Input
              multiline
              type="text"
              id="freightForwarderId"
              error={errors}
              margin="normal"
              name="freightForwarderId"
              onBlur={handleBlur}
              sx={{ mt: ".2rem", mb: 0 }}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.freightForwarderId}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Freight Forwarder ID
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
        <BottomNav loading={loading} />
      </Box>
    </Box>
  );
};

export default NewTradeOverview;
