/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ErrorHandler } from "helper/Handlers";
import { fetchFromTable } from "helper/dynamodb";
import { type IUserData } from "interfaces/home";

const userTable = process.env.REACT_APP_USER_TABLE as string;

interface HomeState {
  users: IUserData[];
  fetchingUsers: boolean;
}

const initialState: HomeState = {
  users: [],
  fetchingUsers: false,
};

export const fetchUserTableData = createAsyncThunk("users/fetchUserTableData", async () => {
  try {
    const params = {
      TableName: userTable,
    };

    const users = (await fetchFromTable(params)) as IUserData[];
    return users;
  } catch (err: any) {
    console.log("userFetchErr", err);
    ErrorHandler({ message: "Unable to fetch users data" });
    return [] as IUserData[];
  }
});

// Then, handle actions in your reducers:
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserTableData: (
      state,
      action: PayloadAction<{
        data: IUserData[];
      }>,
    ) => {
      state.users = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserTableData.pending, (state) => {
      state.fetchingUsers = true;
    });
    builder.addCase(fetchUserTableData.fulfilled, (state, action) => {
      state.users = action.payload;
      state.fetchingUsers = false;
    });
    builder.addCase(fetchUserTableData.rejected, (state, action) => {
      state.users = action.payload as IUserData[];
      state.fetchingUsers = false;
    });
  },
});

export const { setUserTableData } = usersSlice.actions;

export default usersSlice.reducer;
