import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals'
import AuthProvider from "utils/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import store from "redux/store";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import awsExports from "./amplifyconfiguration.json";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { type Integration } from "@sentry/types";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const envIndex = +(process.env.REACT_APP_ENV_ID || 0);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, devRedirectSignIn, falconDevRedirectSignIn] = awsExports.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, devRedirectSignOut, falconDevRedirectSignOut] = awsExports.oauth.redirectSignOut.split(",");

const updatedAwsExports = {
  ...awsExports,
  authenticationFlowType: "CUSTOM_AUTH",
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: envIndex === 1 ? devRedirectSignIn : envIndex === 2 ? falconDevRedirectSignIn : localRedirectSignIn,
    redirectSignOut: envIndex === 1 ? devRedirectSignOut : envIndex === 2 ? falconDevRedirectSignOut : localRedirectSignOut,
  },
};

Amplify.configure(updatedAwsExports);

const sentryIntegrations: Integration[] | ((integrations: Integration[]) => Integration[]) | undefined = [new BrowserTracing() as never];
if (process.env.REACT_APP_NODE_ENV === "production") {
  sentryIntegrations.push(
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  );
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: sentryIntegrations,
  environment: process.env.REACT_APP_NODE_ENV ?? "development",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
