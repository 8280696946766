/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable */
import React, { lazy } from "react";
import Loading from "components/Loading";
import DashboardLayout from "layout/Dashboard/DashboardLayout";
import AuthGuard from "utils/AuthGuard";

// Routes Imports
import { AuthenticatedGeneralChildren, GeneralChildren, OnboardingChildren } from "./GeneralRoutes";
import { AuthChildren } from "./authRoutes";
const RequestAccess = lazy(() => import("pages/permissions/RequestAccess"));
const AuthenticationLayout = lazy(() => import("layout/Authentication/AuthenticationLayout"));
const OnboardingLayout = lazy(() => import("layout/Onboarding/OnboardingLayout"));

const routes = [
  {
    path: "*",
    element: (
      <Loading>
        <OnboardingLayout />
      </Loading>
    ),
    children: [...GeneralChildren],
  },
  {
    path: "auth",
    element: (
      <Loading>
        <AuthenticationLayout />
      </Loading>
    ),
    children: [...AuthChildren],
  },
  {
    path: "onboarding",
    element: (
      <Loading>
        <OnboardingLayout />
      </Loading>
    ),
    children: [...OnboardingChildren],
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <Loading>
          <DashboardLayout />
        </Loading>
      </AuthGuard>
    ),
    children: [...AuthenticatedGeneralChildren],
  },
  {
    path: "request-access",
    element: (
      <Loading>
        <DashboardLayout />
      </Loading>
    ),
    children: [
      {
        path: "",
        element: <RequestAccess />,
      },
    ],
  },
];

export default routes;
