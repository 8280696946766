import { type Account } from "./account";

export interface companyTableData {
  id: string;
  createdAt: string;
  usageType: string;
  owner: string;
  companyKYCStatus: string;
  stage: string;
  headquartersCountry: string;
  headquartersCity: string;
  headquartersStreetName: string;
  legalName: string;
  companyQualified: boolean;
}

export interface CompanyData {
  id?: string;
  name?: string;
  legalName?: string;
  industryType?: string;
  country?: string;
  city?: string;
  website?: string;
  businessDescription?: string;
  typeOfCompany?: string;
  numberOfFullTimeEmployees?: number | string;
  numberOfPartTimeEmployees?: number | string;
  annualTurnover?: number | string;
  incorporationYear?: string;
  haveBeenPartOfStartupProgram?: string;
  haveAVentureCapitalInvestore?: string;
  totalEquityRaised?: number | string;
  headquartersStreetName?: string;
  headquartersPostalCode?: string;
  headquartersCountry?: string;
  headquartersCity?: string;
  isHeadQuartersSameAsAddress?: boolean;
  operatingAddressStreetName?: string;
  operatingAddressPostalCode?: string;
  operatingAddressCity?: string;
  operatingAddressCountry?: string;
  operatingAddressCountriesOfOperation?: string;
  typesOfCustomers?: string;
  signUpAverageMonthlyRevenue?: number;
  signUpCreditCurrency?: string;
  signUpCreditAmount?: number;
  treasuryNeeds?: string | string[];
  ownedBankAccounts?: string | string[];
  proofOfAddressType?: string;
  proofOfAddress?: string;
  baseCurrency?: string;
  currencyOverride?: string;
  uploadCapTableFileName?: string;
  countriesWhereCompanyHaveAccount?: string | string[];
  createdAt?: string;
  updatedAt?: string;
  owner?: string;
  stage?: CompanyStage;
  companyKYCStatus?: CompanyKYCStatus;
  companyDocuments?: CompanyDocuments[];
  ownershipDetails?: OwnershipDetails[];
  rules?: RulesInfo[];
  creditWaitlist?: boolean;
  accountingPlatforms?: string | string[];
  config?: CompanyConfig;
  annualCurrency?: AnnualCurrency;
  sendPaymentGatewayMail?: string;
  usageType?: UsageTypeOptions;
  commissions?: Commissions;
  loanDisbursement?: LoanDisbursement[];
  autoDebit?: AutoDebitData;
  accounts?: Account[];
  kycApprovalTime?: string;
  companyContacted?: boolean | string;
  companyQualified?: boolean | string;
}

export interface LoanDisbursement {
  city: string;
  bankName: string;
  swiftCode: string;
  streetName: string;
  bankCountry: string;
  accountType: string;
  accountNumber: string;
  beneficiaryName: string;
  beneficiaryAddress: string;
  correspondentBankName: string;
  correspondenceSwiftCode: string;
}

export interface Commissions {
  usdtongn: CommissionsTypeModel;
  ngntousd: CommissionsTypeModel;
  ghstousd: CommissionsTypeModel;
  usdtoghs: CommissionsTypeModel;
}

export interface CommissionsTypeModel {
  feePercentage: number;
  discountPercentage: number;
  maxAmountForFlatFee: number;
  feeAmountForFlatFee: number;
  currency: string;
  minimumExchangeAmount: number;
  maximumExchangeAmount: number;
}

export interface AnnualCurrency {
  countryName: string;
  isoAlpha2: string;
  isoAlpha3: string;
  currencyCode: string;
  symbol: string;
  dial_code: string;
}

export interface CompanyDocuments {
  id?: string;
  name?: string;
  fileUrl?: string;
  fileName?: string;
}

export interface CompanyDetails {
  companyName?: string;
  companyRegistrationId?: string;
  proofOfCompanyRegistration?: string;
  proofOfCompanyRegistrationFileName?: string;
}

export interface CompanyAddress {
  country?: string;
  city?: string;
  streetName?: string;
  postCode?: string;
}

export interface KeyCompanyDirector {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
}

export interface PersonalInfo {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
}

export interface PersonalDetails {
  dateOfBirth?: string;
  position?: string;
  phoneNumber?: string;
  nationality?: string;
  gender?: string;
  bvn?: string;
}

export interface IdentificationDetails {
  idType?: string;
  idNumber?: string;
  idUpload?: string;
  idUploadFileName?: string;
  hasHeldOffice?: string;
  additionalDetails?: string;
}

export interface OwnershipDetails {
  id?: string;
  ownerType: OwnerType;
  ownerPercentage: number;
  fillOwnershipStatus: FillOwnershipStatus;
  companyDetails: CompanyDetails;
  companyAddress: CompanyAddress;
  keyCompanyDirector: KeyCompanyDirector;
  personalInfo: PersonalInfo;
  personalDetails: PersonalDetails;
  identificationDetails: IdentificationDetails;
  status: OwnershipDetailsStatus;
}

export interface CompanyConfig {
  feePercentage: number;
  discountPercentage: number;
  minimumExchangeAmountInUsd: number;
  maximumExchangeAmountInUsd: number;
}

export enum OwnerType {
  entity = "entity",
  individual = "individual",
}

enum FillOwnershipStatus {
  manual = "manual",
  request = "request",
}

export enum CompanyKYCStatus {
  inprogress = "inprogress",
  cancelled = "cancelled",
  pending = "pending",
  denied = "denied",
  approved = "approved",
  empty = "",
}

export enum CompanyStage {
  currentlyOnboarding = "currentlyOnboarding",
  completedOnboarding = "completedOnboarding",
}

export enum OwnershipDetailsStatus {
  requested = "requested",
  submitted = "submitted",
}

export enum UsageTypeOptions {
  Active = "Active",
  Test = "Test",
  Exclude = "Exclude",
}

export interface RulesInfo {
  userID: string;
  index: number;
  key: number;
  name: string;
  priority: number;
  description: string;
  accounts: string;
  transferType: string;
  category: string;
  subCategory: string;
  isFetchHistoricals: boolean;
  modifiedDate: string;
}

export interface AutoDebitData {
  __typename: "AutoDebitData";
  allow?: boolean | null;
  selectedAccountId?: string | null;
}

export interface IDocumentRequestData {
  id?: string;
  companyId?: string;
  category?: string;
  createdAt?: string;
  requestName?: string;
  requestedBy?: string;
  name?: string;
  note?: string;
  updatedAt?: string;
  status?: string;
}

export interface InvitationPayload {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  companyId: string;
  companyName: string;
  roleId: string;
  roleName: string;
}

export interface InvitationWithCompanyOwnerId extends InvitationPayload {
  companyOwnerId: string;
}

export enum InvitationTypes {
  createUser = "createUser",
  updatePassword = "updatePassword",
  updateUser = "updateUser",
  deleteUser = "deleteUser",
}

export interface sendMailPayload {
  showNotif: boolean;
  recipientMails: string[];
  mailSubject: string;
  mailBody: string;
  mailSender?: string;
  ccMails?: string[];
  bccMails?: string[];
  replyAddresses?: string[];
}
