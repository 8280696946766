/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const MCA = lazy(() => import("pages/mca/index"));

const AccountDetails = lazy(() => import("pages/mca/AccountDetails/index"));
const Transactions = lazy(() => import("pages/mca/Transactions/index"));

export const MCAChildren = [
  {
    path: "mc-accounts",
    element: <MCA />,
  },
  {
    path: "mc-accounts/:companyId",
    element: <AccountDetails />,
  },
  {
    path: "transactions",
    element: <Transactions />,
  },
];
