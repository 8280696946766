/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */

import React, { useEffect, useState } from "react";
import { Box, FormControl, SelectChangeEvent, Typography } from "@mui/material";
import { entryTypes } from "../helper";
import SelectInput from "components/SelectInput";
import { useFormik } from "formik";
import Input from "components/Input";
import FlunaButton from "components/FlunaButton";
import NewEntityStepper from "./newEntityStepper";
import { addEntity, retreiveEntity } from "services/atlas";
import useEntityContext from "./newEntityContext";
import { OptionType } from "types/dataGrouping";
import * as Yup from "yup";
import { ErrorHandler } from "helper/Handlers";
import InputAutoComplete from "components/CategorizationAutoCompleteInput";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchEntities } from "redux/atlasSlice";
import { useNavigate } from "react-router-dom";
import { blockUpdatesChecker } from "services/navigation";
import { FlunaModules } from "API";

interface NewEntityProps {
  entityType: string;
  handleInputChange: (e: SelectChangeEvent) => void;
}

interface FormValues {
  entityName: string;
  entityEmail: string;
  entityPhone: string;
}

const NewEntity = ({ handleInputChange, entityType }: NewEntityProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(2);
  const { entities } = useAppSelector((state) => state.atlas);
  const userModules = useAppSelector((state) => state.permissions.loggedInUserModules);
  const blockAction = blockUpdatesChecker(userModules, FlunaModules.atlas);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { Provider, handleEntityUpdate } = useEntityContext();

  const initialValues: FormValues = {
    entityName: "",
    entityEmail: "",
    entityPhone: "",
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const entity = await retreiveEntity({
          email: values.entityEmail,
        });

        if (entity?.id) {
          ErrorHandler({
            message: "An entity already exists with that email address",
          });
        } else {
          const result = await addEntity({
            email: values.entityEmail,
            name: values.entityName,
            phoneNumber: values.entityPhone,
          });
          if (result?.status === 200) {
            handleEntityUpdate(result.data.createEntity);
          }
          setSubmitted(true);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
    validationSchema: Yup.object({
      entityName: Yup.string().required("Entity name is required"),
      entityEmail: Yup.string().required("Entity email is required").email("Entity email is not valid"),
      entityPhone: Yup.string().required("Entity phone is required"),
    }),
  });

  const { errors, touched, handleChange, handleSubmit, handleBlur } = formik;

  useEffect(() => {
    dispatch(
      fetchEntities({
        rowsPerPage: 10000000,
        page: 0,
        filters: {},
      }),
    );
  }, []);

  return (
    <>
      {submitted ? (
        <Box>
          <Provider>
            <NewEntityStepper activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />
          </Provider>
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: "40rem",
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: "2rem",
            }}
          >
            New Entry
          </Typography>
          <Box>
            <SelectInput
              name="entryType"
              id="entryType"
              options={entryTypes}
              labelId="entryType"
              value={entityType}
              onChange={handleInputChange}
              placeholder="Select Entry Type"
              title={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Entry Type
                    <Box
                      component="span"
                      sx={{
                        color: "#FF1300",
                      }}
                    >
                      *
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              sx={{
                mt: 1,
              }}
            >
              {/* <Input
                type="text"
                id="entityName"
                error={errors}
                margin="normal"
                name="entityName"
                onBlur={handleBlur}
                required={false}
                fullWidth={false}
                touched={touched}
                value={formik.values.entityName}
                onChange={handleChange}
                label={
                  <Box>
                    <Typography
                      sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Entity Name
                      <Box
                        component="span"
                        sx={{
                          color: "#FF1300",
                        }}
                      >
                        *
                      </Box>
                    </Typography>
                  </Box>
                }
              /> */}
              <InputAutoComplete
                id="entityName"
                name="Entity Name"
                type="text"
                fullWidth={true}
                autoFocus={true}
                label="Entity Name"
                touched={touched}
                freelyCreate={true}
                onBlur={handleBlur}
                error={errors}
                value={formik.values.entityName}
                options={(entities || []).map((entity) => ({
                  name: entity.name,
                  value: entity.id,
                  title: entity.name,
                }))}
                sx={{
                  "& .MuiFilledInput-root": {
                    paddingTop: "1rem",
                    paddingBottom: ".7rem",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "16px",
                  },
                }}
                onChange={(_, newValue: string | OptionType) => {
                  if (typeof newValue === "string") {
                    formik.setFieldValue("entityName", newValue);
                  } else if (newValue && newValue.value && newValue.newOption) {
                    formik.setFieldValue("entityName", newValue.value);
                  } else if (newValue && newValue.value) {
                    navigate(`/atlas/company/${newValue.value}`);
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Input
                type="email"
                id="entityEmail"
                error={errors}
                margin="normal"
                name="entityEmail"
                onBlur={handleBlur}
                required={false}
                fullWidth={false}
                touched={touched}
                value={formik.values.entityEmail}
                onChange={handleChange}
                label={
                  <Box>
                    <Typography
                      sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Entry Email Address
                      {/* <Box
                        component="span"
                        sx={{
                          color: "#FF1300",
                        }}
                      >
                        *
                      </Box> */}
                    </Typography>
                  </Box>
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Input
                type="text"
                id="entityPhone"
                error={errors}
                margin="normal"
                name="entityPhone"
                onBlur={handleBlur}
                required={false}
                fullWidth={false}
                touched={touched}
                value={formik.values.entityPhone}
                onChange={handleChange}
                label={
                  <Box>
                    <Typography
                      sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Entry Phone Number
                      {/* <Box
                        component="span"
                        sx={{
                          color: "#FF1300",
                        }}
                      >
                        *
                      </Box> */}
                    </Typography>
                  </Box>
                }
              />
            </FormControl>
            <Box
              sx={{
                mt: "4rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              ></Box>
              <FlunaButton type="submit" blockAction={blockAction} loading={loading} text="Next" />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default NewEntity;
