import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { StepperNav } from "interfaces/atlas";
import { saveTrade } from "services/atlas";
import { NewTradeContext } from "./newTradeContext";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { units } from "pages/atlas/company/helper";
import { NumberFormatBase } from "react-number-format";
import { currencies } from "pages/atlas/person/helpers/columns";
import BottomNav from "./bottomNav";
import SelectInput from "components/SelectInput";
import Input from "components/Input";

interface FormValues {
  rawMaterialCostAmount: string;
  rawMaterialCostCurrency: string;
  rawMaterialCostUnit: string;
  materialPreparingCostAmount: string;
  materialPreparingCostCurrency: string;
  materialPreparingCostUnit: string;
  logisticsCostAmount: string;
  logisticsCostCurrency: string;
  logisticsCostUnit: string;
  documentationCostAmount: string;
  documentationCostCurrency: string;
  documentationCostUnit: string;
  freightCostAmount: string;
  freightCostCurrency: string;
  freightCostUnit: string;
  customsCostAmount: string;
  customsCostCurrency: string;
  customsCostUnit: string;
}

const initialValues: FormValues = {
  rawMaterialCostAmount: "",
  rawMaterialCostCurrency: "",
  rawMaterialCostUnit: "",
  materialPreparingCostAmount: "",
  materialPreparingCostCurrency: "",
  materialPreparingCostUnit: "",
  logisticsCostAmount: "",
  logisticsCostCurrency: "",
  logisticsCostUnit: "",
  documentationCostAmount: "",
  documentationCostCurrency: "",
  documentationCostUnit: "",
  freightCostAmount: "",
  freightCostCurrency: "",
  freightCostUnit: "",
  customsCostAmount: "",
  customsCostCurrency: "",
  customsCostUnit: "",
};

const NewTradeSupplier = ({ handleBack, handleNext }: StepperNav) => {
  const { trade } = useContext(NewTradeContext);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (trade?.id) {
          await saveTrade(trade.id, {
            rawMaterialCostAmount: values.rawMaterialCostAmount || 0,
            rawMaterialCostCurrency: values.rawMaterialCostCurrency,
            rawMaterialCostUnit: values.rawMaterialCostUnit,
            materialPreparingCostAmount: values.materialPreparingCostAmount || 0,
            materialPreparingCostCurrency: values.materialPreparingCostCurrency,
            materialPreparingCostUnit: values.materialPreparingCostUnit,
            logisticsCostAmount: values.logisticsCostAmount || 0,
            logisticsCostCurrency: values.logisticsCostCurrency,
            logisticsCostUnit: values.logisticsCostUnit,
            documentationCostAmount: values.documentationCostAmount || 0,
            documentationCostCurrency: values.documentationCostCurrency,
            documentationCostUnit: values.documentationCostUnit,
            customsCostAmount: values.customsCostAmount || 0,
            customsCostCurrency: values.customsCostCurrency,
            customsCostUnit: values.customsCostUnit,
            freightCostAmount: values.freightCostAmount || 0,
            freightCostUnit: values.freightCostUnit,
            freightCostCurrency: values.freightCostCurrency,
          });
          handleNext();
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
  });

  const { handleSubmit } = formik;

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Supplier Cost
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Raw Material Cost Amount</FormLabel>
            <NumberFormatBase
              required={false}
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("rawMaterialCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              fullWidth={true}
              id={`rawMaterialCostAmount`}
              displayType="input"
              name={`rawMaterialCostAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.rawMaterialCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Raw Material Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.rawMaterialCostCurrency}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`rawMaterialCostCurrency`}
              options={currencies}
              name={`rawMaterialCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Raw Material Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.rawMaterialCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`rawMaterialCostUnit`}
              options={units}
              name={`rawMaterialCostUnit`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Material Preparing Cost Amount</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              id={`materialPreparingCostAmount`}
              displayType="input"
              name={`materialPreparingCostAmount`}
              customInput={Input}
              decimalSeparator="."
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("materialPreparingCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.materialPreparingCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Material Preparing Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.materialPreparingCostCurrency}
              labelId={`materialPreparingCostCurrency`}
              options={currencies}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              name={`materialPreparingCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Material Preparing Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.materialPreparingCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`materialPreparingCostUnit`}
              options={units}
              name={`materialPreparingCostUnit`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Logistics Cost Amount</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              id={`logisticsCostAmount`}
              displayType="input"
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("logisticsCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              name={`logisticsCostAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.logisticsCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Logistics Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.logisticsCostCurrency}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`logisticsCostCurrency`}
              options={currencies}
              name={`logisticsCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Logistics Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.logisticsCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`logisticsCostUnit`}
              options={units}
              name={`logisticsCostUnit`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Documentation Cost Unit</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("documentationCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              id={`documentationCostAmount`}
              displayType="input"
              name={`documentationCostAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.documentationCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Documentation Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.documentationCostCurrency}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`documentationCostCurrency`}
              options={currencies}
              name={`documentationCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Documentation Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.documentationCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`documentationCostUnit`}
              options={units}
              name={`documentationCostUnit`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Freight Cost Amount</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              id={`freightCostAmount`}
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("freightCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              displayType="input"
              name={`freightCostAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.freightCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Freight Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.freightCostCurrency}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`freightCostCurrency`}
              options={currencies}
              name={`freightCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Freight Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.freightCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`freightCostUnit`}
              options={units}
              name={`freightCostUnit`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Customs Cost Amount</FormLabel>
            <NumberFormatBase
              required={false}
              fullWidth={true}
              id={`customsCostAmount`}
              onValueChange={(values) => {
                const { value } = values;

                formik.setFieldValue("customsCostAmount", value);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors}
              touched={formik.touched}
              displayType="input"
              name={`customsCostAmount`}
              customInput={Input}
              decimalSeparator="."
              allowNegative={false}
              isNumericString={false}
              thousandSeparator={true}
              fixedDecimalScale={true}
              allowEmptyFormatting={false}
              thousandsGroupStyle="thousand"
              value={formik.values.customsCostAmount || ""}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Customs Cost Currency</FormLabel>
            <SelectInput
              value={formik.values.customsCostCurrency}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`customsCostCurrency`}
              options={currencies}
              name={`customsCostCurrency`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <FormLabel>Customs Cost Unit</FormLabel>
            <SelectInput
              value={formik.values.customsCostUnit}
              onChange={formik.handleChange}
              error={formik.errors}
              touched={formik.touched}
              labelId={`customsCostUnit`}
              options={units}
              name={`customsCostUnit`}
            />
          </Grid>
        </Grid>
        <BottomNav handleBack={handleBack} loading={loading} />
      </Box>
    </Box>
  );
};

export default NewTradeSupplier;
