/* eslint-disable @typescript-eslint/no-empty-function */

import { IEntity } from "interfaces/atlas";
import React, { PropsWithChildren, createContext, useState } from "react";

export const NewEntityContext = createContext<{
  entity: IEntity | null;
  handleEntityUpdate: (entity: IEntity) => void;
}>({
  entity: null,
  handleEntityUpdate: () => {},
});

const useEntityContext = () => {
  const [entity, setEntity] = useState<IEntity | null>(null);

  const handleEntityUpdate = (entity: IEntity) => {
    setEntity(entity);
  };

  return {
    handleEntityUpdate,
    Provider: ({ children }: PropsWithChildren) => {
      return (
        <NewEntityContext.Provider
          value={{
            entity,
            handleEntityUpdate,
          }}
        >
          {children}
        </NewEntityContext.Provider>
      );
    },
  };
};

export default useEntityContext;
