/* eslint-disable @typescript-eslint/promise-function-async */
import React, { lazy } from "react";

const IPInvestments = lazy(() => import("pages/ipDashboard/investments"));
const IPInvestors = lazy(() => import("pages/ipDashboard/investors"));
const IPPayments = lazy(() => import("pages/ipDashboard/payments"));

export const IPChildren = [
  {
    path: "investments",
    element: <IPInvestments />,
  },
  {
    path: "investors",
    element: <IPInvestors />,
  },
  {
    path: "ip-payments",
    element: <IPPayments view={"Payments"} />,
  },
];
