import React from "react";
import { Autocomplete, type OutlinedInputProps, type SxProps, type TextFieldProps, type Theme, Typography, Box } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { type Modify } from "../utils/modify";
import ProfileAvatar from "./ProfileAvatar";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { v4 as uuidV4 } from "uuid";
import { CapitalizeSentenceFirstLetters } from "helper/textUtils";
import { type OptionsEntitiesType } from "types/dataGrouping";

// export interface OptionsEntitiesType {
//   id: string
//   title: string
//   value: string
//   associatedEntityType?: string
//   subtitle?: string
//   avatar?: string
//   newOption?: boolean
//   displayNewMessage?: string
//   disable?: boolean
//   disableValue?: string
// }

interface InputAutoCompleteProps {
  id: string;
  name: string;
  value: string;
  options: OptionsEntitiesType[];
  onChange: (event: React.SyntheticEvent, value: any) => void;
  type?: string;
  margin?: "none" | "dense" | "normal" | undefined;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  label?: string | React.ReactElement;
  onClose?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: Record<string, string>;
  touched?: Record<string, any>;
  sx?: SxProps<Theme> | undefined;
  ariaLabel?: string;
  classes?: Record<string, string>;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  autoFocus?: boolean;
  hasAvatar?: boolean;
  hasLogo?: boolean;
  freelyCreate?: boolean;
  loading?: boolean;
}

export type CustomTextFieldProps = Modify<InputAutoCompleteProps, TextFieldProps>;

const InputWrapper = styled("div")(() => ({
  textAlign: "left",
  position: "relative",
}));

const filter = createFilterOptions<OptionsEntitiesType>();

const checkOptionHasAvatar = (options: OptionsEntitiesType[], value: unknown): boolean => {
  return options.some((option) => option.title === value && option.avatar);
};
const checkOptionHasSubtitle = (options: OptionsEntitiesType[], value: unknown): boolean => {
  return options.some((option) => option.title === value && option.subtitle);
};
export const CustomInput = styled((props: CustomTextFieldProps) => (
  <TextField
    InputProps={
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      {
        disableUnderline: true,
      } as Partial<OutlinedInputProps>
    }
    {...props}
  />
))(({ theme, options, value, disabled }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: disabled ? "#F5F5FA !important" : "#fff !important",
    fontSize: "14px",
    // fontSize: '1rem',
    textTransform: "capitalize",
    overflow: "hidden",
    width: "100%",
    // fontWeight: "700",
    paddingTop: "12.55px",
    paddingBottom: checkOptionHasAvatar(options, value) ? "0.7rem" : "",
    paddingLeft: checkOptionHasAvatar(options, value) ? "2.8rem" : "",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    textAlign: "left",
    border: "1px solid #E4E6E8",
    borderRadius: "0.5rem",
    "&:before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&.Mui-focused": {
      borderRadius: "0.5rem",
      border: "1px solid #E77A0C",
      backgroundColor: "#fff",
    },
  },
  "&.MuiFormLabel-root MuiInputLabel-root.Mui-error": {
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  "& .MuiFilledInput-root .MuiFilledInput-input": {
    position: "relative",
    padding: "2px",
    textTransform: "capitalize",
    top: checkOptionHasAvatar(options, value) && checkOptionHasSubtitle(options, value) ? "-0.3rem" : checkOptionHasAvatar(options, value) ? "0" : "",
    paddingTop: checkOptionHasAvatar(options, value) ? "0.8rem !important" : "",
  },
  "& .MuiFormLabel-root": {
    paddingTop: checkOptionHasAvatar(options, value) ? "0.2rem" : "",
  },
  label: {
    color: "#8083A3",
    fontFamily: "SF Pro Display Medium",
    fontSize: "14px",
    // fontWeight: '700',
    lineHeight: "1rem",
    height: "18px",
    border: "none !important",
  },
  placeholder: {
    color: "#8083A3 !important",
    fontFamily: "SF Pro Display Medium",
    lineHeight: "18px",
    paddingBottom: "2px",
  },
}));

export const InputAutoComplete = (props: InputAutoCompleteProps) => {
  let error = false;
  let touched = false;
  const [value, setValue] = React.useState<OptionsEntitiesType | null>(null);
  if (props?.error?.[props.name]) {
    error = true;
  }
  if (props?.touched?.[props.name]) {
    touched = true;
  }

  React.useEffect(() => {
    const foundValue = props.options.find((option) => option.value === props.value);
    if (foundValue) {
      setValue(foundValue);
    } else {
      setValue({ id: uuidV4(), value: props.value, title: props.value, name: "", type: "", inflows: 0, outflows: 0, average: 0, count: 0 });
    }
  }, [props.value, props.options]);

  return (
    <Autocomplete
      onChange={props.onChange}
      value={value}
      onClose={props.onClose}
      loading={props.loading}
      filterOptions={(options, params) => {
        const updatedOptions = options.map((option) => {
          if (option.newOption) {
            return { ...option, newOption: false };
          }
          return option;
        });
        const filtered = filter(updatedOptions, params);
        const { inputValue } = params;
        const valueToUpdate = CapitalizeSentenceFirstLetters(inputValue);

        // Suggest the creation of a new value
        const isExisting = updatedOptions.some((option) => valueToUpdate === option.title);
        if (valueToUpdate !== "" && !isExisting && props.freelyCreate) {
          filtered.push({
            id: uuidV4(),
            value: valueToUpdate,
            title: valueToUpdate,
            displayNewMessage: `Create New  ${props.name} "${inputValue}"`,
            newOption: true,
            name: "",
            type: "",
            inflows: 0,
            outflows: 0,
            average: 0,
            count: 0,
          });
        }
        return filtered;
      }}
      selectOnFocus
      getOptionDisabled={(option) => option.disable || false}
      // clearOnBlur={!props.freelyCreate}
      handleHomeEndKeys
      sx={props.sx}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disabled={props.disabled}
      id={props.id}
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option ?? "";
        }
        // Add "xxx" option created dynamically
        if (option.value) {
          return option.title ?? "";
        }
        // Regular option
        return option.title ?? "";
      }}
      freeSolo={props.freelyCreate}
      renderInput={(params) => (
        <InputWrapper>
          <Box
            component="span"
            sx={{
              position: "absolute",
              top: "1.8rem",
              left: "0.8rem",
              zIndex: "99",
            }}
          >
            {props.hasLogo && value?.avatar && (
              <ProfileAvatar
                sx={{
                  width: "1.875rem",
                  height: "1.875rem",
                }}
                name={value.title}
                hasImage
                imageSrc={value.avatar}
              />
            )}
          </Box>
          <CustomInput
            {...params}
            variant="filled"
            margin={props.margin ? props.margin : "none"}
            required={props.required}
            type={props.type}
            name={props.name}
            fullWidth={props.fullWidth}
            sx={props.sx}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            label={props.label}
            error={error && touched}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            value={value ? value.title : ""}
            readOnly={props.readOnly}
            classes={props.classes}
            aria-label={props.ariaLabel}
            placeholder={props.placeholder}
            options={props.options}
          />
          <Box
            component="span"
            sx={{
              position: "absolute",
              top: "3.2rem",
              left: "3.2rem",
              zIndex: "99",
            }}
          >
            {props.hasLogo && value?.subtitle && (
              <Typography
                sx={{
                  fontSize: "0.625rem",
                  lineHeight: "0.75rem",
                  fontWeight: 400,
                  color: "#8083A3",
                }}
              >
                {value.subtitle}
              </Typography>
            )}
          </Box>

          {props?.error?.[props.name] && touched && <Typography sx={{ color: "#A73636", mt: 1 }}>{props.error[props.name]}</Typography>}
        </InputWrapper>
      )}
      renderOption={(renderProps, option) => (
        <li
          style={{
            background: option.newOption ? "#FCF4F3" : "#fff",
          }}
          {...renderProps}
          key={option.value}
        >
          {option.newOption && (
            <Box
              sx={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <ProfileAvatar
                sx={{
                  width: "1.875rem",
                  height: "1.875rem",
                  background: "#E77A0C",
                }}
                hasIcon
                icon={
                  <AddCircleOutlinedIcon
                    sx={{
                      color: "#FFFFFF",
                      width: "0.873rem",
                      height: "0.873rem",
                    }}
                  />
                }
              />
              {option.displayNewMessage}
            </Box>
          )}
          {!option.newOption && (
            <Box
              sx={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <span>
                {props.hasAvatar ? (
                  <ProfileAvatar
                    sx={{
                      width: "1.875rem",
                      height: "1.875rem",
                      color: "#212437",
                      fontSize: "0.75rem",
                    }}
                    bgColor="rgba(138, 176, 251, 0.3)"
                    name={option.title}
                  />
                ) : props.hasLogo ? (
                  <ProfileAvatar
                    sx={{
                      width: "1.875rem",
                      height: "1.875rem",
                    }}
                    name={option.title}
                    hasImage
                    imageSrc={option.avatar}
                  />
                ) : (
                  ""
                )}
              </span>
              <span>
                {option.title}
                {option.disableValue ? (
                  <small style={{ color: "#8083A3" }}>
                    <CircleIcon sx={{ fontSize: "6px" }} />
                    {option.disableValue}
                  </small>
                ) : (
                  ""
                )}
              </span>
            </Box>
          )}
        </li>
      )}
    />
  );
};

export default InputAutoComplete;
