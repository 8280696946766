/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { type StepperNav } from "interfaces/atlas";
import { useFormik } from "formik";
import { saveEntity } from "services/atlas";
import { NewEntityContext } from "./newEntityContext";
import Input from "components/Input";
import BottomNav from "./bottomNav";
import CountryDropdown from "components/CountryDropdown";
import countryList from "data/countryAndStates";
import CompareValues from "helper/CompareValues";
import CountryDropdownMultiple from "components/CountryDropdown/countryDropdownMultiple";

interface FormValues {
  streetName: string;
  postalCode: string;
  state: string;
  country: string;
  countriesOfOperation: string;
}

const NewEntityAddress = ({ handleBack, handleNext }: StepperNav) => {
  const { entity } = useContext(NewEntityContext);
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: FormValues = {
    streetName: entity?.street || "",
    postalCode: entity?.postCode || "",
    state: entity?.state || "",
    country: entity?.country || "",
    countriesOfOperation: "",
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await saveEntity(entity?.id || "", {
          street: values.streetName,
          postCode: values.postalCode,
          state: values.state,
          country: values.country,
          supplierSourcingCountries: values.countriesOfOperation.split(","),
        });
        handleNext();
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    initialValues,
  });

  const { handleBlur, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Address
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Input
              type="text"
              id="streetName"
              error={errors}
              margin="normal"
              name="streetName"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.streetName}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Street
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              type="text"
              id="postalCode"
              error={errors}
              margin="normal"
              name="postalCode"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.postalCode}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Postal Code
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              type="text"
              id="state"
              error={errors}
              margin="normal"
              name="state"
              onBlur={handleBlur}
              required={false}
              fullWidth={false}
              touched={touched}
              value={formik.values.state}
              onChange={handleChange}
              label={
                <Box>
                  <Typography
                    sx={{
                      gap: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    State/City
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                marginTop: "1rem",
              }}
            >
              <CountryDropdown
                name="country"
                label="Country"
                options={countryList
                  .map((country) => {
                    return { name: country.country, value: country.country };
                  })
                  .sort(CompareValues("name", "asc"))}
                value={formik.values.country}
                errors={formik.errors}
                touched={formik.touched}
                noImage={true}
                placeholder="Country"
                setFieldValue={formik.setFieldValue}
                hasMargin={true}
                sx={{
                  "& .MuiFormControl-root, & .MuiFilledInput-root": {
                    //   maxWidth: "7rem",
                    borderRadius: "7px",
                  },
                  "& .MuiFormControl-root": {
                    border: "1px solid rgba(230, 122, 14, 0.5)",
                  },
                  "& .MuiFilledInput-root": {
                    border: "none",
                  },
                  "& .MuiFilledInput-input": {
                    padding: "16px 8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <p
              style={{
                color: "rgba(128, 131, 162, 1)",
                font: "SF Pro Display",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "600",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              Current Operating Address
            </p>
            <CountryDropdownMultiple
              onChange={formik.setFieldValue}
              required={false}
              placeholder="Select one or more countries"
              title="Countries of Operation"
              options={countryList
                .map((country) => {
                  return { name: country.country, value: country.country };
                })
                .sort(CompareValues("name", "asc"))}
              name="countriesOfOperation"
              value={formik.values.countriesOfOperation}
              error={errors}
              labelId="countriesOfOperation"
              id="countriesOfOperation"
              setFieldTouched={formik.setFieldTouched}
              touched={touched}
            />
          </Grid>
        </Grid>
        <BottomNav handleBack={handleBack} loading={loading} />
      </Box>
    </Box>
  );
};

export default NewEntityAddress;
