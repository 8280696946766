import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const customTransactionsByRecordId = /* GraphQL */ `
  query customTransactionsByRecordId(
    $fileId: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByRecordId(
      fileId: $fileId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const customUserByEmail = /* GraphQL */ `query customUserByEmail(
  $email: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFlunaMembersFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      email
      primaryTeam
      lastActive
      picture
      modules {
        name
        description
        access
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;

export const customListFlunaMembers = /* GraphQL */ `query customListFlunaMembers(
  $filter: ModelFlunaMembersFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlunaMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      primaryTeam
      lastActive
      picture
      modules {
        name
        description
        access
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFlunaMembersQueryVariables,
  APITypes.ListFlunaMembersQuery
>;