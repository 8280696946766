import React from "react";
import { Box } from "@mui/material";
import SecondaryMenuLink from "components/SecondaryMenuLink";
import { SettingsSidebarNav } from "constants/Navigation";

interface SecondarySidebarProps {
  opened: boolean;
  path: string;
}

interface SecondaryMenus {
  name: string;
  navigation: string;
  element: JSX.Element;
}

const SecondarySidebar = ({ opened, path }: SecondarySidebarProps) => {
  const secondaryMenuOptions: Record<string, SecondaryMenus[]> = {
    settings: SettingsSidebarNav,
  };

  const secondaryMenu: SecondaryMenus[] = secondaryMenuOptions[path];

  return (
    <Box
      sx={{
        width: "16%",
        display: { xs: "none", sm: "none", md: "block", lg: "block" },
        marginLeft: {
          md: opened ? "25%" : "8.3%",
          lg: opened ? "16.6%" : "8.3%",
        },
        height: "100vh",
        float: "left",
        position: "fixed",
        background: "#FCFCFD",
      }}
    >
      <p
        style={{
          font: "SF Pro Display",
          color: "#000",
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: "600",
          padding: "1.5rem 0rem 3.5rem 1.2rem",
        }}
      >
        Settings
      </p>
      {secondaryMenu?.map((page) => <SecondaryMenuLink key={page.name} page={page} opened={opened} />)}
    </Box>
  );
};

export default SecondarySidebar;
