export const encodeToken = /* GraphQL */ `
  query EncodeToken($value: String!, $secret: String!) {
    encodeToken(value: $value, secret: $secret)
  }
`;

export const inviteUser = /* GraphQL */ `
  query InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input)
  }
`;

export const decodeToken = /* GraphQL */ `
  query DecodeToken($token: String!, $secret: String) {
    decodeToken(token: $token, secret: $secret)
  }
`;
