import React, { Box } from '@mui/material'
import Lottie from 'react-lottie-player'
import * as Flunaloader from 'assets/loaders/FlunaLoader.json'

const SplashScreen = () => {
  return (
    <Box
      data-testid="loading"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        pb: '3.5rem',
        pt: '3rem',
      }}
    >
      <Lottie loop animationData={Flunaloader} play style={{ width: 100, height: 100 }} />
    </Box>
  )
}

export default SplashScreen
