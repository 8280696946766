import axios from "axios";

export const useTreasuryGraphql = async (endpoint: string, query: any, variables: any, apiKey: string) => {
  try {
    const response = await axios.post(
      endpoint,
      {
        query,
        variables,
      },
      {
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log("error with graphql operation", error);
    throw error;
  }
};
