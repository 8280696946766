import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Investment } from 'interfaces/investment'

interface investmentState {
    newInvestment: Investment | null
    investments: Investment[]
    selectedInvestment: Investment | null
    isUpdateState: boolean
  }


const initialState: investmentState = {
    newInvestment: null,
    investments: [],
    selectedInvestment: null,
    isUpdateState: false,
}


const investmentSlice = createSlice({
    name: 'investment',
    initialState,
    reducers: {
      setAllInvestment: (state, action) => {
        state.investments = action.payload.data
      },
      setSelectedInvestment: (
        state,
        action: PayloadAction<{
          data: Investment
        }>
      ) => {
        state.selectedInvestment = action.payload.data
      },
      setNewInvestment: (
        state,
        action: PayloadAction<{
          data: Investment
        }>
      ) => {
        state.newInvestment = action.payload.data
      },
      setUpdateState: (state, action) => {
        state.isUpdateState = action.payload.isUpdateState
      },
    },
  })

  export const {
    setAllInvestment,
    setSelectedInvestment,
    setNewInvestment,
    setUpdateState,
  } = investmentSlice.actions
  
  export default investmentSlice.reducer