const invitationEmailTemplate = (invitee: string, username: string, companyName: string, link: string) => {
  return `<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
        />
    </head>
    <body>
        <div style="max-width: 765px; width: 100%; margin: auto; font-family: 'Public Sans', sans-serif; color: '#171721'; border-collapse: collapse">
            <table role="presentation" style="max-width: 765px; width: 100%; margin: auto; font-family: 'Public Sans', sans-serif; color: '#171721'; border-collapse: collapse">
                <tr>
                    <td>
                        <img
                            src="https://flunawebassets.s3.eu-west-2.amazonaws.com/Fluna+Logo+Package+For+Folders-01+(1)+1.png"
                            alt="Logo"
                            width="160"
                            style="height: auto; display: block; padding: 60px 0 48px 0px"
                        />
                    </td>
                </tr>
            </table>
            <table role="presentation" style="max-width: 765px; width: 100%; margin: auto; font-family: 'Public Sans', sans-serif; color: '#171721'; border-collapse: collapse">
                <tr>
                    <td style="background: linear-gradient(180deg, rgba(239, 72, 39, 0.2) 0%, rgba(247, 148, 46, 0.2) 100%); padding: 48px 42px; border-radius: 0 0 10px 10px">
                        <p style="font-weight: 700; font-size: 24px; line-height: 48px; color: #253858">Your Invitation to Fluna</p>
                    </td>
                </tr>
            </table>
            <table role="presentation" style="max-width: 765px; width: 100%; margin: auto; font-family: 'Public Sans', sans-serif; color: '#171721'; border-collapse: collapse">
                <tr>
                    <td style="padding: 40px; padding-bottom: 0; line-height: 24px; font-size: 16px; color: #253858; font-style: normal; font-weight: 400">
                        <p>Hello ${invitee},</p>
                        <p style="padding-top: 24px">
                            Welcome to Fluna! A new account has been created for you by ${username} from ${companyName}. <br />
                            Click the button below to create a password for your account.
                        </p>
                        <p style="padding-top: 8px">
                            <a
                                href="${link}"
                                style="
                                    padding: 16px;
                                    width: 171px;
                                    text-align: center;
                                    background-color: #fba34b;
                                    color: #3d1e01;
                                    border-radius: 8px;
                                    font-weight: 500;
                                    font-size: 14px;
                                    display: inline-block;
                                    text-decoration: none;
                                    line-height: 20px;
                                "
                            >
                                Create Password
                            </a>
                        </p>
                    </td>
                </tr>
            </table>
            <table style="max-width: 765px; width: 100%; margin: auto; font-family: 'Public Sans', sans-serif; color: '#171721'; border-collapse: collapse">
                <tr>
                    <td style="padding: 4px 40px">
                        <p style="padding: 40px 0px 40px 0px; line-height: 32px; font-size: 16px; color: #253858; font-style: normal; font-weight: 400">
                            Regards, <br />
                            The Fluna Team
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="padding: 60px 40px 0px; font-size: 14px; color: #253858; font-style: normal; font-weight: 400">
                        <p style="color: #8083a3; font-style: normal; font-weight: 400; font-size: 14px; line-height: 26px">
                            Questions? Feel free to send us an email<a href="mailto:help@fluna.co" style="color: #e77a0c">(support@fluna.co)</a> to this email or
                            <a href="https://tawk.to/chat/63e6299ec2f1ac1e203282da/1goth5552" target="_blank" rel="noreferrer noopener" style="color: #e77a0c">chat with our team️</a>
                        </p>
                        <p style="color: #8083a3; font-style: normal; font-weight: 400; font-size: 12px">
                            <a href="mailto:info@fluna.co" style="color: #8083a3; text-decoration: none"> &copy; Fluna Inc. | info@fluna.co </a>
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    </body>
</html>
`;
};

export default invitationEmailTemplate;
